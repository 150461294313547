import {
  ChangeDetectionStrategy,
  Component, Inject, OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PaginationQuery, Patient } from '@pixacare/pxc-ts-core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { Observable } from 'rxjs';
import {
  debounceTime, distinctUntilChanged, map,
} from 'rxjs/operators';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { PagedCollection } from 'src/app/shared/models/pagination/paged-collection';
import { patientPaginationContext } from 'src/app/shared/models/pagination/pagination-context.config';
import { FilterBarService } from '../../filters/filter-bar.service';
import { PatientPaginationService } from '../patient-pagination.service';

@UntilDestroy()
@Component({
  templateUrl: './patient-merge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PatientPaginationService,
    FilterBarService,
  ],
})
export class PatientMergeComponent implements OnInit {

  patient: Patient = this.context.data.patient;
  clientCode: string = this.context.data.clientCode;
  departmentId: number = this.context.data.departmentId;

  collection$: Observable<PagedCollection<Patient[]>>;
  isCollectionLoaded$: Observable<boolean> = null;

  targetPatient: Patient = null;
  Status = Status;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<Patient, {
      patient: Patient;
      clientCode: string;
      departmentId: number;
    }>,
    private readonly patientPaginationService: PatientPaginationService,
    private readonly filterBarService: FilterBarService,
  ) { }

  ngOnInit(): void {

    this.isCollectionLoaded$ = this.patientPaginationService.isCollectionLoaded$;
    this.collection$ = this.patientPaginationService.select().pipe(
      untilDestroyed(this),
      map((collection) => ({
        ...collection,
        sorted: collection.sorted.filter((patientId) => patientId !== this.patient.id),
        data: collection.data.filter((patient) => patient.id !== this.patient.id),
      })),
    );

    this.filterBarService.filteredSearch$.pipe(
      untilDestroyed(this),
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(({ search }) => {
      this.patientPaginationService.load(this.clientCode, {
        reset: true,
        query: new PaginationQuery({
          orderBy: ['last_activity|desc'],
          size: patientPaginationContext.countPerPage,
          filter: this.departmentId ? [{ prop: 'department_id', op: 'eq', val: this.departmentId.toString() }] : [],
          ...(search && { search }),
        }),
      });

      this.targetPatient = null;
    });
  }

  loadNextPatients(): void {
    this.patientPaginationService.loadNextPage(this.clientCode);
  }

  selectTargetPatient(patient: Patient): void {
    this.targetPatient = patient;
  }

  mergePatients(): void {
    this.context.completeWith(this.targetPatient);
  }

  cancel(): void {
    this.context.completeWith(null);
  }

}
