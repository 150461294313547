import { Injectable } from '@angular/core';
import { Filter } from '@pixacare/pxc-ts-core';
import { FilteredSearch } from '@shared/models/filters/filtered-search';
import { lens, normalizedStartsWith } from '@shared/utils/utils';
import { isValid } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class FilterService<T> {
  
  // TODO : Should be refactor with filter.utils ?
  
  public apply(items: T[], propreties: string[], { search, filters }: FilteredSearch): T[] {
    return (items || []).filter((item) => {
      const matchesSearch = this.matchesSearch(item, propreties, search);
      const matchesFilters = this.matchesFilters(item, filters);
      return matchesSearch && matchesFilters;
    });
  }
  
  private matchesSearch(item: T, properties: string[], search: string): boolean {
    return properties.some((property: string) => normalizedStartsWith(lens(item, property) as string, search));
  }
  
  private matchesFilters(item: T, filters: Filter[]): boolean {
    return filters.every((filter) => {
      const value = item[filter.prop];
  
      if (value === null || value === undefined) {
        return false;
      }
  
      if (isValid(new Date(filter.val as string))) {
        return this.handleDate(value, filter);
      }
  
      if (Array.isArray(filter.val)) {
        return this.handleArray(value, filter);
      }
  
      return true;
    });
  }

  private handleDate(date: Date, filter: Filter): boolean {
    if (typeof filter.val !== 'string') { 
      return false; 
    }
    
    switch (filter.op) {
      case 'gte':
        return new Date(date) >= new Date(filter.val);
      case 'lte': 
        return new Date(date) <= new Date(filter.val);
      default:
        return false;
    }
  }

  private handleArray(value: string, filter: Filter): boolean {
    if (!Array.isArray(filter.val) || filter.val.some((item) => typeof item !== 'string')) {
      return false;
    }
    
    switch (filter.op) {
      case 'in':
        return (filter.val as string[]).some((filterValue) => filterValue === '' || filterValue === value);
      default:
        return false;
    }
  }

}
