import { createReducer, on } from '@ngrx/store';
import { UploadingFile } from '@shared/models/attachments/uploading-file';
import { StoreAttachment } from '@shared/store/attachments/models/store-attachment';
import { attachmentsActions } from './attachments.actions';
import { AttachmentsState, initialAttachmentState } from './attachments.state';

export const attachmentsReducer = createReducer(
  initialAttachmentState,

  on(
    attachmentsActions.uploadAttachments,
    (state, { clientCode, patientId, uploadingFiles }): AttachmentsState => ({
      ...state,
      [clientCode]: {
        ...state[clientCode],
        [patientId]: {
          ...state[clientCode]?.[patientId],
          uploadingFiles: [
            ...new Map([...(state[clientCode]?.[patientId]?.uploadingFiles || []), ...uploadingFiles]
              .map((item) => [item.id, item])).values(),
          ],
        },
      },
    }),
  ),

  on(
    attachmentsActions.uploadAttachmentsSuccess,
    attachmentsActions.getPatientAttachmentsSuccess,
    (state, { clientCode, patientId, attachments }): AttachmentsState => ({
      ...state,
      [clientCode]: {
        ...state[clientCode],
        [patientId]: {
          ...state[clientCode]?.[patientId],
          attachments: [
            ...new Map(
              [...(state[clientCode]?.[patientId]?.attachments || []), ...attachments]
                .map((item) => [item.id, item]),
            ).values(),
          ],
        },
      },
    }),
  ),

  on(
    attachmentsActions.updateUploadingFileState,
    (state, { clientCode, patientId, uploadingFileId, uploadState }): AttachmentsState => ({
      ...state,
      [clientCode]: {
        ...state[clientCode],
        [patientId]: {
          ...state[clientCode]?.[patientId],
          uploadingFiles: (state[clientCode]?.[patientId]?.uploadingFiles || []).reduce((acc, file) => {
            acc.push({ ...file, ...(file.id === uploadingFileId && { uploadState }) });
            return acc;
          }, []),
        },
      },
    }),
  ),

  on(
    attachmentsActions.removeUploadingFile,
    (state, { clientCode, patientId, uploadingFileId }): AttachmentsState => ({
      ...state,
      [clientCode]: {
        ...state[clientCode],
        [patientId]: {
          ...state[clientCode]?.[patientId],
          uploadingFiles: [
            ...(state[clientCode]?.[patientId]?.uploadingFiles || [])
              .filter((item: UploadingFile) => item.id !== uploadingFileId),
          ],
        },
      },
    }),
  ),

  on(
    attachmentsActions.deleteAttachmentSuccess,
    (state, { clientCode, patientId, attachmentId }): AttachmentsState => ({
      ...state,
      [clientCode]: {
        ...state[clientCode],
        [patientId]: {
          ...state[clientCode]?.[patientId],
          attachments: (state[clientCode]?.[patientId]?.attachments || [])
            .filter((attachment: StoreAttachment) => attachment.id !== attachmentId),
        },
      },
    }),
  ),
);
