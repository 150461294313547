<div class="flex h-screen w-full flex-col bg-base">
  <a
    routerLink="/dashboard/home"
    queryParamsHandling="merge"
    class="relative mt-4 h-8 cursor-pointer"
  >
    <img
      src="https://cdn.pixacare.com/logo/left-transparent.svg"
      class="absolute left-4 top-0 h-8"
      [animateFade]="!collapsed"
    />

    <img
      src="https://cdn.pixacare.com/logo/mire.svg"
      class="absolute left-4 top-0 h-8 w-8"
      [animateFade]="collapsed"
    />
  </a>

  @if (sidebarService.clients$ | async; as clients) {
    <tui-scrollbar class="max-h-full w-full flex-1">
      <div class="flex flex-col divide-y divide-slate-100">
        @if (menuBuilderService.clientMenuEntries$ | async; as clientEntries) {
          <ng-template #clientPicker>
            <pxc-client-picker
              class="w-full"
              [selectedClient]="sidebarService.activeClientCode$ | async"
              [clients]="clients"
              [collapsed]="collapsed"
              (selectedClientChange)="changeActiveClient($event)"
              [tuiHint]="collapsed ? 'Sélectionnez un espace' : null"
              tuiHintDirection="right"
              tuiHintAppearance="dark"
              data-testid="select-client"
              size="small"
            ></pxc-client-picker>
          </ng-template>
          <ng-container
            [ngTemplateOutlet]="navigationSection"
            [ngTemplateOutletContext]="{
              entries: clientEntries,
              header: clientPicker,
            }"
          ></ng-container>
        }
        @if (menuBuilderService.departmentsEntries$ | async; as departmentEntries) {
          <ng-container class="max-h-full">
            @if (departmentEntries.length > 0) {
              <ng-container
                [ngTemplateOutlet]="navigationSection"
                [ngTemplateOutletContext]="{
                  entries: departmentEntries,
                }"
              ></ng-container>
            }
          </ng-container>
        }
        <ng-container
          [ngTemplateOutlet]="navigationSection"
          [ngTemplateOutletContext]="{
            entries: menuBuilderService.userEntries,
          }"
        ></ng-container>
      </div>
    </tui-scrollbar>
  }

  <div
    class="flex flex-col gap-2 pb-3 pt-2 transition-all"
    [ngClass]="collapsed ? 'px-1' : 'px-4'"
  >
    <pxc-user-account-card [collapsed]="collapsed"></pxc-user-account-card>

    @if (sidebarService.shouldDisplayDataConsumption$ | async; as shouldDisplayDataConsumption) {
      <pxc-data-consumption
        class="mx-2"
        data-testid="data-consumption"
        [collapsed]="collapsed"
      ></pxc-data-consumption>
    }

    @if (sidebarService.isActiveLicenseStateLimited$ | async; as isActiveLicenseLimited) {
      <tui-notification
        size="m"
        appearance="warning"
        data-testid="alert"
        [attr.data-collapsed]="collapsed"
        [tuiHint]="collapsed ? licenseState : null"
        tuiHintAppearance="dark"
      >
        <span
          [animateFade]="!collapsed"
          class="line-clamp-1 text-xs"
        >
          <ng-container [ngTemplateOutlet]="licenseState"></ng-container>
        </span>
      </tui-notification>
    }
  </div>
</div>

<ng-template
  #navigationSection
  let-entries="entries"
  let-footer="footer"
  let-header="header"
>
  <div
    class="flex flex-col gap-2 overflow-x-hidden py-3 transition-all"
    [ngClass]="collapsed ? 'px-1' : 'px-4'"
  >
    @if (header) {
      <ng-container [ngTemplateOutlet]="header"></ng-container>
    }

    <ng-container *ngTemplateOutlet="itemsSection; context: { items: entries }"></ng-container>

    @if (footer) {
      <ng-container [ngTemplateOutlet]="footer"></ng-container>
    }
  </div>
</ng-template>

<ng-template
  #itemsSection
  let-items="items"
  let-iconsColor="iconsColor"
>
  <div class="flex w-full flex-col">
    @for (entry of items; track entry) {
      <ng-container
        *ngTemplateOutlet="!!entry.children ? folderItem : navItem; context: { item: entry, iconColor: iconsColor }"
      ></ng-container>
    }
  </div>
</ng-template>

<ng-template
  #navItem
  let-entry="item"
  let-iconColor="iconColor"
>
  <a
    tuiLink
    routerLinkActive="tui-link-active"
    [routerLink]="entry.link"
    [queryParams]="entry.queryParams"
    queryParamsHandling="merge"
    [iconStart]="entry.icon"
    appearance="navigation"
    [attr.data-collapsed]="collapsed"
    [tuiHint]="collapsed ? entry.title : null"
    tuiHintDirection="right"
    tuiHintAppearance="dark"
    class="select-none justify-center data-[collapsed=true]:!gap-0"
    [ngStyle]="{
      color: collapsed ? iconColor : null,
      position: 'relative',
    }"
  >
    @if (!collapsed) {
      <div class="flex w-full items-center justify-between">
        <span class="line-clamp-1 whitespace-break-spaces break-all">
          {{ entry.title }}
        </span>

        <ng-container *tuiLet="entry.count | async as counter">
          @if (counter) {
            <tui-badge
              size="s"
              appearance="custom"
              class="right-3 top-0 bg-negative"
              [ngClass]="{
                '!absolute': collapsed,
                '!static': !collapsed,
              }"
            >
              {{ counter }}
            </tui-badge>
          }
        </ng-container>
      </div>
    }
  </a>
</ng-template>

<ng-template
  #folderItem
  let-item="item"
>
  <ng-container *tuiLet="(openDepartment$ | async) === item.id as isExpanded">
    <a
      tuiLink
      (click)="toggleFolder(item.id, isExpanded)"
      [iconEnd]="collapsed ? '' : '@tui.chevron-down'"
      appearance="navigation"
      [tuiChevron]="isExpanded"
      [tuiHint]="collapsed ? item.title : null"
      tuiHintDirection="right"
      tuiHintAppearance="dark"
      [attr.data-collapsed]="collapsed"
      [ngClass]="{
        'justify-center': collapsed,
        '!bg-base-alt': isExpanded,
      }"
    >
      <div
        class="flex select-none items-center"
        [class.w-full]="!collapsed"
      >
        <div
          class="flex h-6 w-6 items-center justify-center rounded-lg text-sm uppercase transition-all"
          [ngStyle]="{
            backgroundColor: item.style.backgroundColor,
            color: item.style.textColor,
          }"
          [ngClass]="{
            'translate-x-3.5': collapsed,
            'mr-1': !collapsed,
          }"
        >
          {{ item.title.charAt(0) }}
        </div>
        <span
          class="line-clamp-1 flex-1 break-all pl-4"
          [animateFade]="!collapsed"
          animateDirection="left"
          truncatedText
          (truncated)="isTitleTruncated = $event"
          [tuiHint]="isTitleTruncated ? item.title : null"
          tuiHintDirection="right"
          tuiHintAppearance="dark"
        >
          {{ item.title }}
        </span>
      </div>
    </a>
    <tui-expand [expanded]="isExpanded">
      <div class="my-2">
        <ng-container
          *ngTemplateOutlet="itemsSection; context: { items: item.children, iconsColor: item.style.textColor }"
        ></ng-container>
      </div>
    </tui-expand>
  </ng-container>
</ng-template>

<ng-template #licenseState>
  @switch (sidebarService.activeLicenseState$ | async) {
    @case (LicenseState.DISABLED) {
      Votre licence est désactivée.
    }
    @case (LicenseState.LIMITED_QRCODE) {
      Votre licence est limitée.
    }
  }
</ng-template>

