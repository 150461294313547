import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FileExtensionPipe } from '@modules/attachments/pipes/file-extension.pipe';
import { FileExtension } from '@shared/models/enums/file-extension.enum';
import { TuiAlertService, TuiIcon } from '@taiga-ui/core';

@Component({
  selector: 'pxc-attachment-drag-and-drop',
  standalone: true,
  imports: [
    TuiIcon,
  ],
  providers: [
    FileExtensionPipe,
  ],
  templateUrl: './attachment-drag-and-drop.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentDragAndDropComponent {
  
  @Input() allowedExtensions: string[] = Object.values(FileExtension);

  @Output() files = new EventEmitter<File[]>();

  fileAcceptTypes = this.allowedExtensions.join(', ');
  
  private readonly alertService = inject(TuiAlertService);
  private readonly fileExtensionPipe = inject(FileExtensionPipe);
  
  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = this.handleFiles(event.dataTransfer?.files);
    this.files.emit(files);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const files = this.handleFiles(input.files);
    this.files.emit(files);
    input.value = '';
  }

  private handleFiles(fileList: FileList): File[] {
    const userFiles = Array.from(fileList || []);
    const files = userFiles.filter((file: File) => this.isValidExtension(file.name));

    if (userFiles.length !== files.length) {
      this.showUnsupportedFilesWarning();
    }

    return files;
  } 

  private isValidExtension(filename: string): boolean {
    const extension = this.fileExtensionPipe.transform(filename);
    return extension ? this.allowedExtensions.includes(extension) : false;
  }
  
  private showUnsupportedFilesWarning() {
    this.alertService.open(`L'extension de certains fichiers n'est pas pris en charge`, {
      label: 'Fichier(s) non supporté(s)',
      appearance: 'warning',
    }).subscribe();
  }

}
