import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserSequenceGalleryService } from '@modules/gallery/services/dialogs/sliders/sequences/user-sequence-gallery.service';
import { Store } from '@ngrx/store';
import { FilterOperator, FlattenedSequence, FlattenedTelemonitoring, Patient, SadmEntity } from '@pixacare/pxc-ts-core';
import { SadmEntityNamePipe } from '@shared/pipes/sadm-entity-name.pipe';
import { SadmEntityTypePipe } from '@shared/pipes/sadm-entity-type.pipe';
import { selectClientPatient } from '@shared/store/patients/patients.selectors';
import { TuiButton, TuiDialogContext, TuiIcon, TuiLabel, TuiTitle } from '@taiga-ui/core';
import { TuiCheckbox, TuiElasticContainer } from '@taiga-ui/kit';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { Observable, Subject } from 'rxjs';
import { FilteredSequenceSearch } from 'src/app/shared/models/filters/filtered-sequence-search';
import { MediaClickedOutput } from 'src/app/shared/models/media-clicked-output';
import { FilteredSequenceReportConfig } from 'src/app/shared/models/report-config';
import { sequenceCountPluralMapping, sequencesCountPluralMapping } from 'src/app/shared/utils/plural-mappings';
import { PatientModule } from '../../patient/patient.module';
import { CloseConfirmableDialogComponent } from '../../shared/close-confirmable-dialog/close-confirmable-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { TelemonitoringService } from '../../telemonitoring/telemonitoring.service';
import { SequencePickerComponent } from '../sequence-picker/sequence-picker/sequence-picker.component';
import { SequenceModule } from '../sequence.module';

@Component({
  selector: 'pxc-sequence-report',
  templateUrl: './sequence-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiElasticContainer,
    TuiButton,
    SequencePickerComponent,
    PatientModule,
    SequenceModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TuiLabel,
    TuiIcon,
    TuiCheckbox,
    TuiTitle,
  ],
  providers: [
    SadmEntityNamePipe,
    SadmEntityTypePipe,
    UserSequenceGalleryService,
  ],
})
export class SequenceReportComponent extends CloseConfirmableDialogComponent<FilteredSequenceReportConfig>
  implements OnInit {

  @Input() patientId: number = this.tuiDialogContext.data.patientId;
  @Input() clientCode: string = this.tuiDialogContext.data.clientCode;
  @Input() departmentIds?: number[] = this.tuiDialogContext.data.departmentIds;
  @Input() telemonitoringId?: number = this.tuiDialogContext.data.telemonitoringId;

  sequencesCountPluralMapping = sequencesCountPluralMapping;
  sequenceCountPluralMapping = sequenceCountPluralMapping;

  step = 1;

  customReportForm = new FormGroup({
    includeReports: new FormControl(true),
    includeCharts: new FormControl(true),
  });

  patient$: Observable<Patient>;
  selectedSequenceIds: number[] = [];
  selectedSequences: FlattenedSequence[] = [];
  selectedTelemonitoringNames: string[] = [];
  selectedSadmEntityNames: string[] = [];
  isAllSequencesSelected = false;

  constructor(
    private readonly telemonitoringService: TelemonitoringService,
    private readonly galleryService: UserSequenceGalleryService,
    private readonly store: Store,
    private readonly sadmEntityNamePipe: SadmEntityNamePipe,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly tuiDialogContext: TuiDialogContext<FilteredSequenceReportConfig, {
      clientCode: string;
      patientId: number;
      departmentIds: number[];
      telemonitoringId?: number;
      shouldAskConfirmation$?: Subject<boolean>;
    }>,
  ) {
    super(tuiDialogContext);
  }

  ngOnInit() {
    this.patient$ = this.store.select(selectClientPatient(this.clientCode, this.patientId));
  }

  confirmSelection(): void {

    if (this.selectedSequenceIds.length <= 0) {
      return;
    }

    const filteredSequenceSearch: FilteredSequenceSearch = {
      search: null,
      filters: [{
        prop: 'id',
        op: FilterOperator.IN,
        val: this.selectedSequenceIds.join(','),
      }],
      sequenceIds: this.selectedSequenceIds,
      sequences: this.selectedSequences,
      isAllSequencesSelected: this.isAllSequencesSelected,
    };

    this.tuiDialogContext.completeWith({
      ...this.customReportForm.value,
      ...filteredSequenceSearch,
    });

  }

  telemonitoringsSelectionChanged(telemonitorings: FlattenedTelemonitoring[]): void {
    this.selectedTelemonitoringNames = telemonitorings.map((telemonitoring) =>
      this.telemonitoringService.getTelemonitoringName(telemonitoring));
  }

  sadmEntitiesSelectionChanged(sadmEntities: SadmEntity[]): void {
    this.selectedSadmEntityNames = sadmEntities.map((sadmEntity) =>
      this.sadmEntityNamePipe.transform(sadmEntity));
  }

  closeDialog(): void {
    this.tuiDialogContext.completeWith(null);
  }

  nextStep(): void {
    if (this.step < 2) {
      this.step++;
    } else {
      this.confirmSelection();
    }
  }

  previousStep(): void {
    if (this.step > 1) {
      this.step--;
    } else {
      this.closeDialog();
    }
  }

  openGallery({ clientCode, sequenceId, mediaIdx }: MediaClickedOutput): void {
    this.galleryService.openGallery(
      clientCode,
      sequenceId,
      mediaIdx,
    );
  }

}
