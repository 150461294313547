@if (attachments && attachments.length) {
  <tui-scrollbar
    tuiSurface="outline"
    class="!max-h-[55vh] overflow-hidden rounded-xl border border-solid border-border-normal"
  >
    <cdk-virtual-scroll-viewport
      #viewport
      tuiScrollable
      class="tui-zero-scrollbar h-[55vh]"
      [itemSize]="virtualScroll.itemSize"
      [maxBufferPx]="virtualScroll.itemSize"
      [minBufferPx]="virtualScroll.itemSize"
    >
      <table
        tuiTable
        class="w-full"
        [columns]="columns"
      >
        <thead>
          <tr tuiThGroup>
            @for (th of tableHeaders; track th.column) {
              <th
                tuiTh
                *tuiHead="th.column"
                [class]="th.classes"
                [sticky]="true"
                [sorter]="th.sorter"
                [style.top.px]="-viewport['_renderedContentOffset']"
              >
                {{ th.name }}
              </th>
            }
          </tr>
        </thead>
        <tbody
          tuiTbody
          *tuiLet="attachments | tuiTableSort as sortedAttachments"
          [data]="sortedAttachments"
        >
          <tr
            tuiTr
            class="group bg-white hover:bg-neutral-1/20"
            *cdkVirtualFor="let attachment of sortedAttachments; trackBy: trackByAttachment"
          >
            @for (th of tableHeaders; track th.column) {
              <td
                tuiTd
                *tuiCell="th.column"
              >
                @switch (th.column) {
                  @case ('name') {
                    <pxc-attachment-preview [attachment]="attachment"></pxc-attachment-preview>
                  }
                  @case ('type') {
                    {{ attachment.filename | fileExtension: false | uppercase }}
                  }
                  @case ('createdBy') {
                    <pxc-user-preview
                      [user]="attachment.createdBy"
                      [colored]="false"
                    ></pxc-user-preview>
                  }
                  @case ('createdOn') {
                    <span>
                      {{ attachment.createdOn | date: 'mediumDate' }}
                    </span>
                    <span>
                      {{ attachment.createdOn | date: 'shortTime' }}
                    </span>
                  }
                  @case ('actions') {
                    <pxc-icon-action-buttons
                      appearance="icon"
                      class="flex items-center justify-evenly"
                      size="xs"
                      [actions]="actions"
                      [entity]="attachment"
                    ></pxc-icon-action-buttons>
                  }
                }
              </td>
            }
          </tr>
        </tbody>
      </table>
    </cdk-virtual-scroll-viewport>
  </tui-scrollbar>
} @else {
  <pxc-lottie-label
    animation="sequenceEmpty"
    message="Il n'y a aucun fichier"
    data-testid="empty-state"
  ></pxc-lottie-label>
}
