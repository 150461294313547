<!-- If nothing has loaded -->
@if ((items$ | async) === null) {
  <pxc-loader
    class="loader"
    [dark]="true"
    data-testid="items-loading"
  ></pxc-loader>
}

@if (items$ | async; as items) {
  <div
    class="gallery-comparison relative flex h-screen w-screen select-none flex-row items-center overflow-y-auto"
    data-testid="comparison-scroll"
    (wheel)="onWheel($event)"
  >
    @for (item of items; track trackByGalleryItem(item); let index = $index) {
      <div class="relative h-full min-w-[33%]">
        <pxc-zoom-container
          class="zoom-container min-w-[33%]"
          [zoomWheelCTRLBinding]="true"
        >
          <img
            class="pointer-events-none h-full max-h-[97vh] object-contain"
            [hidden]="!item.isLoaded"
            [ngClass]="items.length < 3 ? 'image-' + items.length : 'image-infinite'"
            [src]="item.media.uri"
            [alt]="'image-' + index"
            (load)="onPictureLoad(item)"
            (error)="onPictureError(item)"
            [attr.data-testid]="'comparison-' + index"
          />
          @if (!item.isLoaded) {
            <pxc-loader
              [dark]="true"
              [attr.data-testid]="'comparison-loading-' + index"
            ></pxc-loader>
          }
        </pxc-zoom-container>
        <ng-template #insertionAnchors></ng-template>
      </div>
    }
  </div>

  <!-- Action bar -->
  <div class="absolute right-3 top-3 flex flex-col gap-1 sm:flex-row">
    <pxc-icon-action-buttons
      appearance="glass"
      size="m"
      [actions]="actions"
      [entity]="items"
    ></pxc-icon-action-buttons>

    <button
      tuiIconButton
      appearance="glass"
      iconStart="@tui.x"
      (click)="close()"
      data-testid="close-action"
    ></button>
  </div>
}
