import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GalleryItem } from '@shared/models/gallery-item';
import { TuiScrollbar } from '@taiga-ui/core';
import { GalleryThumbnailComponent } from '../gallery-thumbnail/gallery-thumbnail.component';

@Component({
  selector: 'pxc-gallery-thumbnail-list',
  standalone: true,
  imports: [
    CommonModule,
    GalleryThumbnailComponent,
    TuiScrollbar,
  ],
  templateUrl: './gallery-thumbnail-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryThumbnailListComponent {

  @Input() items: GalleryItem[] = [];
  @Input() activeIndex = 0;
  @Output() thumbnailClicked = new EventEmitter<number>();

  trackByGalleryItem(item: GalleryItem): any {
    return item?.media?.id || item.innerHtml;
  }

}
