/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FilterType } from '@shared/models/filters/filter-type.enum';
import { AutoCompleteModel } from '@shared/models/helpers/auto-complete-model';
import { makeFilter } from '@shared/utils/filter-utils';
import { TuiTabs } from '@taiga-ui/kit';
import { combineLatest, first, map, of, switchMap } from 'rxjs';
import { FilterBarService } from '../filter-bar.service';

@Component({
  selector: 'pxc-filter-tabs',
  templateUrl: './filter-tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiTabs,
  ],
})
export class FilterTabsComponent {

  private readonly filterBarService = inject(FilterBarService);
  
  template$ = this.filterBarService.templates$.pipe(
    map((templates) => templates
      .find((template) => template.type === FilterType.TABS),
    ),
  );

  tabs$ = this.template$.pipe(
    switchMap((template) => template ? template.getValue('') : of(null)),
  );

  selectedIndex$ = this.template$.pipe(
    switchMap((template) => this.filterBarService.getSelectedIndex$(template)),
  );

  onTabChange(tab: AutoCompleteModel, index: number): void {
    combineLatest([
      this.template$,
      this.selectedIndex$,
    ]).pipe(first()).subscribe(([template, selectedIndex]) => {

      if (index === selectedIndex) {
        return;
      }

      this.filterBarService.updateTemplate({
        ...template,
        filters: [
          ...(tab.value ? [makeFilter(tab, template)] : []),
        ],
      });
    });
  }

  trackByTab(index: number, tab: AutoCompleteModel): string {
    return tab.value;
  }

}
