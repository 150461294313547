import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FileExtensionPipe } from '@modules/attachments/pipes/file-extension.pipe';
import { FileIconPipe } from '@modules/attachments/pipes/file-icon.pipe';

@Component({
  selector: 'pxc-file-icon',
  standalone: true,
  imports: [
    FileIconPipe,
    FileExtensionPipe,
  ],
  templateUrl: './file-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileIconComponent {

  @Input() filename: string;
  
}
