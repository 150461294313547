import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LoaderComponent } from '@modules/shared/loader/loader.component';
import { SharedModule } from '@modules/shared/shared.module';
import { Store } from '@ngrx/store';
import { Media } from '@pixacare/pxc-ts-core';
import { MediaHttpService } from '@services/http/media.http.service';
import { sequencesActions } from '@shared/store/sequences/sequences.actions';
import { VgApiService, VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { BehaviorSubject, catchError, map, Observable, tap } from 'rxjs';
@Component({
  selector: 'pxc-video-player',
  standalone: true,
  imports: [
    CommonModule,
    VgCoreModule,
    VgStreamingModule,
    SharedModule,
    LoaderComponent,
  ],
  templateUrl: './video-player.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'w-full h-full flex items-center justify-center bg-black' },
})
export class VideoPlayerComponent implements OnInit, OnChanges {

  @Input() @HostBinding('class.hidden') hidden = false;
  @Input() media: Media;

  streamUrl$: Observable<string>;
  error$ = new BehaviorSubject<string>('');
  info$ = new BehaviorSubject<string>('');
  vgApiService: VgApiService;
  isPlaying = true;

  private readonly mediaService = inject(MediaHttpService);
  private readonly store = inject(Store);

  ngOnInit(): void {
    if (!this.media.clientCode || !this.media.sequenceId || !this.media.filename) {
      this.error$.next('Impossible de charger la vidéo : données manquantes');
      return;
    }

    this.streamUrl$ = this.getVideoUri();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.vgApiService || !changes.hidden || !this.isPlaying) {
      return;
    }

    if (this.hidden) {  
      this.vgApiService.pause();
    } else {
      this.vgApiService.play();
    }
  }

  public onPlayerReady(api: VgApiService): void {
    this.vgApiService = api; 
  }
  
  public onPlay(): void {
    this.isPlaying = true;
  }

  public onPause(): void {
    // Don't change playing state if video was paused because it was hidden
    if (!this.hidden) {
      this.isPlaying = false;
    } else {
      this.vgApiService.getDefaultMedia().currentTime = 0;
    }
  }

  private getVideoUri(): Observable<string> {
    return this.mediaService.getSequenceVideoMasterPlaylist(
      this.media.id,
      this.media.clientCode,
    ).pipe(
      map(({ uri }) => uri),
      tap(() => {
        if (!this.media.thumbnailUri) {
          this.reloadVideoThumbnail();
        }
      }),
      catchError((error) => {
        if (error.status === 404) {
          this.info$.next(`La vidéo est en cours de traitement...`);
        } else {
          this.error$.next(`Impossible de charger la vidéo : ${error}`);
        }
        return [];
      }),
    );
  }
  
  private reloadVideoThumbnail() {
    this.store.dispatch(sequencesActions.getUserSequence({ 
      clientCode: this.media.clientCode, 
      sequenceId: this.media.sequenceId, 
    }));
  }

}
