import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
  ClientUser,
  FlattenedSequence,
  FlattenedTelemonitoring,
  Patient,
  Telemonitoring,
} from '@pixacare/pxc-ts-core';
import { PagedCollection } from '@shared/models/pagination/paged-collection';
import { selectPatientState } from '../patients/patients.selectors';
import { PatientsState } from '../patients/patients.state';
import { selectTelemonitoringSequences } from '../sequences/sequences.selectors';
import { selectAllUsers, selectUsers } from '../users/users.selectors';
import { StoreTelemonitoring } from './models/store-telemonitoring';
import { TelemonitoringsState } from './telemonitorings.state';

export const TELEMONITORINGS_FEATURE_KEY = 'Telemonitorings';

export const selectTelemonitoringsState = createFeatureSelector<TelemonitoringsState>(
  TELEMONITORINGS_FEATURE_KEY,
);

const composeFlattenedTelemonitoring = (
  telemonitoring: StoreTelemonitoring,
  patient: Patient,
  user: ClientUser,
  sequences: FlattenedSequence[],
): FlattenedTelemonitoring => ({
  telemonitoring,
  sequences,
  patient,
  createdByInstance: user,
  thumbnailUri: telemonitoring.thumbnailUri,
  patientContact: patient?.patientContacts?.find((contact) => contact.id === telemonitoring.patientContactId),
  previewMedia: { 
    ...telemonitoring.previewMedia,
    clientCode: telemonitoring.clientCode,
  },
});

const composeFlattenedTelemonitorings = (
  telemonitorings: StoreTelemonitoring[],
  { patients }: PatientsState,
  users: { [userId: number]: ClientUser },
): FlattenedTelemonitoring[] => telemonitorings.map((telemonitoring) => composeFlattenedTelemonitoring(
  telemonitoring,
  patients?.[telemonitoring.clientCode]?.data?.[telemonitoring.patientId],
  users[telemonitoring.createdBy],
  null,
));

export const selectIsTelemonitoringStateLoaded = createSelector(
  selectTelemonitoringsState,
  (state) => state.isLoaded || false,
);

export const selectTelemonitoring =
(telemonitoringId: number): MemoizedSelector<TelemonitoringsState, FlattenedTelemonitoring> => createSelector(
  selectTelemonitoringsState,
  selectTelemonitoringSequences(telemonitoringId),
  selectPatientState,
  selectUsers,
  (state, sequences, patients, users) => {

    const telemonitoring = state.telemonitorings.data[telemonitoringId];

    if (!telemonitoring) {
      return null;
    }

    return composeFlattenedTelemonitoring(
      telemonitoring,
      patients.patients?.[telemonitoring.clientCode]?.data?.[telemonitoring.patientId],
      users[telemonitoring.clientCode][telemonitoring.createdBy],
      sequences?.data as FlattenedSequence[],
    );
  },
);

export const selectTelemonitorings = (
  isArchived: boolean = null,
): MemoizedSelector<TelemonitoringsState, PagedCollection<FlattenedTelemonitoring[]>> => createSelector(
  selectTelemonitoringsState,
  selectPatientState,
  selectAllUsers,
  (state, patients, users): PagedCollection<FlattenedTelemonitoring[]> => ({
    ...state.telemonitorings,
    data: composeFlattenedTelemonitorings(
      state.telemonitorings.sorted.map((tlmId) => state.telemonitorings.data?.[tlmId]).filter((tlm: Telemonitoring) =>
        isArchived === null
        || isArchived === (!tlm.isActive || patients.patients?.[tlm.clientCode]?.data[tlm.patientId]?.isArchived),
      ) || [],
      patients,
      users,
    ),
  }),
);

export const selectTelemonitoringSequenceIds = (
  telemonitoringId: number,
): MemoizedSelector<TelemonitoringsState, number[]> => createSelector(
  selectTelemonitoringsState,
  (state) => state.telemonitorings.data[telemonitoringId]?.sequenceIds,
);
