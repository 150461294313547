import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSelectComponent } from '@modules/forms/form-select/form-select.component';
import { PatientContactInputComponent } from '@modules/patient/patient-contact-input/patient-contact-input.component';
import { CreatorType } from '@pixacare/pxc-ts-core';
import { defaultReminderDaysInterval } from '@shared/models/telemonitoring/telemonitoring-request.config';
import { TuiError, TuiLabel } from '@taiga-ui/core';
import { TuiCheckbox, TuiFieldErrorPipe } from '@taiga-ui/kit';
import {
  TuiInputModule, TuiInputNumberModule, TuiTextareaModule, TuiTextfieldControllerModule,
} from '@taiga-ui/legacy';
import { TelemonitoringRequestForm } from '../telemonitoring-request/telemonitoring-request-form';

@Component({
  selector: 'pxc-telemonitoring-request-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    TuiInputModule,
    TuiInputNumberModule,
    TuiTextareaModule,
    TuiLabel,
    TuiFieldErrorPipe,
    TuiError,
    TuiCheckbox,
    PatientContactInputComponent,
    FormSelectComponent,
  ],
  templateUrl: './telemonitoring-request-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemonitoringRequestDetailsComponent {

  @Input() defaultName: string | null = null;

  @Input() form: FormGroup<TelemonitoringRequestForm>;

  CreatorType = CreatorType;

  get creatorType(): CreatorType {
    return this.form.get('creatorType').value;
  }

  get patientId(): number | null {
    return this.form.get('patientId').value;
  }

  get clientCode(): string {
    return this.form.get('clientCode').value;
  }

  get defaultReminderDaysInterval(): number {
    return defaultReminderDaysInterval;
  }

  get isReminderDaysIntervalReadOnly(): boolean {
    return !this.form.get('enableAutomaticReminderForInactivePatient').value;
  }

}
