import { inject, Injectable, Injector } from '@angular/core';
import { PatientNamePipe } from '@modules/patient/patient-name.pipe';
import { StudioComponent } from '@modules/studio/studio/studio.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FlattenedSequence, Media, MediaType } from '@pixacare/pxc-ts-core';
import { GalleryItem } from '@shared/models/gallery-item';
import { ActionsService } from '@shared/models/helpers/actions-service';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { MenuActionId } from '@shared/models/menu-actions/menu-action-id.enum';
import { selectSequence } from '@shared/store/sequences/sequences.selectors';
import { downloadFile } from '@shared/utils/utils';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { first, map, Observable } from 'rxjs';
import { GalleryService } from '../gallery.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SliderGalleryActionsService extends ActionsService {

  actions: MenuAction<GalleryItem>[] = [
    {
      id: MenuActionId.GALLERY_SLIDER_EDIT,
      label: 'Modifier',
      icon: '@tui.pencil',
      condition$: ({ media }: GalleryItem) => this.getSequence(media.sequenceId).pipe(
        first(), 
        map((sequence) => this.canEdit(sequence) && !this.isVideo(media)),
      ),
      execute: ({ media }: GalleryItem): void => {
        this.edit(media);
      },
    },
    {
      id: MenuActionId.GALLERY_SLIDER_DONWLOAD,
      label: 'Télécharger',
      icon: '@tui.download',
      condition: ({ media }: GalleryItem) => !this.isVideo(media),
      execute: ({ media }: GalleryItem): void => {
        this.getSequence(media.sequenceId).pipe(first()).subscribe({ 
          next: (sequence) => {
            this.downloadSequence(sequence, media);
          }, 
        });
      },
    },
  ];
  
  private readonly store = inject(Store);
  private readonly patientNamePipe = inject(PatientNamePipe);
  private readonly galleryService = inject(GalleryService);
  private readonly injector = inject(Injector);
  private readonly dialogService = inject(TuiDialogService);
  
  protected getSequence(sequenceId: number): Observable<FlattenedSequence> {
    return this.store.select(selectSequence(sequenceId)).pipe(untilDestroyed(this));
  }
  
  private downloadSequence(sequence: FlattenedSequence, media: Media): void {
    const patientName = this.patientNamePipe.transform(sequence.patientInstance);
    const createdOn = sequence.sequenceInstance.createdOn.toLocaleDateString();
    const mediaIndex = sequence.medias.findIndex((m) => media.id === m.id);
    const filename = `${patientName}_${createdOn}_${mediaIndex}`;
    downloadFile(media.uri, `${filename}.jpg`);
  }
  
  private edit(media: Media): void {
    this.galleryService.setConfig({ keyboardShortcuts: false });
    this.dialogService.open<string>(new PolymorpheusComponent(StudioComponent, this.injector), {
      data: media,
      size: 'page',
      closeable: false,
      dismissible: false,
    }).subscribe({
      next: (studioOutput: string) => {
        this.galleryService.setConfig({ keyboardShortcuts: true });
        if (studioOutput) {
          this.galleryService.navigateToLastItem();
        }
      }, 
    });
  }
  
  private canEdit(sequence: FlattenedSequence): boolean {
    return sequence.patientInstance && !sequence.patientInstance.isArchived; 
  }

  private isVideo(media: Media) {
    return media.type === MediaType.VIDEO;
  }

}
