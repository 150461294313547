/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiButton, TuiDropdown, TuiIcon, TuiSizeL, TuiSizeS, TuiSurface } from '@taiga-ui/core';
import { map } from 'rxjs';
import { FilterType } from 'src/app/shared/models/filters/filter-type.enum';
import { FlattenedFilterTemplate } from 'src/app/shared/models/filters/flattened-filter-template';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { SharedModule } from '../../shared/shared.module';
import { FilterBarService } from '../filter-bar.service';
import { FilterDateRangeComponent } from '../filter-types/filter-date-range/filter-date-range.component';
import { FilterSelectorComponent } from '../filter-types/filter-selector/filter-selector.component';

@Component({
  selector: 'pxc-filter-bar',
  templateUrl: './filter-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiIcon,
    FilterDateRangeComponent,
    FilterSelectorComponent,
    TuiButton,
    TuiDropdown,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TuiSurface,
  ],
  host: {
    class: 'flex w-full',
  },
})
export class FilterBarComponent {

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  @Input() actions: MenuAction<void>[] = [];
  @Input() searchPlaceholder = 'Rechercher ...';
  @Input() size: TuiSizeS | TuiSizeL = 'l';

  FilterType = FilterType;
  
  private readonly filterBarService = inject(FilterBarService);

  templates$ = this.filterBarService.templates$.pipe(
    map((templates) => templates.filter((template) => 
      template.type !== FilterType.TABS
      && template.type !== FilterType.BUTTONS,
    )),
  );

  search$ = this.filterBarService.search$;

  clearable$ = this.filterBarService.clearable$;

  clear(): void {
    this.filterBarService.clear();
  }

  updateTemplate(template: FlattenedFilterTemplate): void {
    this.filterBarService.updateTemplate(template);
  }

  updateSearch(value: string): void {
    this.filterBarService.updateSearch(value);
  }

  trackByTemplate(index: number, template: FlattenedFilterTemplate): string {
    return `${template.type}-${template.property}`;
  }

  focusSearch(): void {
    this.searchInput.nativeElement.focus();
  }

}
