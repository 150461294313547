import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { ComparisonGalleryInput } from '@modules/gallery/models/comparison-gallery-input';
import { LoaderComponent } from '@modules/shared/loader/loader.component';
import { SharedModule } from '@modules/shared/shared.module';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IconActionButtonsComponent } from '@shared/components/action-buttons/icon-action-buttons/icon-action-buttons.component';
import { GalleryItem } from '@shared/models/gallery-item';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { Widget } from '@shared/models/widget';
import { TuiButton } from '@taiga-ui/core';
import { GalleryComponent } from '../gallery.component';

@UntilDestroy()
@Component({
  selector: 'pxc-comparison-gallery',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    TuiButton,
    LoaderComponent,
    IconActionButtonsComponent,
  ],
  templateUrl: './comparison-gallery.component.html',
  styleUrl: './comparison-gallery.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonGalleryComponent extends GalleryComponent<ComparisonGalleryInput> implements AfterViewInit {

  @ViewChildren('insertionAnchors', { read: ViewContainerRef }) widgetHosts!: QueryList<ViewContainerRef>;

  @Input() actions: MenuAction<GalleryItem[]>[] = this.context.data?.actions;
  @Input() widgets: Widget[] = this.context.data?.widgets;

  public onWheel(event: { currentTarget: HTMLInputElement; deltaY: number; ctrlKey: boolean }): void {
    if (event.ctrlKey) {
      return;
    }
    event.currentTarget.scrollLeft += event.deltaY;
  }

  protected loadWidgets(): void {
    if (this.widgets?.length) {
      this.widgetHosts.forEach((viewContainerRef, index) => {
        this.widgetService.loadWidget(viewContainerRef, this.widgets[index]);
      });
    }
  }

  protected handleKeyPress(key: string): void {
    switch (key) {
      case 'escape': {
        this.close();
        break;
      }
      default: {
        break;
      }
    }
  }

}
