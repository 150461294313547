import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, inject, Inject, Input } from '@angular/core';
import { GalleryInput } from '@modules/gallery/models/gallery-input';
import { SharedModule } from '@modules/shared/shared.module';
import { WidgetService } from '@modules/shared/widget/widget.service';
import { MediaType } from '@pixacare/pxc-ts-core';
import { GalleryConfig } from '@shared/models/gallery-config';
import { GalleryItem } from '@shared/models/gallery-item';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { Observable } from 'rxjs';

@Component({
  selector: 'pxc-gallery',
  template: '',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class GalleryComponent<T extends GalleryInput> implements AfterViewInit {
  
  @Input() items$: Observable<GalleryItem[]> = this.context.data?.items$;
  @Input() config: GalleryConfig = this.context.data?.config;
  @Input() onImageLoadingError: (item: GalleryItem) => Promise<void> | void = this.context.data?.onImageLoadingError;
  
  MediaType = MediaType;
  
  protected readonly widgetService = inject(WidgetService);

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) 
    protected readonly context: TuiDialogContext<void, T>,
  ) { }
  
  @HostListener('document:keyup', ['$event'])
  keyPress(event: KeyboardEvent): void {
    if (!this.config.keyboardShortcuts) {
      return;
    }
    this.handleKeyPress(event.key.toLowerCase());
  }
  
  ngAfterViewInit(): void {
    this.loadWidgets();
  }

  public onPictureLoad(item: GalleryItem): void {
    item.isLoaded = true;
  }
  
  public onPictureError(item: GalleryItem): void {
    item.isLoaded = false;
    this.onImageLoadingError(item);
  }
    
  public close(): void {
    this.context.completeWith();
  }
  
  public trackByGalleryItem(item: GalleryItem): any {
    return `${item.media?.id}-${item.media?.uri}-${item.innerHtml}`;
  }
    
  protected abstract loadWidgets(): void;
  protected abstract handleKeyPress(key: string): void;
  
}
