import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Attachment } from '@shared/models/attachments/attachment';
import { StoreAttachment } from '@shared/store/attachments/models/store-attachment';
import { UploadingFile } from '../../models/attachments/uploading-file';
import { selectClientPatient } from '../patients/patients.selectors';
import { selectUsers } from '../users/users.selectors';
import { AttachmentsState } from './attachments.state';
import { AttachmentProps } from './models/props/attachment.props';

export const ATTACHMENTS_FEATURE_KEY = 'Attachments';

export const selectAttachmentsState = createFeatureSelector<AttachmentsState>(
  ATTACHMENTS_FEATURE_KEY,
);

export const selectPatientUploadingFiles = (
  { clientCode, patientId }: AttachmentProps,
): MemoizedSelector<AttachmentsState, UploadingFile[]> => createSelector(
  selectAttachmentsState,
  (state: AttachmentsState) => state[clientCode]?.[patientId]?.uploadingFiles,
);

export const selectPatientAttachments = (
  { clientCode, patientId }: AttachmentProps,
): MemoizedSelector<AttachmentsState, Attachment[]> => createSelector(
  selectAttachmentsState,
  selectUsers,
  selectClientPatient(clientCode, patientId),
  (state, users, patient) => state[clientCode]?.[patientId]?.attachments?.map((attachment: StoreAttachment) => ({
    ...attachment,
    clientCode,
    patient,
    createdBy: users[clientCode]?.[attachment.createdById],
  })),
);

export const selectPatientAttachmentExtensions = (
  { clientCode, patientId }: AttachmentProps,
): MemoizedSelector<AttachmentsState, string[]> => createSelector(
  selectAttachmentsState,
  selectPatientAttachments({ clientCode, patientId }),
  (_, attachments) => [...new Set(attachments?.map((attachment) => attachment.extension) ?? [])],
);
