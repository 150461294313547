import { inject, Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { qrCodeConfig } from '@modules/gallery/gallery.configs';
import { TelemonitoringService } from '@modules/telemonitoring/telemonitoring.service';
import { GalleryConfig } from '@shared/models/gallery-config';
import { GalleryItem } from '@shared/models/gallery-item';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { Widget } from '@shared/models/widget';
import { Observable } from 'rxjs';
import { SliderGalleryService } from '../slider-gallery.service';

@Injectable({
  providedIn: 'root',
})
export class TelemonitoringGalleryService extends SliderGalleryService<SafeHtml> {

  private readonly telemonitoringService = inject(TelemonitoringService);

  protected getEntity(telemonitoringId: number): Observable<SafeHtml> {
    return this.telemonitoringService.getQrCode(telemonitoringId);
  }

  protected getItems(entity: SafeHtml): GalleryItem[] {
    return [{ innerHtml: entity }];
  }

  protected getConfig(): GalleryConfig {
    return {
      ...qrCodeConfig,
    };
  }
  
  protected getActions(): MenuAction[] {
    return [];
  }

  protected getWidget(): Widget {
    return undefined;
  }

  protected getImageLoadingErrorHandler(): (item: GalleryItem) => void {
    return () => {};
  }

}
