import { Timestamp } from '@angular/fire/firestore';
import { Channel, ChannelType, FirestoreTimestamp } from '@pixacare/pxc-ts-core';
import { channelLabelByType } from '@modules/chat/channel-list/channel-list.config';

export const timestampToDate = (timestamp: FirestoreTimestamp): Date =>
  new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();

export const getUniqueChannelTypesFromChannels = (channels: Channel[]): ChannelType[] =>
  Array.from(new Set<ChannelType>(channels.map((channel) => channel.type)))
    .sort((a, b) => channelLabelByType[a].localeCompare(channelLabelByType[b]));
