import { UpperCasePipe } from '@angular/common';
import { FileExtensionPipe } from '@modules/attachments/pipes/file-extension.pipe';
import { PatientAttachmentsService } from '@modules/attachments/services/patient-attachments.service';
import { FilterTemplate } from '@shared/models/filters/filter-template';
import { FilterType } from '@shared/models/filters/filter-type.enum';
import { normalizedStartsWith } from '@shared/utils/utils';
import { map, Observable, of } from 'rxjs';

const defaultFileExtensionSearchOption = {
  value: '',
  display: 'Tous les fichiers',
};

const extensionToValueDisplay = (
  extension: string, 
  fileExtensionPipe: FileExtensionPipe,
  uppercasePipe: UpperCasePipe,
): { value: string; display: string } => ({
  value: extension, 
  display: uppercasePipe.transform(fileExtensionPipe.transform(extension, false)), 
});

const extensionsToValueDisplay = (
  extensions: string[],
  search: string,
  fileExtensionPipe: FileExtensionPipe,
  uppercasePipe: UpperCasePipe,
): { value: string; display: string }[] => { 
  const valueDisplays = [
    ...extensions.map((extension: string) => extensionToValueDisplay(extension, fileExtensionPipe, uppercasePipe)),
    defaultFileExtensionSearchOption,
  ];
  const filteredValueDisplays = valueDisplays.filter(({ display }) => normalizedStartsWith(display, search));
  return filteredValueDisplays;
};

export const attachmentFilterTemplates = (
  patientAttachmentService: PatientAttachmentsService,
  fileExtensionPipe: FileExtensionPipe,
  uppercasePipe: UpperCasePipe,
): Observable<FilterTemplate[]> => of([
  {
    type: FilterType.DATE_RANGE,
    property: 'createdOn',
    name: 'Date',
  },
  {
    type: FilterType.SELECTOR,
    property: 'extension',
    name: 'Type',
    getValue: (search: string) => patientAttachmentService.extensions$.pipe(
      map((extensions: string[]) => extensionsToValueDisplay(extensions, search, fileExtensionPipe, uppercasePipe)),
    ),
  },
]);
