<div class="flex flex-wrap gap-1.5">
  @let selectedIndex = selectedIndex$ | async;
  @for (button of buttons$ | async; track button.value; let index = $index) {
    <button
      tuiButton
      appearance="custom"
      size="s"
      class="!border !border-solid"
      [ngClass]="
        index === selectedIndex
          ? '!border-secondary !bg-neutral-1-pressed/30 !text-primary'
          : '!border-border-normal hover:!border-border-hover hover:bg-neutral-1'
      "
      (click)="updateFilters(button, index)"
    >
      {{ button.display }}
    </button>
  }
</div>

