import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  DragDropModule,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component, OnInit,
} from '@angular/core';
import { DrawerGalleryService } from '@modules/gallery/services/dialogs/comparisons/drawer-gallery.service';
import { SequenceMediaComponent } from '@modules/sequence/sequence-media/sequence-media.component';
import { SharedModule } from '@modules/shared/shared.module';
import { Store } from '@ngrx/store';
import { Media, MediaType } from '@pixacare/pxc-ts-core';
import { TuiButton, TuiHint, TuiIcon } from '@taiga-ui/core';
import { TuiButtonClose, TuiTooltip } from '@taiga-ui/kit';
import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { InteractiveDrawer } from 'src/app/shared/models/interactive-drawer';
import { drawerActions } from 'src/app/shared/store/drawer/drawer.actions';
import { selectInteractiveDrawer } from 'src/app/shared/store/drawer/drawer.selectors';
import { DrawerOpeningState } from '../../shared/models/enums/drawer-state.enum';

@Component({
  standalone: true,
  selector: 'pxc-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DragDropModule,
    SharedModule,
    TuiTooltip,
    TuiIcon,
    TuiButton,
    TuiHint,
    TuiButtonClose,
    SequenceMediaComponent,
  ],
})

export class DrawerComponent implements OnInit {

  DrawerState = DrawerOpeningState;
  drawer$: Observable<InteractiveDrawer>;
  isUserSorting = false;

  constructor(
    private readonly store: Store,
    private readonly drawerGalleryService: DrawerGalleryService,
  ) {}

  ngOnInit(): void {
    this.drawer$ = this.store.select(selectInteractiveDrawer);
  }

  isDropAllowed = (draggedMedia: CdkDrag<Media>, drawerDropList: CdkDropList): boolean => {
    const isMediaInDrawer = drawerDropList.data.some((media) => media.filename === draggedMedia.data.filename);
    const isMediaVideo = draggedMedia.data.type === MediaType.VIDEO;
    return !isMediaInDrawer && !isMediaVideo;
  };

  clearItems(): void {
    this.store.dispatch(drawerActions.deleteMedias());
  }

  removeThumbnail(idx: number): void {
    this.store.dispatch(drawerActions.deleteMedia({ idx }));
  }

  updateDrawerState(): void {
    let drawerState = DrawerOpeningState.OPEN;
    this.drawer$.pipe(take(1))
      .subscribe((drawer) => {
        if (drawer.state === DrawerOpeningState.OPEN) {
          drawerState = drawer.medias.length > 0 ? DrawerOpeningState.COMPACT : DrawerOpeningState.TINY;
        }
        this.store.dispatch(drawerActions.updateState({ drawerState }));
      });
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.container.id === event.previousContainer.id) {
      // move inside same list
      this.store.dispatch(drawerActions.moveMedia({
        previousIdx: event.previousIndex,
        newIdx: event.currentIndex,

      }));
    } else {
      // move between lists
      this.store.dispatch(drawerActions.insertMedia({
        newMedia: event.item.data,
        insertIdx: event.currentIndex,
      }));
    }
    this.isUserSorting = false;
  }

  loadImageInGallery(): void {
    this.drawer$.pipe(first()).subscribe((drawer) => {
      this.drawerGalleryService.openGallery(drawer.medias);
    });
  }

}
