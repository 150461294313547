import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ArchivedBadgeComponent } from '@modules/shared/archived-badge/archived-badge.component';
import { LoaderComponent } from '@modules/shared/loader/loader.component';
import { ToTuiSizePipe } from '@shared/pipes/tui-size.pipe';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiButton, TuiHint, TuiIcon, TuiNotification, TuiScrollbar, TuiTitle } from '@taiga-ui/core';
import { TuiBadge, TuiBlock, TuiButtonLoading, TuiChip, TuiElasticContainer, TuiRadio } from '@taiga-ui/kit';
import { TuiInputDateModule, TuiInputModule } from '@taiga-ui/legacy';
import { FilterBarComponent } from '../filters/filter-bar/filter-bar.component';
import { FilterTabsComponent } from '../filters/filter-tabs/filter-tabs.component';
import { SharedModule } from '../shared/shared.module';
import { SpinnerComponent } from '../shared/spinner/spinner.component';
import { StatusComponent } from '../shared/status/status.component';
import { PatientAvatarComponent } from './patient-avatar/patient-avatar.component';
import { PatientCardComponent } from './patient-card/patient-card.component';
import { PatientCreateComponent } from './patient-create/patient-create.component';
import { PatientDataComponent } from './patient-data/patient-data.component';
import { PatientEditFormComponent } from './patient-edit/patient-edit-form/patient-edit-form.component';
import { PatientEditComponent } from './patient-edit/patient-edit/patient-edit.component';
import { PatientEncounterPickerComponent } from './patient-encounter-picker/patient-encounter-picker.component';
import { PatientLinkQuickActionComponent } from './patient-link-quick-action/patient-link-quick-action.component';
import { PatientLinkDirective } from './patient-link.directive';
import { PatientListComponent } from './patient-list/patient-list.component';
import { PatientMergeComponent } from './patient-merge/patient-merge.component';
import { PatientNamePipe } from './patient-name.pipe';
import { PatientReportPreviewComponent } from './patient-report-preview/patient-report-preview.component';
import { PatientSearchListComponent } from './patient-search-list/patient-search-list.component';

@NgModule({
  declarations: [
    PatientMergeComponent,
    PatientDataComponent,
    PatientListComponent,
    PatientEditComponent,
    PatientEditFormComponent,
    PatientLinkQuickActionComponent,
    PatientEncounterPickerComponent,
    PatientReportPreviewComponent,
    PatientAvatarComponent,
    PatientCardComponent,
    PatientCreateComponent,
    PatientSearchListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TuiButton,
    TuiBlock,
    ...TuiRadio,
    TuiLet,
    TuiIcon,
    TuiBadge,
    ...TuiHint,
    TuiInputModule,
    TuiInputDateModule,
    TuiNotification,
    TuiElasticContainer,
    FilterBarComponent,
    FilterTabsComponent,
    TuiScrollbar,
    PatientLinkDirective,
    StatusComponent,
    SpinnerComponent,
    PatientNamePipe,
    ArchivedBadgeComponent,
    TuiButtonLoading,
    TuiChip,
    TuiTitle,
    ToTuiSizePipe,
    LoaderComponent,
  ],
  exports: [
    PatientListComponent,
    PatientDataComponent,
    PatientEditComponent,
    PatientLinkQuickActionComponent,
    PatientEncounterPickerComponent,
    PatientCardComponent,
    PatientAvatarComponent,
    PatientCreateComponent,
  ],
  providers: [PatientNamePipe],
})
export class PatientModule {}
