@if (attachment) {
  <div class="flex h-screen w-full flex-col items-center justify-center">
    <div class="flex items-center justify-start">
      <pxc-file-icon
        class="max-h-10 min-h-10 min-w-10 max-w-10"
        [filename]="attachment.filename"
      ></pxc-file-icon>
      <div class="mx-4">
        <div class="font-bold text-slate-50">
          <span>{{ attachment.originalFilename }}</span>
        </div>
        <div class="text-slate-200">
          <span>Fichier {{ attachment.filename | fileExtension: false | uppercase }}</span>
        </div>
      </div>
    </div>
    <div class="my-4 text-slate-200">
      <span>L'aperçu n'est pas disponible sur ce type de fichier</span>
    </div>
    <div class="text-slate-200">
      <button
        tuiButton
        size="m"
        [iconStart]="downloadAction.icon"
        (click)="downloadAction.execute(attachment)"
      >
        <span>Télécharger</span>
      </button>
    </div>
  </div>
}
