import { CommonModule, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AttachmentDragAndDropComponent } from '@modules/attachments/components/attachment-drag-and-drop/attachment-drag-and-drop.component';
import { AttachmentFiltersComponent } from '@modules/attachments/components/attachment-filters/attachment-filters.component';
import { attachmentFilterTemplates } from '@modules/attachments/components/attachment-filters/attachment-filters.config';
import { AttachmentListComponent } from '@modules/attachments/components/attachment-list/attachment-list.component';
import { UploadingFileListComponent } from '@modules/attachments/components/uploading-file-list/uploading-file-list.component';
import { FileExtensionPipe } from '@modules/attachments/pipes/file-extension.pipe';
import { PatientAttachmentsService } from '@modules/attachments/services/patient-attachments.service';
import { FILTER_TEMPLATES, FilterBarService } from '@modules/filters/filter-bar.service';
import { FilterService } from '@modules/filters/filter.service';
import { LoaderComponent } from '@modules/shared/loader/loader.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Attachment } from '@shared/models/attachments/attachment';
import { UploadingFile } from '@shared/models/attachments/uploading-file';
import { OperationState } from '@shared/models/enums/operation.enum';
import { FilteredSearch } from '@shared/models/filters/filtered-search';
import { AttachmentProps } from '@shared/store/attachments/models/props/attachment.props';
import { uuidv4 } from '@shared/utils/utils';
import { map, Observable, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    AttachmentFiltersComponent,
    AttachmentDragAndDropComponent,
    UploadingFileListComponent,
    AttachmentListComponent,
    LoaderComponent,
  ],
  providers: [
    PatientAttachmentsService,
    FilterBarService,
    FileExtensionPipe,
    UpperCasePipe,
    {
      provide: FILTER_TEMPLATES,
      deps: [
        PatientAttachmentsService,
        FileExtensionPipe,
        UpperCasePipe,
      ],
      useFactory: (
        patientAttachmentService: PatientAttachmentsService,
        fileExtensionPipe: FileExtensionPipe,
        upperCasePipe: UpperCasePipe,
      ) => attachmentFilterTemplates(patientAttachmentService, fileExtensionPipe, upperCasePipe),
    },
  ],
  templateUrl: './patient-attachments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientAttachmentsComponent {

  attachmentProps$: Observable<AttachmentProps>;
  uploadingFiles$: Observable<UploadingFile[]>;
  attachments$: Observable<Attachment[]>;

  private readonly patientService = inject(PatientAttachmentsService);
  private readonly filterBarService = inject(FilterBarService);
  private readonly filterService = inject(FilterService<Attachment>);
  
  constructor() {
    this.attachmentProps$ = this.patientService.attachmentProps$;
    this.uploadingFiles$ = this.patientService.uploadingFiles$;
    this.attachments$ = this.patientService.attachments$.pipe(
      switchMap((attachments: Attachment[]) => this.filterAttachments(attachments)),
      untilDestroyed(this),
    );
  }

  public uploadFiles(props: AttachmentProps, files: File[]): void {
    if (!files || files?.length === 0) { 
      return; 
    }       
    
    const uploadingFiles = files.map((file: File) => ({
      file,
      id: uuidv4(),
      uploadState: OperationState.ONGOING,
    }));
    
    this.patientService.dispatchUploadAttachments({ ...props, uploadingFiles });
  }

  public retryUpload(props: AttachmentProps, uploadingFile: UploadingFile): void {
    this.patientService.dispatchUploadAttachments({ ...props, uploadingFiles: [uploadingFile] });
  }

  private filterAttachments(attachments: Attachment[]): Observable<Attachment[]> {
    return this.filterBarService.filteredSearch$.pipe(
      map(({ filters, search }: FilteredSearch) => 
        this.filterService.apply(
          attachments, ['originalFilename', 'createdBy.firstName', 'createdBy.lastName'], { filters, search },
        ),
      ),
    );
  }

}
