import { defaultPagedCollection } from '../../models/pagination/default-paged-collection.config';
import { PagedCollection } from '../../models/pagination/paged-collection';
import { StoreTelemonitoring } from './models/store-telemonitoring';

type PagedTelemonitoringsCollection = PagedCollection<{ [telemonitoringId: number]: StoreTelemonitoring }>;

export interface TelemonitoringsState {
  telemonitorings: PagedTelemonitoringsCollection;
  isLoaded: boolean;
}

export const initialTelemonitoringsState: TelemonitoringsState = {
  telemonitorings: {
    ...defaultPagedCollection as PagedTelemonitoringsCollection,
  },
  isLoaded: false,
};
