@if (attachment) {
  <div
    class="flex cursor-pointer items-center justify-start"
    (click)="openPreview()"
  >
    <pxc-file-icon
      class="max-h-10 min-h-10 min-w-10 max-w-10 p-0.5"
      [filename]="attachment.filename"
    ></pxc-file-icon>
    <span class="mx-4">
      {{ attachment.originalFilename }}
    </span>
  </div>
}
