import { GalleryConfig } from '@shared/models/gallery-config';

export const sliderConfig: GalleryConfig = {
  keyboardShortcuts: true,
  navigation: true,
  thumbnails: true,
};

export const comparisonConfig: GalleryConfig = {
  keyboardShortcuts: true,
  navigation: false,
  thumbnails: false,
};

export const qrCodeConfig: GalleryConfig = {
  keyboardShortcuts: true,
  thumbnails: false,
  navigation: false,
};
