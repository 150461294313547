import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FilterBarComponent } from '@modules/filters/filter-bar/filter-bar.component';

@Component({
  selector: 'pxc-attachment-filters',
  standalone: true,
  imports: [
    FilterBarComponent,
  ],
  templateUrl: './attachment-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentFiltersComponent {

}
