import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FilterDropdownButtonComponent,
} from '@modules/filters/filter-dropdown-button/filter-dropdown-button.component';
import { SharedModule } from '@modules/shared/shared.module';
import { Filter, FilterOperator } from '@pixacare/pxc-ts-core';
import { VirtualScrollService } from '@services/virtual-scroll.service';
import { FlattenedFilterTemplate } from '@shared/models/filters/flattened-filter-template';
import { AutoCompleteModel } from '@shared/models/helpers/auto-complete-model';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiButton, TuiDataList, TuiDataListComponent, TuiScrollbar, tuiIsEditingKey } from '@taiga-ui/core';
import { TuiDataListWrapper } from '@taiga-ui/kit';
import { TuiInputModule, TuiMultiSelectModule, TuiTextfieldControllerModule } from '@taiga-ui/legacy';
import { Observable, Subject, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'pxc-filter-selector',
  templateUrl: './filter-selector.component.html',
  standalone: true,
  providers: [VirtualScrollService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FilterDropdownButtonComponent,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiScrollbar,
    TuiDataList,
    TuiDataListWrapper,
    TuiLet,
    TuiMultiSelectModule,
    TuiButton,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
})
export class FilterSelectorComponent implements OnInit {

  @ViewChild(TuiDataListComponent, { static: true }) dataList: TuiDataListComponent<AutoCompleteModel>;

  @Input() template: FlattenedFilterTemplate;
  @Output() templateChange = new EventEmitter<FlattenedFilterTemplate>();

  displayedItems$: Observable<AutoCompleteModel[]>;
  search$ = new Subject<string>();

  private readonly virtualScrollService = inject(VirtualScrollService<AutoCompleteModel>);

  ngOnInit(): void {
    this.displayedItems$ = this.search$.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((search) => {
        if (!this.template.loadNextPage) {
          return this.virtualScrollService.init(this.template.getValue(search));
        }
        return this.template.getValue(search);
      }),
    );
  }

  filterItems(items: string[]): Filter[] {
    if (items.length > 0) {
      return [{
        prop: this.template.property,
        op: FilterOperator.IN,
        val: items,
      }];
    } else {
      return [];
    }
  }

  clear(): void {
    this.templateChange.emit({
      ...this.template,
      filters: [],
    });
  }

  loadNextEntities(): void {
    if (this.template.loadNextPage) {
      this.template.loadNextPage();
    } else {
      this.virtualScrollService.loadNextPage();
    }
  }

  loadPreviousEntities(): void {
    if (this.template.loadPreviousPage) {
      this.template.loadPreviousPage();
    }
  }

  updateSelection(selection: string[]): void {
    this.templateChange.emit({
      ...this.template,
      filters: this.filterItems(selection),
    });
  }

  onKeyDown(key: string, element: HTMLElement | null): void {
    if (element && tuiIsEditingKey(key)) {
      element.focus({ preventScroll: true });
    }
  }

}
