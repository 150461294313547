import { Injectable } from '@angular/core';
import { GalleryConfig } from '@shared/models/gallery-config';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {

  public config$: Observable<GalleryConfig>;
  public navigateToItem$: Observable<number>;
  public navigateToLastItem$: Observable<void>;
  
  private configSubject = new Subject<GalleryConfig>();
  private navigateToItemSubject = new Subject<number>();
  private navigateToLastItemSubject = new Subject<void>();

  constructor() {
    this.config$ = this.configSubject.asObservable();
    this.navigateToLastItem$ = this.navigateToLastItemSubject.asObservable();
    this.navigateToItem$ = this.navigateToItemSubject.asObservable();
  }

  setConfig(config: GalleryConfig): void {
    this.configSubject.next(config);
  }

  navigateToItem(index: number): void {
    this.navigateToItemSubject.next(index);
  }

  navigateToLastItem(): void {
    this.navigateToLastItemSubject.next();
  }

}
