@for (action of actions; track action.id) {
  @if ((!action.condition || action.condition(entity)) && (!action.condition$ || (action.condition$(entity) | async))) {
    <button
      tuiIconButton
      [appearance]="appearance"
      [size]="size"
      [iconStart]="action.icon"
      [attr.data-testid]="'action-' + action.id"
      (click)="action.execute(entity)"
    ></button>
  }
}
