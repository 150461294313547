import { inject, Injectable } from '@angular/core';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { SliderGalleryActionsService } from '../../../actions/slider-gallery-actions.service';
import { SequenceGalleryService } from './sequence-gallery.service';

@Injectable({
  providedIn: 'root',
})
export class UserSequenceGalleryService extends SequenceGalleryService {

  private readonly galleryActionsService = inject(SliderGalleryActionsService);

  protected getActions(): MenuAction[] {
    return this.galleryActionsService.getActions();
  }

}
