import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Media, MediaType } from '@pixacare/pxc-ts-core';
import { GalleryItem } from '@shared/models/gallery-item';
import { ActionsService } from '@shared/models/helpers/actions-service';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { MenuActionId } from '@shared/models/menu-actions/menu-action-id.enum';
import { downloadFile } from '@shared/utils/utils';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ComparisonGalleryActionsService extends ActionsService {

  actions: MenuAction<GalleryItem[]>[] = [
    {
      id: MenuActionId.GALLERY_COMPARISON_EDIT,
      label: 'Télécharger',
      icon: '@tui.download',
      condition: (items: GalleryItem[]) => !this.containsVideos(items.map(({ media }) => media)),
      execute: (items: GalleryItem[]): void => {
        this.download(items.map(({ media }) => media));
      },
    },
  ];

  private download(medias: Media[]): void {
    if (medias.length === 1) {
      this.downloadSingleImage(medias[0].uri);
    } else {
      this.downloadMultipleImage(medias);
    }
  }

  private downloadSingleImage(source: string): void {
    downloadFile(source, 'mon-image.jpg');
  }

  private async downloadMultipleImage(
    medias: Media[],
    downloadWidth: number = 1920,
    picturePerRow: number = 3,
    pictureGap: number = 10,
  ): Promise<void> {
    const images = await Promise.all(medias.map((source) => this.uriToImage(source.uri)));
    const sortedImages: HTMLImageElement[][] = images.reduce((acc, thumb, index) => (
      index % picturePerRow === 0
        ? [...acc, images.slice(index, index + picturePerRow)]
        : acc
    ), []);

    let imageHeight = 0;
    const heightPerLine = [];
    sortedImages.forEach((line) => {
      let highestImage = 0;
      line.forEach((image) => {
        const maxWidth = downloadWidth / Math.min(medias.length, picturePerRow);
        const widthRatio = maxWidth / image.width;
        image.width *= widthRatio;
        image.height *= widthRatio;

        highestImage = Math.max(highestImage, image.height);
      });
      imageHeight += highestImage;
      heightPerLine.push(highestImage);
      highestImage = 0;
    });

    const canvas = document.createElement('canvas');
    canvas.height = imageHeight + (heightPerLine.length - 1) * pictureGap;
    canvas.width = downloadWidth + (Math.min(medias.length, picturePerRow) - 1) * pictureGap;

    const context = canvas.getContext('2d');
    let y = 0;
    sortedImages.forEach((line, index) => {
      let x = 0;
      line.forEach((image) => {
        context.drawImage(image, x, y + (heightPerLine[index] / 2) - (image.height / 2), image.width, image.height);
        x += image.width + pictureGap;
      });
      y += heightPerLine[index] + pictureGap;
    });

    downloadFile(canvas.toDataURL('image/png'), 'ma-comparaison.png');
  }

  private async uriToImage(uri: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      image.src = uri;
      image.onload = () => resolve(image);
      image.onerror = (err) => reject(err);
    });
  }

  private containsVideos(medias: Media[]): boolean {
    return medias?.some((media: Media) => media.type === MediaType.VIDEO);
  }
  
}
