import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileExtension',
  standalone: true,
})
export class FileExtensionPipe implements PipeTransform {

  transform(name: string, dot: boolean = true): string {
    if (!name) {
      return undefined;
    }
    
    const extension = name.split('.').pop()?.toLowerCase();
    return dot && extension ? `.${extension}` : extension;
  }

}
