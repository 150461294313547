@let data =
  {
    resolvedShare: resolvedShare$ | async,
    error: resolvedShareError$ | async,
  };
@if (!data.error) {
  @if (data.resolvedShare; as resolvedShare) {
    @switch (resolvedShare.objectType) {
      @case (ObjectType.SEQUENCE) {
        @let sequence = resolvedShare.object;
        <pxc-sequence-preview
          class="cursor-auto"
          [sequence]="sequence"
          [mobileMode]="true"
          [patientRedirect]="false"
          (mediaClicked)="mediaClicked($event)"
          (click)="$event.stopPropagation()"
        >
          <a [sequenceSadmLink]="sequence">Analyse</a>
        </pxc-sequence-preview>
      }
    }
  } @else {
    <div
      class="h-32 w-52 max-w-full rounded-lg lg:w-96"
      [tuiSkeleton]="true"
    ></div>
  }
} @else {
  <span class="text-negative">Une erreur est survenue lors de la récupération du partage</span>
}
