import { Pipe, PipeTransform } from '@angular/core';
import { FileExtension } from '@shared/models/enums/file-extension.enum';

@Pipe({
  name: 'fileIcon',
  standalone: true,
})
export class FileIconPipe implements PipeTransform {

  transform(extension: string): string {
    const fileExtension = this.getExtension(extension) ?? 'default';
    return this.getFileIconPath(fileExtension); 
  }

  private getExtension(extension: string): FileExtension | undefined {
    return Object.values(FileExtension).find(
      (value: FileExtension) => extension === value || extension === value.replace('.', ''),
    );
  }

  private getFileIconPath(extension: string): string {
    return `/assets/icons/attachments/file-icon-${extension.replace('.', '')}.png`;
  }

}
