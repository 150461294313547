<tui-elastic-container class="sm:min-h-[50vh] lg:min-h-[70vh]">
  <div>
    <progress
      tuiProgressBar
      class="mb-4 mt-2"
      [max]="computedSections.length"
      [segments]="computedSections.length"
      [value]="maxSectionIndex + 1"
      [tuiProgressColorSegments]="computedSectionsColors"
      size="s"
      data-testid="progress-bar"
    ></progress>
    <p class="text-sm text-secondary">Questionnaire {{ activeSectionIndex + 1 }} sur {{ computedSections.length }}</p>
  </div>

  <form
    [formGroup]="formGroup"
    *tuiLet="computedSections[activeSectionIndex] as activeSection"
    class="flex flex-col gap-4 py-2"
    data-testid="form"
  >
    <h3
      tuiTitle="m"
      class="mb-2 font-normal"
    >
      {{ activeSection.title }}
    </h3>

    @for (question of activeSection.questions; track question) {
      @let isQuestionReadonly = question.parameters.readonly || readOnly;
      @let questionClass =
        {
          'pointer-events-none opacity-75': question.parameters.readonly,
        };
      @if (isQuestionVisible(question.id)) {
        <div
          class="flex flex-col gap-2"
          [attr.data-testid]="question.id"
        >
          <p class="font-medium">
            {{ question.title ?? question.label }}
            @if (question.parameters.required) {
              <span class="text-status-negative">*</span>
            }
          </p>
          @switch (question.type) {
            @case (QuestionType.TEXT) {
              <tui-textarea
                [expandable]="true"
                maxlength="65535"
                [formControlName]="question.id"
                [readOnly]="isQuestionReadonly"
                [ngClass]="questionClass"
                [tuiTextfieldLabelOutside]="true"
                data-testid="text-input"
                ngDefaultControl
              >
                {{ question.parameters.placeholder }}
              </tui-textarea>
            }
            @case (QuestionType.RICH_TEXT) {
              <tui-textarea
                [expandable]="true"
                maxlength="65535"
                [formControlName]="question.id"
                [readOnly]="isQuestionReadonly"
                [ngClass]="questionClass"
                [tuiTextfieldLabelOutside]="true"
                data-testid="rich-text-input"
                ngDefaultControl
              >
                {{ question.parameters.placeholder }}
              </tui-textarea>
            }
            @case (QuestionType.NUMBER) {
              <ng-container>
                <tui-input-number
                  [formControlName]="question.id"
                  [readOnly]="isQuestionReadonly"
                  [ngClass]="questionClass"
                  [tuiTextfieldLabelOutside]="true"
                  [step]="question.parameters.step"
                  [tuiTextfieldPostfix]="question.parameters.unit || ''"
                  data-testid="number-input"
                  ngDefaultControl
                >
                  <ng-container *ngTemplateOutlet="numberPlaceholder"></ng-container>
                </tui-input-number>
              </ng-container>
              <ng-template #numberPlaceholder>
                {{ question.parameters.placeholder ?? question.title + ' en ' + question.parameters.unit }}
              </ng-template>
            }
            @case (QuestionType.BOOL) {
              <input
                tuiSwitch
                type="checkbox"
                [formControlName]="question.id"
                [readOnly]="isQuestionReadonly"
                [ngClass]="questionClass"
                size="m"
                data-testid="toggle-input"
                ngDefaultControl
              />
            }
            @case (QuestionType.CHECKBOX) {
              <div
                class="flex flex-wrap gap-2"
                [formGroupName]="question.id"
              >
                @for (choice of question.choices; track choice) {
                  <label tuiBlock="m">
                    <input
                      tuiCheckbox
                      type="checkbox"
                      [formControlName]="choice.key"
                      [readOnly]="isQuestionReadonly"
                      [ngClass]="questionClass"
                      [attr.data-testid]="choice.key"
                      ngDefaultControl
                    />
                    {{ choice.value }}
                  </label>
                }
              </div>
            }
            @case (QuestionType.RADIO) {
              <div class="flex flex-wrap gap-2">
                @for (choice of question.choices; track choice) {
                  <label tuiBlock="m">
                    <input
                      tuiRadio
                      type="radio"
                      [formControlName]="question.id"
                      [value]="choice.key"
                      [readOnly]="isQuestionReadonly"
                      [ngClass]="questionClass"
                      [attr.data-testid]="choice.key"
                      ngDefaultControl
                    />
                    {{ choice.value }}
                  </label>
                }
              </div>
            }
            @case (QuestionType.SLIDER_LABEL) {
              <!-- TODO -->
            }
            @case (QuestionType.DATE) {
              <!-- TODO -->
            }
            @case (QuestionType.TIME) {
              <!-- TODO -->
            }
          }
          <tui-error
            [formControlName]="question.id"
            [error]="[] | tuiFieldError | async"
            data-testid="error-display"
          ></tui-error>
        </div>
      }
    }
  </form>
</tui-elastic-container>

<div class="mt-4 flex gap-2 max-sm:flex-col-reverse max-sm:items-stretch">
  <button
    tuiButton
    appearance="outline"
    (click)="previous()"
    class="sm:mr-auto"
    data-testid="previous-button"
  >
    {{ activeSectionIndex === 0 ? 'Annuler' : 'Précédent' }}
  </button>
  <button
    tuiButton
    [appearance]="activeSectionIndex !== computedSections.length - 1 ? 'secondary' : 'primary'"
    (click)="finish()"
    [disabled]="!formGroup?.valid"
    [tuiHint]="!formGroup?.valid && hint"
    tuiHintShowDelay="200"
    tuiHintDirection="top"
    tuiHintAppearance="error"
    data-testid="finish-button"
  >
    Terminer
  </button>

  @if (activeSectionIndex !== computedSections.length - 1) {
    <button
      tuiButton
      (click)="next()"
      [disabled]="computedSectionsValidity[activeSectionIndex] === SectionValidity.INVALID"
      [tuiHint]="computedSectionsValidity[activeSectionIndex] === SectionValidity.INVALID && hint"
      tuiHintShowDelay="200"
      tuiHintDirection="top"
      tuiHintAppearance="error"
      data-testid="next-button"
    >
      Suivant
    </button>
  }

  <ng-template #hint>Au moins une des valeurs saisies est incorrecte.</ng-template>
</div>
