import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component, Inject, OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  CreatorType,
  Patient,
  Telemonitoring,
} from '@pixacare/pxc-ts-core';
import { AppConfigHttpService } from '@services/http/app-config.http.service';
import { LocalStorageService } from '@services/local-storage.service';
import { ActionState } from '@shared/models/helpers/action-state';
import { UserInputSelection } from '@shared/models/helpers/user-input-selection';
import { defaultReminderDaysInterval, telemonitoringRequestStepsConfig } from '@shared/models/telemonitoring/telemonitoring-request.config';
import { TuiButton, TuiDialogContext } from '@taiga-ui/core';
import { TuiElasticContainer, TuiStepper } from '@taiga-ui/kit';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import {
  Observable,
} from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TelemonitoringRequestStep } from 'src/app/shared/models/enums/telemonitoring-request-step.enum';
import { TelemonitoringCollaboratorsComponent } from '../telemonitoring-collaborators/telemonitoring-collaborators.component';
import { TelemonitoringRequestDetailsComponent } from '../telemonitoring-request-details/telemonitoring-request-details.component';
import { TelemonitoringRequestLocationComponent } from '../telemonitoring-request-location/telemonitoring-request-location.component';
import { TelemonitoringRequestResultComponent } from '../telemonitoring-request-result/telemonitoring-request-result.component';
import { TelemonitoringRequestSequencesComponent } from '../telemonitoring-request-sequences/telemonitoring-request-sequences.component';
import { TelemonitoringRequestTypeComponent } from '../telemonitoring-request-type/telemonitoring-request-type.component';
import { TelemonitoringService } from '../telemonitoring.service';
import { TelemonitoringRequestForm } from './telemonitoring-request-form';
import { TelemonitoringRequestProps } from './telemonitoring-request-props';
import { TelemonitoringRequestService } from './telemonitoring-request.service';
import {
  validateDepartmentIds,
  validatePatientContact,
  validatePatientForm,
} from './telemonitoring-request.validators';

const SHOULD_SEND_TEXT_MESSAGE_TO_PATIENT_KEY = 'telemonitoring-should-send-text-message-to-patient';
const ENABLE_AUTOMATIC_REMINDER_FOR_INACTIVE_PATIENT = 'telemonitoring-enable-automatic-reminder-for-inactive-patient';

@Component({
  templateUrl: './telemonitoring-request.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiStepper,
    TuiElasticContainer,
    TuiButton,
    FormsModule,
    ReactiveFormsModule,
    TelemonitoringRequestTypeComponent,
    TelemonitoringRequestDetailsComponent,
    TelemonitoringRequestSequencesComponent,
    TelemonitoringRequestLocationComponent,
    TelemonitoringRequestResultComponent,
    TelemonitoringCollaboratorsComponent,
  ],
  providers: [
    TelemonitoringRequestService,
  ],
})
export class TelemonitoringRequestComponent implements OnInit {

  activePageIndex = 0;
  isLoading = false;
  defaultName: string = null;

  form: FormGroup;

  TelemonitoringRequestStep = TelemonitoringRequestStep;
  telemonitoringRequestStepsConfig = telemonitoringRequestStepsConfig;

  state$: Observable<ActionState<Telemonitoring>>;

  constructor(
    private readonly telemonitoringHelperService: TelemonitoringService,
    private readonly telemonitoringRequestService: TelemonitoringRequestService,
    private readonly authenticationService: AuthenticationService,
    private readonly configuration: AppConfigHttpService,
    private readonly localStorage: LocalStorageService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, TelemonitoringRequestProps>,
  ) { }

  get patient(): Patient {
    return this.context.data.patient;
  }

  get patientId(): number {
    return this.context.data.patient.id;
  }

  get departmentIds(): number[] {
    return this.context.data.departmentIds;
  }

  get clientCode(): string {
    return this.context.data.clientCode;
  }

  get shouldSendTextMessageToPatient(): boolean {
    return this.form.get('shouldSendTextMessageToPatient').value;
  }

  get enableAutomaticReminderForInactivePatient(): boolean {
    return this.form.get('enableAutomaticReminderForInactivePatient').value;
  }

  get reminderDaysInterval(): number {
    return this.form.get('reminderDaysInterval').value;
  }

  ngOnInit(): void {
    this.defaultName = this.telemonitoringHelperService.getTelemonitoringDefaultName(this.patient);

    const currentUser = this.authenticationService.currentUser;

    const selectedCollaborator: UserInputSelection = {
      user: {
        id: currentUser.id,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        medicalSpecialtyLabel: 'Vous',
        clientNames: null,
        liveId: null,
      },
    };

    this.form = new FormGroup<TelemonitoringRequestForm>({
      name: new FormControl('', [Validators.maxLength(256)]),
      patientId: new FormControl(this.patient.id, [Validators.required]),
      clientCode: new FormControl(this.clientCode, [Validators.required]),
      departmentIds: new FormControl(this.departmentIds),
      sequences: new FormControl(this.context.data.sequences),
      message: new FormControl(),
      collaborators: new FormControl([selectedCollaborator], [Validators.minLength(1)]),
      patientContact: new FormControl(),
      patientForm: new FormControl(),
      creatorType: new FormControl(CreatorType.BY_USER, [Validators.required]),
      shouldSendTextMessageToPatient: new FormControl(
        (this.localStorage.get(SHOULD_SEND_TEXT_MESSAGE_TO_PATIENT_KEY) ?? 'true') === 'true',
      ),
      enableAutomaticReminderForInactivePatient: new FormControl(
        (this.localStorage.get(ENABLE_AUTOMATIC_REMINDER_FOR_INACTIVE_PATIENT) ?? 'true') === 'true',
      ),
      reminderDaysInterval: new FormControl(
        defaultReminderDaysInterval,
        [Validators.min(1)],
      ),
    });

    // add dynamic validators
    this.form.get('departmentIds').addValidators(
      validateDepartmentIds(this.form),
    );
    this.form.get('patientContact').addValidators(
      validatePatientContact(this.form, this.configuration.configuration.inputValidation.phoneNumber.regex),
    );
    this.form.get('patientForm').addValidators(
      validatePatientForm(this.form),
    );

    // we want the error message to be shown directly on departmentIds
    this.form.get('departmentIds').markAsTouched();

  }

  isStepValid(fields: string[]): boolean {
    if (!fields) {
      return true;
    }
    return fields.every((field) => this.form.get(field).valid);
  }

  pickCreatorType(type: CreatorType): void {
    this.form.get('creatorType').setValue(type);
    this.nextPage();
  }

  previousPage(): void {
    if (this.activePageIndex === 0) {
      this.context.completeWith();
    } else {
      this.activePageIndex--;
    }
  }

  nextPage(): void {
    if (this.activePageIndex === telemonitoringRequestStepsConfig.length - 1) {
      this.createTelemonitoring();
    } else {
      this.activePageIndex++;
    }
  }

  createTelemonitoring(): void {
    this.localStorage.set(SHOULD_SEND_TEXT_MESSAGE_TO_PATIENT_KEY, this.shouldSendTextMessageToPatient.toString());
    this.localStorage.set(
      ENABLE_AUTOMATIC_REMINDER_FOR_INACTIVE_PATIENT,
      this.enableAutomaticReminderForInactivePatient.toString(),
    );
    this.state$ = this.telemonitoringRequestService.createMonitoring(this.form);
    this.isLoading = true;
  }

  closeDialog(): void {
    this.context.completeWith();
  }

}
