import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FileSizePipe } from '@modules/attachments/pipes/file-size.pipe';
import { SpinnerComponent } from '@modules/shared/spinner/spinner.component';
import { UploadingFile } from '@shared/models/attachments/uploading-file';
import { OperationState } from '@shared/models/enums/operation.enum';
import { TuiButton, TuiIcon } from '@taiga-ui/core';
import { FileIconComponent } from '../file-icon/file-icon.component';

@Component({
  selector: 'pxc-uploading-file-list',
  standalone: true,
  imports: [
    FileIconComponent,
    SpinnerComponent,
    FileSizePipe,
    TuiButton,
    TuiIcon,
  ],
  templateUrl: './uploading-file-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadingFileListComponent {

  @Input() uploadingFiles: UploadingFile[];

  @Output() retryUpload = new EventEmitter<UploadingFile>();

  OperationState = OperationState;

  public retry(uploadingFile: UploadingFile): void {
    this.retryUpload.emit(uploadingFile);
  }

}
