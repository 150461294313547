import { inject, Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentUriResponse } from '@pixacare/pxc-ts-core';
import { AttachmentHttpService } from '@services/http/attachment.http.service';
import { Attachment } from '@shared/models/attachments/attachment';
import { FileExtension } from '@shared/models/enums/file-extension.enum';
import { fetchBlob } from '@shared/utils/utils';
import { TuiDialogService } from '@taiga-ui/core';
import { TuiPdfViewerService } from '@taiga-ui/kit';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { Observable, switchMap } from 'rxjs';
import { UnsupportedAttachmentPreviewComponent } from '../components/unsupported-attachment-preview/unsupported-attachment-preview.component';

@Injectable()
export class AttachmentPreviewService {
  
  private readonly attachmentService = inject(AttachmentHttpService);
  
  private readonly injector = inject(Injector);
  private readonly sanitizer = inject(DomSanitizer);
  private readonly pdfService = inject(TuiPdfViewerService);
  private readonly dialogService = inject(TuiDialogService);

  public openPreview(attachment: Attachment): Observable<void> {
    switch (attachment.extension) {
      case FileExtension.PDF:
        return this.openPdfPreview(attachment);
      default:
        return this.openUnsupportedPreview(attachment);
    }
  }

  private openPdfPreview({ clientCode, patientId, id: attachmentId }: Attachment): Observable<void> {
    return this.attachmentService.getAttachmentUri({ clientCode, patientId, attachmentId }).pipe(
      switchMap(({ uri }: AttachmentUriResponse) => fetchBlob(uri)),
      switchMap((blob: Blob) => {
        const blobUrl = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
        const safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
        return this.pdfService.open<void>(safeResourceUrl, { 
          label: 'Pixacare', 
        });
      }),
    );
  }
  
  private openUnsupportedPreview(attachment: Attachment): Observable<void> {
    return this.dialogService.open<void>(
      new PolymorpheusComponent(UnsupportedAttachmentPreviewComponent, this.injector), {
        data: {
          attachment,
        },
        size: 'page',
        closeable: true,
        dismissible: false,
      },
    );
  }

}
