import { Directive, ElementRef, inject, Input } from '@angular/core';
import { TelemonitoringLinkDirective } from '@modules/telemonitoring/directives/telemonitoring-link.directive';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';

@Directive({
  selector: '[sequenceTelemonitoringLink]',
  standalone: true,
  hostDirectives: [TelemonitoringLinkDirective],
})
export class SequenceTelemonitoringLinkDirective { 

  private readonly elementRef = inject(ElementRef);
  private readonly telemonitoringLinkDirective = inject(TelemonitoringLinkDirective);

  @Input() set sequenceTelemonitoringLink(sequence: FlattenedSequence) {

    if (!sequence || !sequence.sequenceInstance.telemonitoringId) {
      this.elementRef.nativeElement.style.display = 'none';
      return;
    }

    this.telemonitoringLinkDirective.telemonitoringLink = sequence.sequenceInstance.telemonitoringId;

  }

}
