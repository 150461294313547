import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { AttachmentResponse } from '@pixacare/pxc-ts-core';
import { AttachmentHttpService } from '@services/http/attachment.http.service';
import { OperationState } from '@shared/models/enums/operation.enum';
import { catchError, concat, concatMap, from, mergeMap, Observable, of, switchMap } from 'rxjs';
import { UploadingFile } from '../../models/attachments/uploading-file';
import { attachmentsActions } from './attachments.actions';

@Injectable()
export class AttachmentsEffects {

  uploadAttachments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentsActions.uploadAttachments),
      mergeMap(({ clientCode, patientId, uploadingFiles }) =>
        from(uploadingFiles).pipe(
          mergeMap(({ id: uploadingFileId, file }: UploadingFile) =>
            concat(
              of(attachmentsActions.updateUploadingFileState({
                clientCode,
                patientId,
                uploadingFileId,
                uploadState: OperationState.ONGOING,
              })),
              this.attachmentService.uploadAttachments({ clientCode, patientId, files: [file] }).pipe(
                concatMap((attachments: AttachmentResponse[]) => [
                  attachmentsActions.updateUploadingFileState({
                    clientCode,
                    patientId,
                    uploadingFileId,
                    uploadState: OperationState.FINISHED,
                  }),
                  attachmentsActions.removeUploadingFile({ 
                    clientCode, 
                    patientId, 
                    uploadingFileId, 
                  }),
                  attachmentsActions.uploadAttachmentsSuccess({
                    clientCode,
                    patientId,
                    attachments,
                  }),
                ],
                ),
                catchError(() => 
                  of(attachmentsActions.updateUploadingFileState({
                    clientCode,
                    patientId,
                    uploadingFileId,
                    uploadState: OperationState.FAILED,
                  })),
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  getPatientAttachments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentsActions.getPatientAttachments),
      mergeMap(({ clientCode, patientId }) =>
        this.attachmentService.getPatientAttachment({ clientCode, patientId }).pipe(
          switchMap((attachments: AttachmentResponse[]) => [
            attachmentsActions.getPatientAttachmentsSuccess({
              clientCode,
              patientId,
              attachments,
            }),
          ]),
        ),
      ),
    ),
  );

  deleteAttachment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentsActions.deleteAttachment),
      mergeMap(({ clientCode, patientId, attachmentId }) =>
        this.attachmentService.deleteAttachment({ clientCode, patientId, attachmentId }).pipe(
          switchMap(() => [
            attachmentsActions.deleteAttachmentSuccess({
              clientCode,
              patientId,
              attachmentId,
            }),
          ]),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly attachmentService: AttachmentHttpService,
  ) {}

}
