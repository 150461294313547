import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { isFirebaseAuthenticatedGuard } from '@guards/is-firebase-authenticated.guard';
import { isUserClientAdminGuard } from '@guards/is-user-client-admin.guard';
import { isUserDepartmentAdminGuard } from '@guards/is-user-department-admin.guard';
import { isValidUserGuard } from '@guards/is-valid-user.guard';
import { mustChangePasswordGuard } from '@guards/must-change-password.guard';
import { redirectToChannelGuard } from '@guards/redirect-to-channel.guard';
import { redirectToPatientGuard } from '@guards/redirect-to-patient.guard';
import { isAuthenticatedGuard } from 'src/app/guards/is-authenticated.guard';
import { ClientAdministrationTabs } from 'src/app/shared/models/enums/client-administration-tabs.enum';
import { DepartmentAdministrationTabs } from 'src/app/shared/models/enums/department-administration-tabs.enum';
import { PatientTab } from 'src/app/shared/models/enums/patient-tabs.enum';
import { ChannelCreateService } from '../chat/channel-create/channel-create.service';
import { ChannelPaneEmptyComponent } from '../chat/channel-pane-empty/channel-pane-empty.component';
import { ChannelPaneComponent } from '../chat/channel-pane/channel-pane.component';
import { ChatPageComponent } from '../chat/chat-page/chat-page.component';
import { FirebaseAuthenticationService } from '../chat/firebase-authentication.service';
import { ClientAdminComponent } from '../client/client-admin/client-admin.component';
import { ClientDepartmentsAdminComponent } from '../client/client-departments-admin/client-departments-admin.component';
import { ClientUsersAdminComponent } from '../client/client-users-admin/client-users-admin.component';
import { DepartmentAdminComponent } from '../department/department-admin/department-admin.component';
import {
  DepartmentLabelsAdminComponent,
} from '../department/department-labels-admin/department-labels-admin.component';
import { DepartmentUsersAdminComponent } from '../department/department-users-admin/department-users-admin.component';
import { DrawerComponent } from '../drawer/drawer.component';
import { ClientCodeService } from './client-code.service';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { HomeComponent } from './home/home.component';
import { LabelsComponent } from './labels/labels.component';
import { LargeLayoutComponent } from './large-layout/large-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { MediumLayoutComponent } from './medium-layout/medium-layout.component';
import { PatientAnalysesComponent } from './patient-analyses/patient-analyses.component';
import { PatientAttachmentsComponent } from './patient-attachments/patient-attachments.component';
import { PatientSequencesComponent } from './patient-sequences/patient-sequences.component';
import { PatientTelemonitoringsComponent } from './patient-telemonitorings/patient-telemonitorings.component';
import { PatientComponent } from './patient/patient.component';
import { PatientsComponent } from './patients/patients.component';
import { SadmComponent } from './sadm/sadm.component';
import { SequencesComponent } from './sequences/sequences.component';
import { SharedtomeComponent } from './sharedtome/sharedtome.component';
import { TelemonitoringComponent } from './telemonitoring/telemonitoring.component';
import { TelemonitoringsComponent } from './telemonitorings/telemonitorings.component';

const drawer: Route = {
  path: '',
  component: DrawerComponent,
  outlet: 'drawer',
};

const patientRoutes: Route =
  {
    path: 'patients',
    children: [
      {
        path: '',
        component: PatientsComponent,
        canActivate: [redirectToPatientGuard],
      },
      {
        path: ':patientId',
        children: [
          {
            path: '',
            component: PatientComponent,
            children: [
              { path: '', pathMatch: 'full', redirectTo: PatientTab.SEQUENCES },
              {
                path: PatientTab.SEQUENCES,
                component: PatientSequencesComponent,
              },
              {
                path: PatientTab.ANALYSES,
                component: PatientAnalysesComponent,
              },
              {
                path: PatientTab.TELEMONITORINGS,
                component: PatientTelemonitoringsComponent,
              },
              {
                path: PatientTab.ATTACHMENTS,
                component: PatientAttachmentsComponent,
              },
            ],
          },
          drawer,
        ],
      },
    ],
  };

const sequenceRoutes: Route = {
  path: 'sequences',
  children: [
    {
      path: '',
      component: SequencesComponent,
    },
    drawer,
  ],
};

const appRoutes: Routes = [
  {
    path: 'dashboard',
    component: MainLayoutComponent,
    canActivate: [
      isAuthenticatedGuard,
      isValidUserGuard,
      mustChangePasswordGuard,
      isFirebaseAuthenticatedGuard,
    ],
    providers: [
      ClientCodeService,
      FirebaseAuthenticationService,
    ],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: '',
        component: HomeLayoutComponent,
        children: [
          {
            path: 'home',
            component: HomeComponent,
          },
        ],
      },
      {
        path: '',
        component: MediumLayoutComponent,
        children: [
          {
            path: 'labels',
            component: LabelsComponent,
          },
          {
            path: 'profile',
            loadChildren: () => import('../profile/profile.module').then((m) => m.ProfileModule),
          },
          {
            path: 'sharedtome',
            children: [
              {
                path: '',
                component: SharedtomeComponent,
              },
              drawer,
            ],
          },
          sequenceRoutes,
          patientRoutes,
          {
            path: 'telemonitorings',
            children: [
              {
                path: '',
                component: TelemonitoringsComponent,
              },
              {
                path: ':tmId',
                children: [
                  {
                    path: '',
                    component: TelemonitoringComponent,
                  },
                  drawer,
                ],
              },
            ],
          },
          {
            path: 'admin',
            component: ClientAdminComponent,
            canActivate: [
              isUserClientAdminGuard,
            ],
            children: [
              { path: '', pathMatch: 'full', redirectTo: ClientAdministrationTabs.USERS },
              {
                path: ClientAdministrationTabs.USERS,
                component: ClientUsersAdminComponent,
              },
              {
                path: ClientAdministrationTabs.DEPARTMENTS,
                component: ClientDepartmentsAdminComponent,
              },
            ],
          },
          {
            path: 'department',
            children: [
              {
                path: ':departmentId',
                children: [
                  sequenceRoutes,
                  patientRoutes,
                  {
                    path: 'admin',
                    component: DepartmentAdminComponent,
                    canActivate: [
                      isUserDepartmentAdminGuard,
                    ],
                    children: [
                      { path: '', pathMatch: 'full', redirectTo: DepartmentAdministrationTabs.USERS },
                      {
                        path: DepartmentAdministrationTabs.USERS,
                        component: DepartmentUsersAdminComponent,
                      },
                      {
                        path: DepartmentAdministrationTabs.LABELS,
                        component: DepartmentLabelsAdminComponent,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '',
        component: LargeLayoutComponent,
        children: [
          {
            path: 'chat',
            canActivate: [redirectToChannelGuard],
            providers: [
              ChannelCreateService,
            ],
            children: [
              {
                path: '',
                component: ChatPageComponent,
                children: [
                  {
                    path: '',
                    component: ChannelPaneEmptyComponent,
                  },
                  {
                    path: ':channelId',
                    component: ChannelPaneComponent,
                  },
                ],
              },
            ],
          },
          {
            path: 'sadms',
            children: [
              {
                path: ':sadmEntityId',
                component: SadmComponent,
              },
            ],
          },
        ],
      },
      { path: '**', redirectTo: '/dashboard/home' },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(appRoutes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
