import { inject, Injectable, Injector } from '@angular/core';
import { SliderGalleryComponent } from '@modules/gallery/components/gallery/slider-gallery/slider-gallery.component';
import { SliderGalleryInput } from '@modules/gallery/models/slider-gallery-input';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { GalleryConfig } from '@shared/models/gallery-config';
import { GalleryItem } from '@shared/models/gallery-item';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { Widget } from '@shared/models/widget';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { first, map, Observable } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export abstract class SliderGalleryService<T> {

  protected readonly store = inject(Store);
  protected readonly injector = inject(Injector);
  protected readonly dialogService = inject(TuiDialogService);
  
  public openGallery(clientCode: string, entityId: number, openIndex: number = 0): void {
    this.getEntity(entityId, clientCode).pipe(first()).subscribe({ 
      next: (entity) => {
        const galleryInput = this.getInput(clientCode, entityId, entity, openIndex);
        this.openSliderGallery(galleryInput).subscribe();
      },
    });
  }
    
  protected getInput(clientCode: string, entityId: number, entity: T, selectedIndex: number): SliderGalleryInput {
    return {
      items$: this.getEntity(entityId, clientCode).pipe(
        map((e) => this.getItems(e).map((item) => ({ ...item, isLoaded: true }))),
        untilDestroyed(this),
      ),
      selectedIndex,
      config: this.getConfig(),
      actions: this.getActions(),
      widget: this.getWidget(entity),
      onImageLoadingError: this.getImageLoadingErrorHandler(),
    };
  }

  private openSliderGallery({
    items$,
    selectedIndex,
    config,
    actions,
    widget,
    onImageLoadingError,
  }: SliderGalleryInput): Observable<void> {
    return this.dialogService.open<void>(
      new PolymorpheusComponent(SliderGalleryComponent, this.injector),
      {
        data: {
          items$,
          selectedIndex,
          config,
          actions,
          widget,
          onImageLoadingError,
        },
        size: 'page',
        closeable: false,
        dismissible: false,
      },
    );
  
  }

  protected abstract getEntity(entityId: number, clientCode?: string): Observable<T>;
  protected abstract getItems(entity: T): GalleryItem[];
  protected abstract getConfig(): GalleryConfig;
  protected abstract getActions(): MenuAction[];
  protected abstract getWidget(entity: T): Widget;
  protected abstract getImageLoadingErrorHandler(): (item: GalleryItem) => void;

}
