<div
  class="flex h-28 cursor-pointer flex-col items-center justify-evenly rounded-lg border-2 border-dashed border-border-normal bg-white"
  (click)="fileInput.click()"
  (dragover)="onDragOver($event)"
  (drop)="onDrop($event)"
>
  <tui-icon
    class="m-1"
    icon="@tui.file-plus-2"
  ></tui-icon>
  <span class="m-1">Sélectionnez ou déposez un fichier ({{ fileAcceptTypes }})</span>
</div>

<input
  class="hidden"
  multiple="true"
  type="file"
  #fileInput
  [accept]="fileAcceptTypes"
  (change)="onFileSelected($event)"
/>
