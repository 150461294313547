import { inject, Injectable, Injector } from '@angular/core';
import { ComparisonGalleryComponent } from '@modules/gallery/components/gallery/comparison-gallery/comparison-gallery.component';
import { comparisonConfig } from '@modules/gallery/gallery.configs';
import { ComparisonGalleryInput } from '@modules/gallery/models/comparison-gallery-input';
import { SequencePreviewDrawerComponent } from '@modules/sequence/sequence-preview-drawer/sequence-preview-drawer.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FlattenedSequence, Media } from '@pixacare/pxc-ts-core';
import { MediaHttpService } from '@services/http/media.http.service';
import { GalleryItem } from '@shared/models/gallery-item';
import { sequencesActions } from '@shared/store/sequences/sequences.actions';
import { selectSequence } from '@shared/store/sequences/sequences.selectors';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { combineLatest, first, Observable, of, switchMap } from 'rxjs';
import { ComparisonGalleryActionsService } from '../../actions/comparison-gallery-actions.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DrawerGalleryService {

  protected readonly injector = inject(Injector);
  protected readonly dialogService = inject(TuiDialogService);
  private readonly actionsService = inject(ComparisonGalleryActionsService);
  private readonly mediaService = inject(MediaHttpService);
  private readonly store = inject(Store);

  openGallery(medias: Media[]): void {
    this.getSequenceMedias(medias).pipe(
      untilDestroyed(this),
    ).subscribe({ 
      next: (sequenceMedias) => {
        this.openComparisonGallery(this.getGalleryInput(sequenceMedias)).subscribe();
      }, 
    });
  }
  
  private getSequenceMedias(medias: Media[]): Observable<{ media: Media; sequence: FlattenedSequence }[]> {
    return combineLatest(medias.map((media) => 
      combineLatest({
        // Fetch updated media details from the API, including secure URIs for full-size and thumbnail images
        media: this.mediaService.getMedia(
          media.clientCode, 
          media.id, 
          { fillFullSizeUri: true, fillThumbnailUri: true },
        ),
        // Retrieve the sequence from the store if available; otherwise, fetch it from the API.
        // This is only required when the sequence is not already present, as it's needed for patient widget
        sequence: this.store.select(selectSequence(media.sequenceId)).pipe(
          first(),
          switchMap((sequence) => sequence ? of(sequence) : this.fetchMediaSequence(media)),
          untilDestroyed(this),
        ),
      }),
    ));
  }
  
  private fetchMediaSequence(media: Media): Observable<FlattenedSequence> {
    this.store.dispatch(sequencesActions.getUserSequence({
      clientCode: media.clientCode,
      sequenceId: media.sequenceId,
    }));
    return this.store.select(selectSequence(media.sequenceId)).pipe(
      first((sequence) => !!sequence),
    );
  }

  private getGalleryInput(sequenceMedias): ComparisonGalleryInput {
    return {
      items$: of(sequenceMedias.map(({ media }): GalleryItem => ({ media, isLoaded: true }))),
      config: comparisonConfig,
      actions: this.actionsService.getActions(),
      widgets: sequenceMedias.map(({ sequence }) => ({
        component: SequencePreviewDrawerComponent,
        config: {
          context: {
            patient: sequence.patientInstance,
            date: sequence.sequenceInstance.createdOn,
          },
          dialogClass: 'dialog--gallery-info',
        },
      })),
    };
  }

  private openComparisonGallery({
    items$,
    config,
    actions,
    widgets,
  }: ComparisonGalleryInput): Observable<void> {
    return this.dialogService.open<void>(
      new PolymorpheusComponent(ComparisonGalleryComponent, this.injector),
      {
        data: {
          items$,
          config,
          actions,
          widgets,
        },
        size: 'page',
        closeable: false,
        dismissible: false,
      },
    );
  }

}
