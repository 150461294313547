import { Injectable, inject } from '@angular/core';
import {
  DocumentData,
  Firestore,
  Query,
  QueryDocumentSnapshot,
  Timestamp,
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  collectionChanges,
  collectionData,
  doc,
  endBefore,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Channel,
  ChannelMessage,
  ChannelMessageGroup,
  ChannelMessageSenderType,
  CreateChannelMessageRequest,
  ObjectType,
  getUsername, ChannelsSearchOptions, searchChannels,
} from '@pixacare/pxc-ts-core';
import { BehaviorSubject, Observable, Subscription, map } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ShareHttpService } from 'src/app/services/http/share.http.service';
import { Message } from 'src/app/shared/models/chat/message';
import { ChatHttpService } from './chat.http.service';
import { MessageGroupService } from './message-group.service';
import { ChannelNamePipe } from './pipes/channel-name.pipe';

@UntilDestroy()
@Injectable()
export class ChatService {

  channels$: Observable<Channel[]>;
  currentChannelMessages$: Observable<{ [messageId: string]: ChannelMessage }>;
  hasUnseenMessages$: Observable<boolean>;
  unreadMessagesCounter$: Observable<number>;

  private readonly fireStore: Firestore = inject(Firestore);
  private readonly authenticationService: AuthenticationService = inject(AuthenticationService);
  private readonly messageGroupService = inject(MessageGroupService);
  private readonly channelNamePipe = inject(ChannelNamePipe);
  private readonly chatHttpService = inject(ChatHttpService);
  private readonly shareService = inject(ShareHttpService);

  private currentChannelMessages: BehaviorSubject<{ [messageId: string]: ChannelMessage }> = new BehaviorSubject({});
  private hasUnseenMessages = new BehaviorSubject<boolean>(false);
  private channelQuery: Query;

  private channelMessageSubscription: Subscription;

  private pageSize = 50;
  private lastCursor: ChannelMessage = null;
  private firstCursor: ChannelMessage = null;

  constructor() {
    const channelCollection = collection(this.fireStore, 'channels');
    this.channelQuery = query(
      channelCollection,
      where('userIds', 'array-contains', this.authenticationService.currentUser.id),
      orderBy('lastMessage.createdOn', 'desc'),
    );
    this.channels$ = (collectionData(this.channelQuery, { idField: 'id' }) as Observable<Channel[]>).pipe(
      untilDestroyed(this),
    );
    this.currentChannelMessages$ = this.currentChannelMessages.asObservable();
    this.hasUnseenMessages$ = this.hasUnseenMessages.asObservable();
    this.unreadMessagesCounter$ = this.channels$.pipe(
      map((channels) => channels.reduce(
        (totalUnreads, channel) => totalUnreads + channel?.unreads[this.authenticationService.currentUser.id] || 0,
        0,
      )),
    );
  }

  markMessagesAsSeen(): void {
    this.hasUnseenMessages.next(false);
  }

  updateChannelUnreads(channelId: string): void {
    this.chatHttpService.updateChannelUserUnreads(channelId, this.authenticationService.currentUser.id).subscribe();
  }

  getMessageGroups(): Observable<ChannelMessageGroup[]> {
    return this.messageGroupService.messageGroups$;
  }

  async updateNotifications(channelId: string, enabledPriorities: number[]): Promise<void> {
    const channelDocRef = doc(this.fireStore, `channels/${channelId}`);
    try {

      const userId = this.authenticationService.currentUser.id;

      const value = (level: number) => enabledPriorities.includes(level) ? arrayUnion(userId) : arrayRemove(userId);

      await updateDoc(channelDocRef, {
        'settings.notifications.0': value(0),
        'settings.notifications.1': value(1),
        'settings.notifications.2': value(2),
        'settings.notifications.3': value(3),
      });

    } catch (e) {
      console.error('Error while updating channel notifications', e);
    }
  }

  getSenderName(message: ChannelMessage): string {
    switch (message.senderType) {
      case ChannelMessageSenderType.SYSTEM:
        return 'Pixacare';
      case ChannelMessageSenderType.ALERT:
        return 'Alerte';
      default:
        return message.senderName ?? '';
    }
  }

  parseMessages(messages: QueryDocumentSnapshot<DocumentData, DocumentData>[]): ChannelMessage[] {
    return messages.map((document) => {
      const data = document.data() as ChannelMessage;
      return {
        ...data,
        id: document.id,
        senderName: this.getSenderName(data),
      };
    });
  }

  listenToChannelMessages(channelId: string): void {
    const messagesDocRef = collection(
      this.channelQuery.firestore,
      `channels/${channelId}/messages`,
    );
    const messagesQuery = query(
      messagesDocRef,
      orderBy('createdOn', 'desc'),
      endBefore(Timestamp.now()),
    );

    this.channelMessageSubscription?.unsubscribe();
    this.channelMessageSubscription = collectionChanges(messagesQuery, { events: ['added'] }).pipe(
      untilDestroyed(this),
    ).subscribe((changes) => {

      const newMessages = this.parseMessages(changes.map((change) => change.doc));

      if (newMessages.length === 0) {
        return;
      }

      this.messageGroupService.addMessages(newMessages, 'prepend', this.firstCursor);
      this.firstCursor = newMessages[0] as unknown as ChannelMessage;

      this.hasUnseenMessages.next(true);
      this.currentChannelMessages.next({
        ...this.indexMessagesById(newMessages),
        ...this.currentChannelMessages.value,
      });
    });
  }

  async loadChannelMessages(
    channelId: string, { reset } = { reset: true },
  ): Promise<void> {
    if (reset) {
      this.listenToChannelMessages(channelId);
      this.updateChannelUnreads(channelId);
    }

    const messagesDocRef = collection(this.channelQuery.firestore, `channels/${channelId}/messages`);
    const messagesQuery = query(
      messagesDocRef,
      orderBy('createdOn', 'desc'),
      ...((reset || !this.lastCursor) ? [] : [startAfter(this.lastCursor.createdOn)]),
      limit(this.pageSize),
    );

    const messagesDocuments = await getDocs(messagesQuery);
    const messages = this.parseMessages(messagesDocuments.docs);

    const indexedMessages = this.indexMessagesById(messages);
    if (reset) {
      this.messageGroupService.reset();
      this.currentChannelMessages.next(indexedMessages);
      this.firstCursor = messages[0] as unknown as ChannelMessage;
    } else {
      this.currentChannelMessages.next(
        { ...this.currentChannelMessages.value, ...indexedMessages },
      );
    }

    if (messages.length) {
      this.messageGroupService.addMessages(messages);
      this.lastCursor = messages.slice(-1)[0] as unknown as ChannelMessage;
    }

  }

  async sendMessage(channelId: string, newMessage: Message): Promise<void> {
    if (newMessage.attachment?.sequence) {
      await this.sendSequence(channelId, newMessage);
    } else {
      await this.sendSimpleMessage(channelId, newMessage.text);
    }
  }

  searchChannels$(searchOptions: ChannelsSearchOptions): Observable<Channel[]> {
    return this.channels$.pipe(
      map((channels) => searchChannels(channels, this.authenticationService.currentUser.id, searchOptions)),
    );
  }

  private async sendSimpleMessage(channelId: string, text: string): Promise<void> {
    const messagesDocRef = collection(this.channelQuery.firestore, `channels/${channelId}/messages`);
    await addDoc(messagesDocRef, {
      channelId,
      senderId: this.authenticationService.currentUser.id,
      senderName: getUsername(this.authenticationService.currentUser),
      createdOn: Timestamp.now(),
      text,
      special: null,
    } as CreateChannelMessageRequest);
  }

  private async sendSequence(channelId: string, newMessage: Message): Promise<void> {
    this.shareService.shareObject({
      clientCode: newMessage.attachment.sequence.clientCode,
      toMailAddresses: [],
      toUserIds: [],
      toChannelIds: [channelId],
      objectId: newMessage.attachment.sequence.sequenceInstance.id,
      objectType: ObjectType.SEQUENCE,
      withAdminPrivileges: false,
      message: newMessage.text,
      expiryInDays: 7,
    }).subscribe();
  }

  private indexMessagesById(messages: ChannelMessage[]): { [messageId: string]: ChannelMessage } {
    return messages.reduce((acc, message) => {
      acc[message.id] = message;
      return acc;
    }, {});
  }

}
