import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, inject } from '@angular/core';
import { LoaderComponent } from '@modules/shared/loader/loader.component';
import { FlattenedSequence, ObjectType } from '@pixacare/pxc-ts-core';
import { TuiDialogContext } from '@taiga-ui/core';
import { TuiElasticContainer } from '@taiga-ui/kit';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { BehaviorSubject } from 'rxjs';
import { ShareHttpService } from 'src/app/services/http/share.http.service';
import { MessageWithReceivers } from 'src/app/shared/models/chat/message-with-receivers';
import { ShareComponent } from '../../chat/share/share.component';
import { SharedModule } from '../../shared/shared.module';
import { SequencePreviewComponent } from '../sequence-preview/sequence-preview.component';

@Component({
  templateUrl: './sequence-share.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ShareComponent,
    SequencePreviewComponent,
    SharedModule,
    TuiElasticContainer,
    LoaderComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceShareComponent {

  @Input() sequence: FlattenedSequence = this.context.data.sequence;

  isLoading$ = new BehaviorSubject<boolean>(false);

  private readonly shareService = inject(ShareHttpService);

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<MessageWithReceivers, {
      sequence: FlattenedSequence;
    }>,
  ) {}

  close(): void {
    this.context.completeWith(null);
  }

  confirm(newMessage: MessageWithReceivers): void {
    this.isLoading$.next(true);
    this.shareService.shareObject({
      clientCode: this.sequence.clientCode,
      toMailAddresses: newMessage.receivers.toMailAddresses.map((x) => +x),
      toUserIds: newMessage.receivers.toUserIds,
      toChannelIds: newMessage.receivers.toChannelIds,
      objectId: this.sequence.sequenceInstance.id,
      objectType: ObjectType.SEQUENCE,
      withAdminPrivileges: false,
      message: newMessage.text,
      expiryInDays: 7,
    }).subscribe(() => {
      this.isLoading$.next(false);
      this.context.completeWith({
        ...newMessage,
        attachment: {
          sequence: this.sequence,
        },
      });
    });

  }

}
