<pxc-filter-dropdown-button
  [isActive]="template.filters[0]?.val.length"
  buttonText="{{ template.name }} {{
    template.filters[0]?.val.length > 0 ? '(' + template.filters[0]?.val.length + ')' : ''
  }}"
>
  <div
    class="flex h-96 w-80 flex-col items-stretch gap-2 overflow-hidden p-3"
    data-testid="dropdown-wrapper"
  >
    <tui-input
      #input
      tuiTextfieldIconLeft="@tui.search"
      tuiTextfieldLabelOutside="true"
      tuiTextfieldCleaner="true"
      [ngModel]="search$ | async"
      (ngModelChange)="search$.next($event)"
      data-testid="search"
      tuiTextfieldSize="s"
      ngDefaultControl
    >
      <input tuiTextfieldLegacy />
      {{ template.placeholder || 'Rechercher ...' }}
    </tui-input>

    <tui-scrollbar
      class="flex-1"
      scroll-tracker
      [threshold]="{ top: 100, bottom: 100 }"
      (bottomThreshold)="loadNextEntities()"
      (topThreshold)="loadPreviousEntities()"
      data-testid="scroll-wrapper"
    >
      <tui-data-list
        *tuiLet="displayedItems$ | async as filtered"
        emptyContent="Aucun résultat"
        size="s"
        (keydown)="onKeyDown($event.key, input.nativeFocusableElement)"
      >
        <tui-opt-group
          tuiMultiSelectGroup
          [ngModel]="this.template.filters[0]?.val || []"
          (ngModelChange)="updateSelection($event)"
          data-testid="opt-group"
          ngDefaultControl
        >
          <tui-opt-group>
            @for (item of filtered; track item.value + item.display) {
              <button
                tuiOption
                size="m"
                [value]="item.value"
              >
                <div>
                  <p class="font-bold">{{ item.display }}</p>
                  <p class="text-secondary">{{ item.subtitle }}</p>
                  <p class="text-sm text-secondary">{{ item.description }}</p>
                </div>
              </button>
            }
          </tui-opt-group>
        </tui-opt-group>
      </tui-data-list>
    </tui-scrollbar>

    <button
      [disabled]="!template.filters[0]?.val.length"
      tuiButton
      (click)="clear()"
      size="s"
      data-testid="reset-button"
      appearance="destructive"
    >
      Réinitialiser
    </button>
  </div>
</pxc-filter-dropdown-button>
