@if (attachmentProps$ | async; as props) {
  <div class="flex flex-col gap-2">
    <pxc-attachment-filters></pxc-attachment-filters>

    <pxc-attachment-drag-and-drop (files)="uploadFiles(props, $event)"></pxc-attachment-drag-and-drop>

    @if (uploadingFiles$ | async; as uploadingFiles) {
      <pxc-uploading-file-list
        [uploadingFiles]="uploadingFiles"
        (retryUpload)="retryUpload(props, $event)"
      ></pxc-uploading-file-list>
    }

    @if (attachments$ | async; as attachments) {
      <pxc-attachment-list [attachments]="attachments"></pxc-attachment-list>
    } @else {
      <ng-container *ngTemplateOutlet="loading"></ng-container>
    }
  </div>
} @else {
  <ng-container *ngTemplateOutlet="loading"></ng-container>
}

<ng-template #loading>
  <div class="my-4">
    <pxc-loader data-testid="loading">Chargement des fichiers ...</pxc-loader>
  </div>
</ng-template>
