<article
  tuiCardLarge
  tuiSurface="outline"
  class="flex flex-1 flex-col !gap-2.5 divide-y divide-slate-100"
>
  <header class="flex flex-wrap items-center justify-between gap-2">
    <p
      class="text-balance text-sm text-secondary"
      data-testid="date-author"
    >
      <span class="font-medium">{{ telemonitoring.telemonitoring.createdOn | date: 'mediumDate' | uppercase }}</span>
      à
      {{ telemonitoring.telemonitoring.createdOn | date: 'shortTime' }}
      <span class="mx-1 text-border-normal">•</span>
      @if (telemonitoring.createdByInstance) {
        <pxc-user-preview
          [user]="telemonitoring.createdByInstance"
          [colored]="false"
        ></pxc-user-preview>
      }
    </p>

    <div
      class="flex flex-wrap gap-3 !text-sm"
      stopPropagation
    >
      <button
        type="button"
        tuiLink
        data-appearance="primary"
        data-testid="qr-code"
        (click)="openQrCodeInGallery()"
      >
        QR Code
      </button>
      @if (actions) {
        <pxc-action-buttons
          [entity]="telemonitoring.telemonitoring"
          [actions]="actions"
          data-testid="actions"
          optionButtonLabel="Options"
        ></pxc-action-buttons>
      }
    </div>
  </header>

  <div class="flex gap-3 pt-2.5 max-sm:flex-col">
    <div class="h-20 w-20 shrink-0">
      @if (!telemonitoring.previewMedia) {
        <div class="tui-skeleton h-full w-full"></div>
      } @else {
        <pxc-sequence-media
          [media]="telemonitoring.previewMedia"
          data-testid="thumbnail"
          alt="Aperçu du télésuivi"
        />
      }
    </div>

    <div class="grid flex-1 gap-3 lg:grid-cols-2">
      <div class="flex flex-col pt-1">
        <p class="line-clamp-2 text-lg font-medium leading-snug">
          {{ name }}
        </p>
        @if (!telemonitoring.telemonitoring.isActive || telemonitoring.patient.isArchived) {
          <pxc-archived-badge></pxc-archived-badge>
        }
        <p
          class="text-sm text-secondary"
          data-testid="sequences-count"
        >
          {{ telemonitoring.telemonitoring.sequenceCount | i18nPlural: sequencesCountPluralMapping }}
        </p>
        @if (telemonitoring.telemonitoring.lastActivity; as lastActivity) {
          <pxc-last-update-badge
            [lastUpdate]="lastActivity"
            class="mt-1"
          ></pxc-last-update-badge>
        }
      </div>

      @if (displayPatient) {
        <div class="flex gap-3">
          <pxc-patient-avatar
            [patient]="telemonitoring.patient"
            data-testid="avatar"
            class="shrink-0"
            size="small"
          ></pxc-patient-avatar>
          <pxc-patient-data
            [patient]="telemonitoring.patient"
            data-testid="patient"
            class="grow"
            size="small"
          ></pxc-patient-data>
        </div>
      }
    </div>
  </div>
</article>
