import { StoreAttachment } from '@shared/store/attachments/models/store-attachment';
import { UploadingFile } from '../../models/attachments/uploading-file';

export interface AttachmentsState {
  [clientCode: string]: {
    [patientId: number]: {
      uploadingFiles: UploadingFile[];
      attachments: StoreAttachment[];
    };
  };
}

export const initialAttachmentState: AttachmentsState = {
};
