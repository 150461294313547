import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, inject, Input } from '@angular/core';
import { FileExtensionPipe } from '@modules/attachments/pipes/file-extension.pipe';
import { AttachmentActionsService } from '@modules/attachments/services/attachment-actions.service';
import { Attachment } from '@shared/models/attachments/attachment';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { MenuActionId } from '@shared/models/menu-actions/menu-action-id.enum';
import { TuiButton, TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { FileIconComponent } from '../file-icon/file-icon.component';

@Component({
  selector: 'app-unsupported-attachment-preview',
  standalone: true,
  imports: [
    FileIconComponent,
    FileExtensionPipe,
    UpperCasePipe,
    TuiButton,
  ],
  templateUrl: './unsupported-attachment-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `:host {
      display: block;
      background-color: black;
    }`,
  ],
})
export class UnsupportedAttachmentPreviewComponent {

  @Input() attachment: Attachment = this.context.data?.attachment;

  private readonly attachmentActionsService = inject(AttachmentActionsService);

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) 
    protected readonly context: TuiDialogContext<void, { 
      attachment: Attachment;
    }>,
  ) { }
    
  get downloadAction(): MenuAction {
    return this.attachmentActionsService.getAction(MenuActionId.ATTACHMENT_DOWNLOAD);
  }

}
