import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AttachmentsEffects } from './attachments.effects';
import { attachmentsReducer } from './attachments.reducer';
import { ATTACHMENTS_FEATURE_KEY } from './attachments.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(ATTACHMENTS_FEATURE_KEY, attachmentsReducer),
    EffectsModule.forFeature([AttachmentsEffects]),
  ],
  declarations: [],
})
export class AttachmentsStoreModule { }
