<div
  id="canvas-tools"
  [ngClass]="{ hidden: isPictureLoading }"
  class="flex h-full items-center justify-center"
></div>
<div
  class="absolute left-2.5 top-1/2 flex -translate-y-1/2 flex-col items-center justify-center gap-1 rounded-xl bg-base p-2 shadow-xl"
>
  @for (tool of studioTools; track tool; let index = $index) {
    <button
      tuiIconButton
      [ngClass]="tool.classes"
      [appearance]="selectedToolName === tool.commandName ? 'opposite' : 'flat'"
      [iconStart]="tool.icon"
      [tuiHint]="tool | toolNamePipe"
      tuiHintDirection="right"
      tuiHintAppearance="dark"
      (click)="onToolClick(tool.commandName)"
    ></button>
  }
  <pxc-color-picker-button
    [colorPickerConfig]="colorPickerConfig"
    [(color)]="drawConfig.color"
    [(width)]="drawConfig.width"
    colorPickerPosition="bottom-right"
  ></pxc-color-picker-button>
</div>

<ng-template #colorPicker>
  <pxc-color-picker
    [(color)]="drawConfig.color"
    [(width)]="drawConfig.width"
    [colorPickerConfig]="colorPickerConfig"
  ></pxc-color-picker>
</ng-template>

<div class="absolute left-0 top-1 flex h-12 w-full items-center justify-center">
  @if (isShapeSelected | async) {
    <div
         class="shadow-pixablue-1/20 drop-shadow-xl gap-2 bg-white rounded-md flex items-center p-1 px-2 h-full ml-auto translate-x-1/2"
    >
      <button
        tuiIconButton
        appearance="flat"
        iconStart="@tui.trash-2"
        tuiHint="Supprimer"
        tuiHintDirection="bottom"
        (click)="canvas.destroySelectedShapes()"
      ></button>
      <pxc-color-picker-button
        [colorPickerConfig]="colorPickerConfig"
        [color]="canvas.selectedShapesDrawConfig.color"
        [width]="canvas.selectedShapesDrawConfig.width"
        (colorChange)="canvas.changeSelectedShapesColor($event)"
        (widthChange)="canvas.changeSelectedShapesWidth($event)"
        colorPickerPosition="bottom-right"
      ></pxc-color-picker-button>
    </div>
  }
  @if (selectedToolName === tool.PREPARE_CROP) {
    <div class="relative ml-auto flex h-full translate-x-1/2 items-center gap-2 rounded-xl bg-base p-2 shadow-xl">
      <tui-icon
        class="m-1"
        icon="@tui.crop"
      ></tui-icon>
      <span class="h-1/2 w-px bg-neutral-1-pressed"></span>
      <button
        tuiIconButton
        appearance="flat"
        iconStart="@tui.undo"
        tuiHint="Rétablir"
        tuiHintDirection="bottom"
        tuiHintAppearance="dark"
        (click)="resetCrop()"
      ></button>
      <button
        tuiIconButton
        appearance="flat"
        iconStart="@tui.circle-x"
        tuiHint="Annuler (Echap)"
        tuiHintDirection="bottom"
        tuiHintAppearance="dark"
        (click)="onToolClick(tool.DEFAULT_TOOL)"
      ></button>
      <button
        tuiIconButton
        appearance="flat"
        iconStart="@tui.circle-check"
        tuiHint="Valider (Entrer)"
        tuiHintDirection="bottom"
        tuiHintAppearance="dark"
        (click)="executeCrop()"
      ></button>
    </div>
  }

  <div class="m-2 ml-auto flex flex-row rounded-xl bg-base p-1 shadow-xl">
    <button
      tuiIconButton
      appearance="flat"
      iconStart="@tui.download"
      tuiHint="Télécharger l'image"
      tuiHintDirection="bottom"
      tuiHintAppearance="dark"
      (click)="downloadPicture()"
    ></button>
    <button
      tuiIconButton
      appearance="flat"
      iconStart="@tui.x"
      tuiHint="Quitter"
      tuiHintDirection="bottom"
      tuiHintAppearance="dark"
      (click)="closeStudio()"
    ></button>
  </div>
</div>

@if (isPictureLoading) {
  <pxc-loader class="my-auto">Chargement de l'image ...</pxc-loader>
}

<ng-template
  #closeConfirm
  let-observer
>
  <p>Si vous quittez le studio sans enregistrer, vous perdrez toutes les modifications actuelles.</p>
  <div class="mt-4 flex items-stretch gap-2 max-md:flex-col md:items-center">
    <button
      tuiButton
      appearance="destructive"
      (click)="observer.complete(); this.closeWithoutSaving()"
      class="md:mr-auto"
    >
      Ne pas enregistrer
    </button>
    <button
      tuiButton
      appearance="outline"
      (click)="observer.complete()"
    >
      Annuler
    </button>

    <button
      tuiButton
      [loading]="isSaveOngoing"
      appearance="primary"
      (click)="this.saveAndClose(observer)"
    >
      Enregistrer
    </button>
  </div>
</ng-template>
