import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiHint } from '@taiga-ui/core';
import { TuiSlider } from '@taiga-ui/kit';
import { studioColors } from '../config/studio-colors.config';
import { ColorPickerConfig, StudioColor } from '../config/studio-interfaces';

@Component({
  selector: 'pxc-color-picker',
  templateUrl: './color-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiSlider,
    FormsModule,
    ReactiveFormsModule,
    TuiHint,
  ],
})

export class ColorPickerComponent {

  @Input() color: StudioColor;
  @Input() width: number;
  @Input() colorPickerConfig: ColorPickerConfig;
  @Input() colorPickerPosition = 'top-right';

  @Output() colorChange = new EventEmitter<StudioColor>();
  @Output() widthChange = new EventEmitter<number>();

  defaultColors = studioColors;

  defaultColorCode = studioColors[0].code;

  selectColor(color: StudioColor): void {
    this.color = color;
    this.colorChange.emit(this.color);
  }

  selectWidth(selectedSize: number): void {
    this.width = selectedSize;
    this.widthChange.emit(this.width);
  }

}
