import { Pipe, PipeTransform } from '@angular/core';
import { StudioTool } from '../config/studio-interfaces';

@Pipe({
  name: 'toolNamePipe',
  standalone: true,
})
export class ToolNamePipe implements PipeTransform {

  transform(tool: StudioTool): string {
    return tool.shortcut ? `${tool.name} (${tool.shortcut.toUpperCase()})` : tool.name;
  }

}
