import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorPickerConfig, StudioColor } from '../config/studio-interfaces';
import { ColorPickerComponent } from '@modules/studio/color-picker/color-picker.component';
import { ColorPickerWidthPipe } from '@modules/studio/pipes/color-picker-width.pipe';
import { TuiDropdown, TuiDropdownOpen, TuiDropdownOptionsDirective } from '@taiga-ui/core';

@Component({
  selector: 'pxc-color-picker-button',
  templateUrl: './color-picker-button.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ColorPickerComponent,
    ColorPickerWidthPipe,
    TuiDropdownOpen,
    TuiDropdownOptionsDirective,
    TuiDropdown,
  ],
})
export class ColorPickerButtonComponent {

  @Input() colorPickerConfig: ColorPickerConfig;
  @Input() color: StudioColor;
  @Input() width: number;
  @Input() colorPickerPosition = 'top-right';

  @Output() colorChange = new EventEmitter<StudioColor>();
  @Output() widthChange = new EventEmitter<number>();

}
