import { Directive, ElementRef, inject, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';

@Directive({
  selector: '[sequenceSadmLink]',
  standalone: true,
})
export class SequenceSadmLinkDirective extends RouterLink { 
  
  private elementRef = inject(ElementRef);

  @Input() set sequenceSadmLink(sequence: FlattenedSequence) {

    if (!sequence || !sequence.sequenceInstance.sadmEntityId) {
      this.elementRef.nativeElement.style.display = 'none';
      return;
    }

    this.routerLink = ['/dashboard', 'sadms', sequence.sequenceInstance.sadmEntityId];
    this.queryParamsHandling = 'merge';
    this.queryParams = {
      tab: null,
      sequenceId: sequence.sequenceInstance.id,
    };
  }

}
