import { createActionGroup, props } from '@ngrx/store';
import { ATTACHMENTS_FEATURE_KEY } from './attachments.selectors';
import { DeleteAttachmentSuccessProps } from './models/props/delete-attachment-success.props';
import { DeleteAttachmentProps } from './models/props/delete-attachment.props';
import { GetPatientAttachmentsSuccessProps } from './models/props/get-patient-attachments-success.props';
import { GetPatientAttachmentsProps } from './models/props/get-patient-attachments.props';
import { RemoveUploadingFileProps } from './models/props/remove-uploading-file.props';
import { UpdateUploadingFileProps } from './models/props/update-uploading-file.props';
import { UploadAttachmentsSuccessProps } from './models/props/upload-attachments-success.props';
import { UploadAttachmentsProps } from './models/props/upload-attachments.props';

export const attachmentsActions = createActionGroup({
  source: ATTACHMENTS_FEATURE_KEY,
  events: {
    'Upload attachments': props<UploadAttachmentsProps>(),
    'Upload attachments success': props<UploadAttachmentsSuccessProps>(),

    'Update uploading file state': props<UpdateUploadingFileProps>(),
    'Remove uploading file': props<RemoveUploadingFileProps>(),

    'Get patient attachments': props<GetPatientAttachmentsProps>(),
    'Get patient attachments success': props<GetPatientAttachmentsSuccessProps>(),

    'Delete attachment': props<DeleteAttachmentProps>(),
    'Delete attachment success': props<DeleteAttachmentSuccessProps>(),
  }, 
});
