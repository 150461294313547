import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AttachmentPreviewService } from '@modules/attachments/services/attachment-preview.service';
import { Attachment } from '@shared/models/attachments/attachment';
import { FileIconComponent } from '../file-icon/file-icon.component';

@Component({
  selector: 'pxc-attachment-preview',
  standalone: true,
  imports: [
    FileIconComponent,
  ],
  providers: [
    AttachmentPreviewService,
  ],
  templateUrl: './attachment-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentPreviewComponent {

  @Input() attachment: Attachment;

  private readonly attachmentPreviewService = inject(AttachmentPreviewService);

  openPreview(): void {
    this.attachmentPreviewService.openPreview(this.attachment).subscribe();
  }

}
