import { Directive, ElementRef, EventEmitter, HostListener, inject, Injectable, Output } from '@angular/core';

@Directive({
  selector: '[truncatedText]',
  standalone: true,
})

@Injectable()
export class DetectTruncationDirective {

  @Output() truncated = new EventEmitter<boolean>();
  
  private readonly elementRef = inject(ElementRef);
  
  @HostListener('mouseenter')
  checkTruncation(): void {
    const element = this.elementRef.nativeElement;
    const isTruncated = element.scrollHeight > element.clientHeight || 
                        element.scrollWidth > element.clientWidth;
    this.truncated.emit(isTruncated);
  }

}
