import Konva from 'konva';
import { Layer } from 'konva/lib/Layer';
import { Shape } from 'konva/lib/Shape';
import { Node } from 'konva/lib/Node';
import { Transformer } from 'konva/lib/shapes/Transformer';
import { Stage } from 'konva/lib/Stage';
import { StudioShape } from '../config/studio-enums';
import { EventBus } from '../event-bus/event-bus';
import { EventTopic } from '../event-bus/event-bus.interfaces';
import { StudioService } from '../studio/studio.service';
import { LineTransformer } from './line-transformer';
import { DrawConfig, StudioColor } from '../config/studio-interfaces';
import { defaultDrawConfig } from '@modules/studio/config/default-draw-config';

export class StudioCanvas {

  stage: Stage;
  layer: Layer;
  backgroundLayer: Layer;
  transformLayer: Layer;
  transformer: Transformer;
  lineTransformer: LineTransformer;
  croppingTransformer: Transformer;
  eventBus: EventBus;
  minimalCroppingSize = 50;

  selectedShapesDrawConfig: DrawConfig = {
    ...defaultDrawConfig,
  };

  constructor(container: string, eventBus: EventBus, private studioService: StudioService) {
    this.stage = new Stage({
      container,
      height: window.innerHeight,
      width: window.innerWidth,
    });

    this.eventBus = eventBus;

    this.backgroundLayer = new Layer();
    this.layer = new Layer();
    this.transformLayer = new Layer();

    this.transformer = new Konva.Transformer({
      ignoreStroke: true,
      keepRatio: false,
      anchorCornerRadius: 50,
      anchorSize: 15,
      enabledAnchors: [
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right',
      ],
    });

    this.transformer.on('transformend', () => {
      this.eventBus.publish(EventTopic.MODIFY_ANNOTATIONS);
    });

    this.layer.on('dragend', () => {
      this.eventBus.publish(EventTopic.MODIFY_ANNOTATIONS);
    });

    this.croppingTransformer = new Konva.Transformer({
      ignoreStroke: true,
      keepRatio: false,
      rotateEnabled: false,
      anchorSize: 20,
      anchorCornerRadius: 0,
      anchorStroke: '#b2b2b2',
      anchorFill: 'white',
      anchorStrokeWidth: 3,
      borderEnabled: false,
      boundBoxFunc: (oldBox, newBox) => {
        const toleranceOffset = 0.5;

        const stageWidth = this.stage.width() + toleranceOffset;
        const stageHeight = this.stage.height() + toleranceOffset;

        let nbWidth = newBox.width;
        let nbHeight = newBox.height;
        let nbX = newBox.x;
        let nbY = newBox.y;

        if (nbWidth < this.minimalCroppingSize || nbHeight < this.minimalCroppingSize) {
          return oldBox;
        }

        if (this.isVertical()) {
          [nbWidth, nbHeight] = [nbHeight, nbWidth];
          if (this.stage.rotation() % 360 === 90) {
            nbX = Math.abs(nbX - stageWidth + toleranceOffset);
          } else if (this.stage.rotation() % 360 === 270) {
            nbY = Math.abs(nbY - stageHeight + toleranceOffset);
          }
        } else if (this.stage.rotation() % 360 === 180) {
          nbX = Math.abs(nbX - stageWidth + toleranceOffset);
          nbY = Math.abs(nbY - stageHeight + toleranceOffset);
        }

        if (nbX < -toleranceOffset || nbY < -toleranceOffset) {
          return oldBox;
        }

        if (nbWidth > stageWidth - nbX) {
          return oldBox;
        }

        if (nbHeight > stageHeight - nbY) {
          return oldBox;
        }

        return newBox;
      },
    });

    this.stage.add(this.backgroundLayer);
    this.stage.add(this.layer);
    this.stage.add(this.transformLayer);
    this.transformLayer.add(this.transformer);
    this.transformLayer.add(this.croppingTransformer);

    this.lineTransformer = new LineTransformer(this.layer, this.stage, this.studioService);
  }

  addShape(shape: Shape): void {
    shape.attrs.name = StudioShape.SHAPE_NAME;
    shape.attrs.strokeScaleEnabled = false;
    this.layer.add(shape);
    this.eventBus.publish(EventTopic.MODIFY_ANNOTATIONS);
  }

  addTempShape(shape: Shape): void {
    this.layer.add(shape);
  }

  addBackground(image: Konva.Image): void {
    this.backgroundLayer.destroyChildren();
    image.id('background-picture');
    this.backgroundLayer.add(image);
  }

  setSelectedShapes(shapes: Konva.Shape[]): void {
    this.deselectShapes();
    shapes.forEach((shape) => {
      shape.draggable(true);
    });
    this.select(shapes);
  }

  selectShape(shape: Konva.Shape): void {
    shape.draggable(true);
    if (this.lineTransformer.getTransformedLine()) {
      this.select(this.transformer.nodes().concat([shape, this.lineTransformer.getTransformedLine()]));
      this.lineTransformer.stopLineTransform();
    } else {
      this.select(this.transformer.nodes().concat([shape]));
    }
    this.transformer.moveToTop();
  }

  deselectShapes(): void {
    this.transformer.nodes([]);
    this.lineTransformer.stopLineTransform();
    this.layer.find(`.${StudioShape.SHAPE_NAME}`).forEach((shape: Konva.Shape) => {
      shape.draggable(false);
      shape.off();
    });
    this.eventBus.publish(EventTopic.SELECTED_SHAPES_CHANGED, { nodes: [] });
  }

  deselectShape(shape: Konva.Shape): void {
    const nodes = this.transformer.nodes().slice();
    nodes.splice(nodes.indexOf(shape), 1);
    shape.draggable(false);
    shape.off();
    this.lineTransformer.stopLineTransform();
    this.select(nodes);
    this.eventBus.publish(EventTopic.SELECTED_SHAPES_CHANGED, { nodes });

  }

  destroySelectedShapes(): void {
    this.lineTransformer.destroyTransformedLine();
    this.transformer.nodes().forEach((node) => {
      node.destroy();
    });
    this.deselectShapes();
    this.eventBus.publish(EventTopic.MODIFY_ANNOTATIONS);
  }

  changeSelectedShapesColor(color: StudioColor): void {
    this.selectedShapesDrawConfig.color = color;
    this.getSelectedShapes().forEach((node) => {
      node?.setAttrs({
        stroke: color.code,
      });
    });
    this.eventBus.publish(EventTopic.MODIFY_ANNOTATIONS);
  }

  changeSelectedShapesWidth(width: number): void {
    this.selectedShapesDrawConfig.width = width;
    this.getSelectedShapes().forEach((node) => {
      node?.setAttrs({
        strokeWidth: width,
      });
    });
    this.eventBus.publish(EventTopic.MODIFY_ANNOTATIONS);
  }

  destroyCropSelection(): void {
    this.layer.find('.crop').forEach((crop) => {
      this.croppingTransformer.nodes([]);
      crop.destroy();
    });
    this.croppingTransformer.nodes([]);
  }

  resize({ width = null, height = null }: { width?: number; height?: number }): void {
    if (width) {
      this.stage.width(width);
    }
    if (height) {
      this.stage.height(height);
    }
  }

  isVertical(): boolean {
    return [90, 270].includes(Math.abs(this.stage.rotation() % 360));
  }

  private getSelectedShapes(): Node[] {
    return this.transformer.nodes().concat(this.lineTransformer.getTransformedLine());
  }

  private select(nodes: Konva.Node[]): void {

    if (nodes.length === 1 && nodes[0].className === 'Line' && (nodes[0] as Konva.Line).points().length === 4) {
      const line = nodes[0] as Konva.Line;
      this.lineTransformer.setTransformedLine(line);
      this.transformer.nodes([]);
    } else {
      this.lineTransformer.stopLineTransform();
      this.transformer.nodes(nodes);
    }
    this.eventBus.publish(EventTopic.SELECTED_SHAPES_CHANGED, { nodes });
    if (nodes.length > 0) {
      this.updateDrawConfig(nodes[nodes.length - 1]);
    }

  }

  private updateDrawConfig(shape: Konva.Node): void {
    this.selectedShapesDrawConfig = {
      color: {
        code: shape.attrs.stroke,
        name: '',
      },
      width: shape.attrs.strokeWidth,
    };
  }

}
