/* eslint-disable @typescript-eslint/member-ordering */
import { inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Filter, FilterOperator } from '@pixacare/pxc-ts-core';
import { FilterType } from '@shared/models/filters/filter-type.enum';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  Observable,
  shareReplay,
  skipWhile,
  switchMap,
} from 'rxjs';
import { FilterTemplate } from 'src/app/shared/models/filters/filter-template';
import { FlattenedFilterTemplate } from 'src/app/shared/models/filters/flattened-filter-template';

export const FILTER_TEMPLATES = new InjectionToken<Observable<FilterTemplate[]>>('filterTemplates');

@Injectable()
export class FilterBarService {

  private readonly router = inject(Router);
  private readonly activeRoute = inject(ActivatedRoute);
  
  private readonly persistentFilters = new BehaviorSubject<Filter[]>([]);
  private readonly templates = new BehaviorSubject<FlattenedFilterTemplate[]>(null);
  private readonly search = new BehaviorSubject<string>(null);

  private readonly filterTemplates$ = inject(FILTER_TEMPLATES, { optional: true });
    
  readonly templates$ = this.templates.pipe(
    skipWhile((templates) => !templates),
    shareReplay(1),
  );
  
  readonly search$ = this.search.pipe(
    map((search) => search?.trim()),
    distinctUntilChanged(),
    debounceTime(300),
    shareReplay(1),
  );

  readonly filteredSearch$ = combineLatest([
    this.templates$,
    this.search$,
    this.persistentFilters.asObservable(),
  ]).pipe(
    map(([templates, search, persistentFilters]) => ({
      filters: [
        ...templates.map((template) => template.filters).flat(1),
        ...persistentFilters,
      ],
      search,
    })),
    shareReplay(1),
  );

  readonly clearable$ = combineLatest([
    this.search$.pipe(
      map((search) => !!search),
    ),
    this.templates$.pipe(
      map((templates) => 
        templates.some((template) => this.isTemplateClearable(template)),
      ),
    ),
  ]).pipe(
    map(([searchClearable, templatesClearable]) => searchClearable || templatesClearable),
    distinctUntilChanged(),
    shareReplay(1),
  );

  constructor() { 
    this.resetFilters();
    this.loadFromQueryParams();
  }

  setPersistentFilters(filters: Filter[]): void {
    this.persistentFilters.next(filters);
  }

  updateTemplate(targetTemplate: FlattenedFilterTemplate): void {

    this.updateQueryParams([targetTemplate]);

    this.templates.next([
      ...this.templates.getValue()
        .filter((template) => template.type !== targetTemplate.type || template.property !== targetTemplate.property),
      targetTemplate,
    ].sort((a, b) => a.name.localeCompare(b.name)));
  }

  updateSearch(value: string): void {
    this.search.next(value);
  }

  resetFilters(): void {

    if (!this.filterTemplates$) {
      this.templates.next([]);
      return;
    }

    this.filterTemplates$
      .pipe(first(Boolean))
      .subscribe((templates) => {

        const filters: FlattenedFilterTemplate[] = templates.map((template) => ({
          ...template,
          filters: [
            ...(template.defaultValue ? [
              {
                prop: template.property,
                val: template.defaultValue,
                op: FilterOperator.EQUAL,
              },
            ] : []),
          ],
        }));

        this.templates.next(filters);
        this.updateQueryParams(filters);

      });
  }

  getSelectedIndex$(template: FilterTemplate): Observable<number> {
    return this.filteredSearch$.pipe(
      switchMap(({ filters }) => {
        const activeFilter = filters.find((filter) => filter.prop === template.property);
        return template.getValue('').pipe(
          map((items) => {
            if (!activeFilter) {
              // if no active filter for this prop, we can still match an "all" filter
              return items.findIndex((item) => !item.value);
            }
            return items.findIndex((item) => item.value === activeFilter.val);
          }),
        );
      }));
  }

  clear(): void {
    this.updateSearch(null);
    this.resetFilters();
  }

  private updateQueryParams(filtersTemplates: FlattenedFilterTemplate[]): void {

    const queryParams = filtersTemplates.reduce((acc, template) => ({
      ...acc, 
      [template.property]: undefined,
      ...(template.filters.reduce((acc2, filter) => ({
        ...acc2,
        [template.property]: filter.val,
      }), {})),
    }), {});

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  private loadFromQueryParams(): void {

    combineLatest([
      this.templates$.pipe(first(Boolean)),
      this.activeRoute.queryParams.pipe(first(Boolean)),
    ]).subscribe(([templates, queryParams]) => {

      if (!Object.keys(queryParams).length) {
        return;
      }

      const filtersTemplates: { [prop: string]: FlattenedFilterTemplate } = templates.reduce((acc, template) => ({
        ...acc,
        [template.property]: {
          ...template,
          filters: [],
        },
      }), {});

      let matchingTemplates = 0;

      Object.entries(queryParams).forEach(([key, value]) => {
        const template = templates.find((t) => t.property === key);
        if (template) {
          matchingTemplates++;
          filtersTemplates[key].filters = [{
            prop: key,
            val: value,
            op: FilterOperator.EQUAL,
          }];
        }
      });

      if (!matchingTemplates) {
        return;
      }
      this.templates.next(Object.values(filtersTemplates));

    });

  }

  private isTemplateClearable(template: FlattenedFilterTemplate): boolean {
    return template.type !== FilterType.TABS 
      && template.type !== FilterType.BUTTONS
      && template.filters.length > 0;
  }

}
