@if (tabs$ | async; as tabs) {
  <tui-tabs [activeItemIndex]="selectedIndex$ | async">
    @for (tab of tabs; track trackByTab($index, tab); let index = $index) {
      <button
        tuiTab
        (click)="onTabChange(tab, index)"
      >
        {{ tab.display }}
      </button>
    }
  </tui-tabs>
}

