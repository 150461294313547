import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { PatientDepartmentPickerComponent } from '@modules/dashboard/patient-departments-picker/patient-department-picker.component';
import { Patient } from '@pixacare/pxc-ts-core';
import { DialogService } from '@services/dialog.service';
import { GuardInitializerService } from '@services/guard-initializer.service';
import { PatientHttpService } from '@services/http/patient.http.service';
import { catchError, map, Observable, of, switchMap } from 'rxjs';

const getPatient = (
  patientService: PatientHttpService, clientCode: string, businessIdentifier: string,
): Observable<Patient> => patientService.getPatientsByBusinessIdentifier(clientCode, businessIdentifier).pipe(
  map((patients: Patient[]) => patients.find(
    (patient: Patient) => patient.businessIdentifier === businessIdentifier),
  ),
);

const openDialog = (
  dialogService: DialogService, clientCode: string, patient: Patient,
): Observable<number> => dialogService.openComponent<PatientDepartmentPickerComponent, number>(
  PatientDepartmentPickerComponent,
  {
    size: 'm',
    label: 'Accès au dossier patient',
    data: {
      patient,
      clientCode,
    },
    closeable: false,
    dismissible: false,
  },
);

const getRoutes = (
  patientId: number, departmentId: number,
): { routes: string[]; state: string } => {
  if (!departmentId || !patientId) {
    return {
      routes: ['dashboard', 'patients'],
      state: '/dashboard/patients',
    };
  }

  if (departmentId === -1) {
    return {
      routes: ['dashboard', 'patients', `${patientId}`],
      state: '/dashboard/patients',
    };
  }

  return {
    routes: ['dashboard', 'department', `${departmentId}`, 'patients', `${patientId}`],
    state: `/dashboard/department/${departmentId}/patients`,
  };
};

const navigate = (
  router: Router, location: Location, clientCode: string, { routes, state }: { routes: string[]; state: string },
): void => {
  location.replaceState(state, `cc=${clientCode}`);
  router.navigate(routes, {
    queryParams: { cc: clientCode, ipp: null },
    queryParamsHandling: 'replace',
  });
};

// TODO : Replace boolean by UrlTree would be a nice improvement
export const redirectToPatientGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  
  const guardInitializerService = inject(GuardInitializerService);
  const patientService = inject(PatientHttpService);
  const dialogService = inject(DialogService);
  const router = inject(Router);
  const location = inject(Location);

  const { cc: clientCode, ipp: businessIdentifier } = route.queryParams;
  if (!clientCode || !businessIdentifier) {
    return of(true);
  }

  const redirectToPatientAction$ = getPatient(patientService, clientCode, businessIdentifier).pipe(
    switchMap((patient: Patient) => openDialog(dialogService, clientCode, patient).pipe(
      map((departmentId: number | null) => {
        navigate(router, location, clientCode, getRoutes(patient?.id, departmentId));
        return true;
      }),
    ),
    ),
    catchError((error) => {
      console.error(error);
      router.navigate(['/']);
      return of(false);
    }),
  );

  return guardInitializerService.executeOrWaitForStoreInitialization$(redirectToPatientAction$);
};
