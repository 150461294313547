/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FilterButtonsComponent } from '@modules/filters/filter-buttons/filter-buttons.component';
import { ChannelType } from '@pixacare/pxc-ts-core';
import { chatFilterTemplates } from '@shared/models/filters/filter-template.config';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiButton, TuiScrollbar, TuiTitle } from '@taiga-ui/core';
import { switchMap } from 'rxjs';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { FILTER_TEMPLATES, FilterBarService } from '../../filters/filter-bar.service';
import { FilterBarComponent } from '../../filters/filter-bar/filter-bar.component';
import { StatusComponent } from '../../shared/status/status.component';
import { ChannelCreateService } from '../channel-create/channel-create.service';
import { ChannelPreviewComponent } from '../channel-preview/channel-preview.component';
import { ChatService } from '../chat.service';

@Component({
  selector: 'pxc-channel-list',
  standalone: true,
  imports: [
    CommonModule,
    ChannelPreviewComponent,
    TuiLet,
    RouterModule,
    TuiScrollbar,
    TuiButton,
    TuiTitle,
    StatusComponent,
    FilterBarComponent,
    FilterButtonsComponent,
  ],
  templateUrl: './channel-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ChannelCreateService,
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      deps: [ChatService],
      useFactory: chatFilterTemplates,
    },
  ],
})
export class ChannelListComponent {

  readonly chatService = inject(ChatService);
  readonly channelCreateService = inject(ChannelCreateService);
  private readonly filterBarService = inject(FilterBarService);

  filteredSearch$ = this.filterBarService.filteredSearch$;
  channels$ = this.filteredSearch$.pipe(
    switchMap((filteredSearch) => this.chatService.searchChannels$(
      {
        search: filteredSearch.search,
        type: filteredSearch.filters.find((filter) => filter.prop === 'type')?.val as ChannelType,
      },
    )),
  );

  Status = Status;

  openCreateChannelDialog(): void {
    this.channelCreateService.openCreateChannelDialog();
  }

}
