import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy, Component,
  inject,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { GalleryThumbnailListComponent } from '@modules/gallery/components/thumbnails/gallery-thumbnail-list/gallery-thumbnail-list.component';
import { VideoPlayerComponent } from '@modules/gallery/components/video-player/video-player.component';
import { SliderGalleryInput } from '@modules/gallery/models/slider-gallery-input';
import { GalleryService } from '@modules/gallery/services/gallery.service';
import { LoaderComponent } from '@modules/shared/loader/loader.component';
import { SharedModule } from '@modules/shared/shared.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IconActionButtonsComponent } from '@shared/components/action-buttons/icon-action-buttons/icon-action-buttons.component';
import { GalleryItem } from '@shared/models/gallery-item';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { Widget } from '@shared/models/widget';
import { TuiButton } from '@taiga-ui/core';
import { first } from 'rxjs';
import { GalleryComponent } from '../gallery.component';

@UntilDestroy()
@Component({
  selector: 'pxc-slider-gallery',
  templateUrl: './slider-gallery.component.html',
  styleUrls: ['./slider-gallery.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    TuiButton,
    GalleryThumbnailListComponent,
    VideoPlayerComponent,
    LoaderComponent,
    IconActionButtonsComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderGalleryComponent extends GalleryComponent<SliderGalleryInput> implements AfterViewInit, OnInit {

  @ViewChild('insertionAnchor', { read: ViewContainerRef }) widgetHost!: ViewContainerRef;

  @Input() actions: MenuAction<GalleryItem>[] = this.context.data?.actions;
  @Input() widget: Widget = this.context.data?.widget;
  @Input() selectedIndex: number = this.context.data?.selectedIndex ?? 0;

  public currentIndex: number;
  
  protected readonly galleryService = inject(GalleryService);

  ngOnInit() {
    this.currentIndex = this.selectedIndex;
    this.items$.pipe(untilDestroyed(this)).subscribe({ 
      next: (items) => {
        this.config.navigation &&= items.length > 1;
      }, 
    });
    this.galleryService.config$.pipe(untilDestroyed(this)).subscribe({ 
      next: (config) => {
        this.config = { ...this.config, ...config };
      }, 
    });
    this.galleryService.navigateToLastItem$.pipe(untilDestroyed(this)).subscribe({ 
      next: () => {
        this.navigateToLast();
      }, 
    });
    this.galleryService.navigateToItem$.pipe(untilDestroyed(this)).subscribe({ 
      next: (index) => {
        this.navigateToIndex(index);
      }, 
    });
  }
  
  public isInLazyLoadRange(index: number, length: number): boolean {
    return [
      this.getPreviousIndex(this.currentIndex, length),
      this.currentIndex,
      this.getNextIndex(this.currentIndex, length),
    ].includes(index);
  }

  public navigateToNext(): void {
    this.items$.pipe(first()).subscribe({ 
      next: (items) => {
        this.currentIndex = this.getNextIndex(this.currentIndex, items.length);
      }, 
    });
  }

  public navigateToPrevious(): void {
    this.items$.pipe(first()).subscribe({ 
      next: (items) => {
        this.currentIndex = this.getPreviousIndex(this.currentIndex, items.length);
      }, 
    });
  }

  public navigateToIndex(index: number): void {
    this.items$.pipe(first()).subscribe({ 
      next: (items) => {
        this.currentIndex = Math.max(Math.min(index, items.length - 1), 0);
      }, 
    });
  }
  
  protected loadWidgets(): void {
    if (this.widget) {
      this.widgetService.loadWidget(this.widgetHost, this.widget);
    }
  }

  protected handleKeyPress(key: string): void {
    switch (key) {
      case 'escape': {
        this.close();
        break;
      }
      case 'arrowleft': {
        this.navigateToPrevious();
        break;
      }
      case 'arrowright': {
        this.navigateToNext();
        break;
      }
      default: {
        break;
      }
    }
  }

  private navigateToLast(): void {
    this.items$.pipe(first()).subscribe({ 
      next: (items) => {
        this.currentIndex = items.length - 1;
      }, 
    });
  }

  private getNextIndex(index: number, length: number): number {
    return index === length - 1
      ? 0
      : index + 1;
  }

  private getPreviousIndex(index: number, length: number): number {
    return index === 0
      ? length - 1
      : index - 1;
  }

}
