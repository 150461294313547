import { inject, Injectable } from '@angular/core';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { MenuActionId } from '@shared/models/menu-actions/menu-action-id.enum';
import { SliderGalleryActionsService } from '../../../actions/slider-gallery-actions.service';
import { SequenceGalleryService } from './sequence-gallery.service';

@Injectable({
  providedIn: 'root',
})
export class SharedSequenceGalleryService extends SequenceGalleryService {

  private readonly galleryActionsService = inject(SliderGalleryActionsService);
  
  protected getActions(): MenuAction[] {
    return [this.galleryActionsService.getAction(MenuActionId.GALLERY_SLIDER_DONWLOAD)];
  }

}
