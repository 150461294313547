import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FILTER_TEMPLATES, FilterBarService } from '@modules/filters/filter-bar.service';
import { SadmEntityCreationService } from '@modules/sadm/sadm-entity-creation.service';
import { SadmFormService } from '@modules/sadm/sadm-form.service';
import { Store } from '@ngrx/store';
import { Patient, SadmClient, SadmTypeLabel } from '@pixacare/pxc-ts-core';
import { patientAnalysesFilterTemplates } from '@shared/models/filters/filter-template.config';
import { sadmActions } from '@shared/store/sadm/sadm.actions';
import { selectIsPatientSadmStateLoaded } from '@shared/store/sadm/sadm.selectors';
import { first, map, Observable, switchMap } from 'rxjs';
import { PatientService } from '../patient/patient.service';

@Component({
  templateUrl: './patient-analyses.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SadmFormService,
    SadmEntityCreationService,
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: patientAnalysesFilterTemplates,
    },
  ],
})
export class PatientAnalysesComponent {

  SadmTypeLabel = SadmTypeLabel;
  sadms$: Observable<SadmClient[]> = this.patientPageService.getSadms();
  patient$: Observable<Patient> = this.patientPageService.getPatient();

  isDataLoaded$ = this.patientPageService.getParams().pipe(
    switchMap(({ clientCode, patientId }) =>
      this.store.select(selectIsPatientSadmStateLoaded(clientCode, patientId)),
    ));

  constructor(
    private readonly patientPageService: PatientService,
    private readonly sadmEntityCreationService: SadmEntityCreationService,
    private readonly store: Store,
  ) { }

  createEntity(sadmClient: SadmClient): void {
    this.patient$.pipe(
      switchMap((patient) =>
        this.sadmEntityCreationService.openEntityCreation(sadmClient)
          .pipe(
            map((sadmEntity) => ({
              sadmEntity,
              patient,
            })),
          )),
      first(),
    ).subscribe(({ sadmEntity: baseSadmEntity, patient }) => {

      if (!baseSadmEntity) {
        return;
      }

      this.store.dispatch(sadmActions.createSadmEntity({
        sadmEntityPayload: {
          ...baseSadmEntity,
          patientId: patient.id,
          clientCode: patient.clientCode,
        },
      }));

    });
  }

}
