import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ClientUser, FlattenedSequence, Label } from '@pixacare/pxc-ts-core';
import { PagedCollection } from '../../models/pagination/paged-collection';
import { selectLabels } from '../labels/labels.selectors';
import { selectPatientState } from '../patients/patients.selectors';
import { PatientsState } from '../patients/patients.state';
import { selectTelemonitoringSequenceIds } from '../telemonitorings/telemonitorings.selectors';
import { selectUsers } from '../users/users.selectors';
import { StoreSequence } from './models/store-sequence';
import { SequencesState } from './sequences.state';

export const SEQUENCES_FEATURE_KEY = 'Sequences';

export const selectSequencesState = createFeatureSelector<SequencesState>(
  SEQUENCES_FEATURE_KEY,
);

export const selectSynchronizeSequenceOnGoing = createSelector(
  selectSequencesState,
  (state) => state.synchronizeSequenceOnGoing,
);

const composeFlattenedSequence = (
  sequence: StoreSequence,
  patients: PatientsState,
  labels: {
    [labelId: number]: Label;
  },
  users: {
    [clientCode: string]: {
      [userId: number]: ClientUser;
    };
  },
): FlattenedSequence => ({
  sequenceInstance: sequence,
  clientCode: sequence.clientCode,
  medias: sequence.medias?.map((media) => ({ ...media, clientCode: sequence.clientCode })),
  createdByInstance: users[sequence.clientCode][sequence.createdBy],
  patientInstance: patients.patients?.[sequence.clientCode]?.data?.[sequence.patientId],
  labels: sequence.labelIds.map((labelId) => labels[labelId]),
});

const composeFlattenedSequences = (
  sequences: StoreSequence[],
  patients: PatientsState,
  labels: {
    [labelId: number]: Label;
  },
  users: {
    [clientCode: string]: {
      [userId: number]: ClientUser;
    };
  },
): FlattenedSequence[] => sequences?.filter((sequence) => !!sequence).map((sequence) =>
  composeFlattenedSequence(sequence, patients, labels, users));

export const selectSequence = (
  sequenceId: number,
): MemoizedSelector<SequencesState, FlattenedSequence> => createSelector(
  selectSequencesState,
  selectPatientState,
  selectLabels,
  selectUsers,
  (state, patients, labels, users) => {
    const sequence = state.sequences.data[sequenceId];
    if (sequence) {
      return composeFlattenedSequence(sequence, patients, labels, users);
    } else {
      return null;
    }
  },
);

export const selectSequences = createSelector(
  selectSequencesState,
  selectPatientState,
  selectLabels,
  selectUsers,
  (state, patients, labels, users) => ({
    ...state.sequences,
    data: composeFlattenedSequences(
      state.sequences.sorted.map((sequenceId) => state.sequences.data?.[sequenceId]) || [],
      patients,
      labels,
      users),
  }),
);

export const selectSadmSequences = (
  sadmEntityId: number,
): MemoizedSelector<SequencesState, PagedCollection<FlattenedSequence[]>> => createSelector(
  selectSequencesState,
  selectPatientState,
  selectLabels,
  selectUsers,
  (state, patients, labels, users) => ({
    ...state.sequences,
    data: composeFlattenedSequences(
      (Object.values(state.sequences.data || {}) || [])
        .filter((sequence) => sequence.sadmEntityId === sadmEntityId)
        .sort((seq1, seq2) => seq1.createdOn.getTime() - seq2.createdOn.getTime()) || [],
      patients,
      labels,
      users),
  }),
);

export const selectTelemonitoringSequences = (
  telemonitoringId: number,
): MemoizedSelector<SequencesState, PagedCollection<FlattenedSequence[]>> => createSelector(
  selectSequencesState,
  selectTelemonitoringSequenceIds(telemonitoringId),
  selectPatientState,
  selectLabels,
  selectUsers,
  (state, sequenceIds, patients, labels, users) => {
    if (!sequenceIds) {
      return null;
    }

    const sequences = sequenceIds.map((sequenceId) => state.sequences.data?.[sequenceId]) || [];

    return {
      ...state.sequences,
      data: composeFlattenedSequences(
        sequences.sort((seq1, seq2) => seq2.createdOn.getTime() - seq1.createdOn.getTime()),
        patients,
        labels,
        users),
    };
  },
);

export const selectIsSequenceStateLoaded = createSelector(
  selectSequencesState,
  (state) => state.isLoaded || false,
);
