import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FileExtensionPipe } from '@modules/attachments/pipes/file-extension.pipe';
import { AttachmentActionsService } from '@modules/attachments/services/attachment-actions.service';
import { SharedModule } from '@modules/shared/shared.module';
import { UserPreviewComponent } from '@modules/user/user-preview/user-preview.component';
import { IconActionButtonsComponent } from '@shared/components/action-buttons/icon-action-buttons/icon-action-buttons.component';
import { Attachment } from '@shared/models/attachments/attachment';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { TuiTable } from '@taiga-ui/addon-table';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiScrollbar } from '@taiga-ui/core';
import { AttachmentPreviewComponent } from '../attachment-preview/attachment-preview.component';
import { ATTACHMENT_TABLE_CONFIG } from './configurations/attachment-table.config';
import { TableHeader } from './models/table-header';
import { VirtualScrollBuffer } from './models/virtual-scroll-buffer';

@Component({
  selector: 'pxc-attachment-list',
  standalone: true,
  imports: [
    CommonModule,
    TuiLet,
    TuiTable,
    TuiScrollbar,
    ScrollingModule,
    FileExtensionPipe,
    UpperCasePipe,
    SharedModule,
    UserPreviewComponent,
    IconActionButtonsComponent,
    AttachmentPreviewComponent,
  ],
  templateUrl: './attachment-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentListComponent {

  @Input() attachments: Attachment[];

  private readonly attachmentActionsService = inject(AttachmentActionsService);
  
  get virtualScroll(): VirtualScrollBuffer  {
    return ATTACHMENT_TABLE_CONFIG.virtualScroll;
  }

  get tableHeaders(): TableHeader<Attachment>[] {
    return ATTACHMENT_TABLE_CONFIG.headers;
  }

  get columns(): string[] {
    return this.tableHeaders.map((th: TableHeader<Attachment>) => th.column);
  }

  get actions(): MenuAction<Attachment>[] {
    return this.attachmentActionsService.getActions();
  }

  public trackByAttachment(index: number, item: Attachment): string {
    return `${index}-${item.id}`;
  }

}
