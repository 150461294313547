@let isDepartmentsStateLoaded = isDepartmentsStateLoaded$ | async;
@let departments = departments$ | async;
@if (!patient) {
  <tui-block-status>
    <pxc-lottie-label
      animation="patientEmpty"
      message="Cet identifiant patient n'existe pas"
    ></pxc-lottie-label>
    <button
      type="button"
      tuiButton
      (click)="cancel()"
      class="mt-8"
    >
      Revenir à la liste des patients
    </button>
  </tui-block-status>
} @else {
  <div
    class="mb-6 flex items-center gap-5"
    data-testid="patient-data"
  >
    <pxc-patient-avatar
      [patient]="patient"
      [size]="Size.LARGE"
    ></pxc-patient-avatar>
    <pxc-patient-data
      [patient]="patient"
      [size]="Size.LARGE"
      data-testid="patient-details"
    ></pxc-patient-data>
  </div>

  @if (!isDepartmentsStateLoaded) {
    <button
      class="mt-4 flex h-12 w-full rounded-lg shadow-sm"
      [tuiSkeleton]="!isDepartmentsStateLoaded"
    ></button>
  } @else {
    @if (departments.length > 0) {
      <p>Vous faites partie de plusieurs groupes comportant ce patient.</p>
      <p>Dans quel contexte souhaitez-vous consulter ce dossier patient ?</p>
      <div class="mt-8 flex flex-col justify-center gap-4">
        @for (department of departments; track $index) {
          @let departmentAvatarStyle = getDepartmentAvatarStyle(department);
          <button
            class="flex w-full flex-row items-center justify-between rounded-lg bg-white p-4 shadow-sm"
            (click)="pickDepartment(department.id)"
          >
            <div
              class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm uppercase"
              [ngStyle]="{
                backgroundColor: departmentAvatarStyle.backgroundColor,
                color: departmentAvatarStyle.textColor,
              }"
            >
              {{ department?.name.charAt(0) || '?' }}
            </div>
            <div class="ml-3 min-w-0 flex-1 overflow-hidden">
              <h2 class="truncate text-left">{{ department.name }}</h2>
              <p class="truncate text-left text-sm text-slate-400">
                Consulter ce dossier patient dans le groupe {{ department.name }}
              </p>
            </div>
            <div class="ml-3 flex h-6 w-6 shrink-0 items-center justify-center">
              <tui-icon
                icon="@tui.chevron-right"
                class="text-slate-400"
              ></tui-icon>
            </div>
          </button>
        }
      </div>
    }
    <button
      class="mt-4 flex w-full flex-row items-center justify-between rounded-lg bg-white p-4 shadow-sm"
      (click)="pickDepartment(-1)"
    >
      <tui-icon
        icon="@tui.user"
        class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm uppercase text-primary"
      ></tui-icon>
      <div class="ml-3 min-w-0 flex-1 text-start">
        <h2>Mes patients</h2>
        <p class="line-clamp-2 text-sm text-slate-400">
          Consulter toutes les données auxquelles vous avez accès pour ce patient
        </p>
      </div>
      <div class="ml-3 flex h-6 w-6 shrink-0 items-center justify-center">
        <tui-icon
          icon="@tui.chevron-right"
          class="text-slate-400"
        ></tui-icon>
      </div>
    </button>
  }

  <button
    type="button"
    tuiButton
    appearance="outline"
    (click)="cancel()"
    class="mt-8"
  >
    Annuler
  </button>
}
