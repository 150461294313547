import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiHint } from '@taiga-ui/core';
import { SharedModule } from '../shared.module';

@Component({
  selector: 'pxc-cell-overflow',
  standalone: true,
  imports: [SharedModule, CommonModule, TuiHint],
  templateUrl: './cell-overflow.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CellOverflowComponent {

  @Input() cellContent: string;

}
