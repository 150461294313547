import { channelLabelByType } from '@modules/chat/channel-list/channel-list.config';
import { ChatService } from '@modules/chat/chat.service';
import { getUniqueChannelTypesFromChannels } from '@modules/chat/chat.utils';
import { Store } from '@ngrx/store';
import { Channel } from '@pixacare/pxc-ts-core';
import { selectIsGamEnabled } from '@shared/store/clients/clients.selectors';
import { selectClientCode } from '@shared/store/router/router.selectors';
import { map, Observable, of, switchMap } from 'rxjs';
import { FilterTemplate } from './filter-template';
import { FilterType } from './filter-type.enum';

export const sequenceFilterTemplates: Observable<FilterTemplate[]> = of([
  {
    type: FilterType.DATE_RANGE,
    property: 'created_on',
    name: 'Date',
  },
]);

export const patientFilterTemplates: (store: Store) => Observable<FilterTemplate[]> = (store: Store) =>
  store.select(selectClientCode).pipe(
    switchMap((clientCode) => store.select(selectIsGamEnabled(clientCode))),
    map((isGamEnabled) => [
      {
        type: FilterType.BUTTONS,
        property: 'is_archived',
        name: 'Archivable',
        defaultValue: '0',
        getValue: () => of([
          { value: '0', display: 'Actifs' },
          { value: '1', display: 'Archivés' },
          { value: undefined, display: 'Tous' },
        ]),
      },
      ...(isGamEnabled ? [
        {
          type: FilterType.TABS,
          property: 'is_gam_linked',
          name: 'GAM Status',
          getValue: () => of([
            { value: null, display: 'Tous' },
            { value: '0', display: 'Non rattachés à l\'établissement' },
          ]),
        },
      ] : []),
    ]),
  );

export const telemonitoringFilterTemplates: Observable<FilterTemplate[]> = of([
  {
    type: FilterType.TABS,
    property: 'is_active',
    name: 'Telemonitoring Status',
    defaultValue: '1',
    getValue: () => of([
      {
        value: '1',
        display: 'Actifs',
      },
      {
        value: '0',
        display: 'Archivés',
      },
    ]),
  },
]);

export const chatFilterTemplates: (chatService: ChatService) =>  Observable<FilterTemplate[]> = 
  (chatService: ChatService) =>
    chatService.channels$.pipe(
      map((channels: Channel[]) => {
        if (!channels.length) {
          return [];
        }
        const uniqueChannelTypes = getUniqueChannelTypesFromChannels(channels);
        if (uniqueChannelTypes.length === 1) {
          return [];
        }
        return [
          {
            type: FilterType.BUTTONS,
            property: 'type',
            name: 'Type',
            defaultValue: uniqueChannelTypes[0],
            getValue: () => of(
              uniqueChannelTypes.map((channelType) => ({
                value: channelType,
                display: channelLabelByType[channelType],
              })),
            ),
          },
        ];
      }),
    );

export const patientAnalysesFilterTemplates = of(
  [{
    type: FilterType.BUTTONS,
    property: 'is_archived',
    name: 'Archivable',
    defaultValue: 'false',
    getValue: () => of([
      { value: 'false', display: 'Actives' },
      { value: 'true', display: 'Archivées' },
      { value: undefined, display: 'Toutes' },
    ]),
  }],
);
