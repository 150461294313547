import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AttachmentResponse, AttachmentUriResponse, DeleteAttachmentRequest, GetAttachmentRequest, GetPatientAttachmentsRequest, UploadAttachmentsRequest } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AttachmentHttpService {

  private controllerName = 'patientAttachment';

  private readonly http = inject(HttpClient);

  public uploadAttachments(
    { clientCode, patientId, files }: UploadAttachmentsRequest,
  ): Observable<AttachmentResponse[]> {
    const data = this.getFormData(files);
    return this.http.post<AttachmentResponse[]>(
      `${environment.apiBaseUrl}/${this.controllerName}/${clientCode}/${patientId}`, 
      data,
    );
  }

  public getPatientAttachment(
    { clientCode, patientId }: GetPatientAttachmentsRequest,
  ): Observable<AttachmentResponse[]> {
    return this.http.get<AttachmentResponse[]>(
      `${environment.apiBaseUrl}/${this.controllerName}/${clientCode}/${patientId}`, 
    );
  }

  public getAttachmentUri(
    { clientCode, patientId, attachmentId }: GetAttachmentRequest,
  ): Observable<AttachmentUriResponse> {
    return this.http.get<AttachmentUriResponse>(
      `${environment.apiBaseUrl}/${this.controllerName}/${clientCode}/${patientId}/${attachmentId}`, 
    );
  }

  public deleteAttachment(
    { clientCode, patientId, attachmentId }: DeleteAttachmentRequest,
  ): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiBaseUrl}/${this.controllerName}/${clientCode}/${patientId}/${attachmentId}`, 
    );
  }

  private getFormData(attachments: File[]): FormData {
    const data = new FormData();
    attachments.forEach((attachment: File) => {
      data.append('attachments', attachment);
    });
    return data;
  }

}
