<div class="h-full w-full">
<!--  Top and bottoms are inverted due to flex-col-reverse. -->
  <div
    class="flex h-full flex-col-reverse gap-3 overflow-auto scroll-smooth p-4"
    #scrollBar
    scroll-tracker
    [threshold]="{ top: bottomThreshold, bottom: topThreshold }"
    (bottomThreshold)="topReached()"
    (topThreshold)="bottomReached()"
  >
    @for (messageGroup of messageGroups; track trackByMessageGroup(groupIndex, messageGroup); let groupIndex = $index) {
      <div class="flex flex-col-reverse gap-1">
        @for (
          messageId of messageGroup.messageIds;
          track trackByMessageId(messageIndex, messageId);
          let messageIndex = $index
        ) {
          <pxc-message-bubble
            [message]="messages[messageId]"
            [showHeader]="messageGroup.options?.[messageId]?.showSender ?? false"
            [readers]="groupIndex === 0 && messageIndex === 0 ? lastMessageReaders : null"
            [channelUsersCount]="channel.users.length"
            (messageClicked)="openReadersList(messages[messageId])"
          ></pxc-message-bubble>
        }
        <div class="sticky left-1/2 top-4 mt-2 w-fit -translate-x-1/2 rounded-xl bg-neutral-1 px-3 py-1">
          <p class="text-center text-sm font-medium text-secondary">
            {{ messageGroup.date | date: 'longDate' }}
          </p>
        </div>
      </div>
    }
  </div>

  <button
    tuiButton
    appearance="primary"
    class="fixed left-1/2 -translate-x-1/2 shadow-xl transition-all duration-300"
    [ngClass]="{
      'pointer-events-none bottom-0 opacity-0': !hasUnseenMessages,
      'bottom-14 opacity-100': hasUnseenMessages,
    }"
    iconEnd="@tui.arrow-down"
    (click)="scrollToBottom()"
  >
    Nouveaux messages
  </button>
</div>
