import { Pipe, PipeTransform } from '@angular/core';
import { ColorPickerConfig } from '../config/studio-interfaces';

@Pipe({
  name: 'colorPickerWidth',
  standalone: true,
})
export class ColorPickerWidthPipe implements PipeTransform {

  transform(drawConfigWidth: number, colorPickerConfig: ColorPickerConfig ): unknown {
    const maxRoundSize = colorPickerConfig.displayWidth
    - (colorPickerConfig.maxWidth - colorPickerConfig.displayWidth) - 2;
    return Math.round((drawConfigWidth * maxRoundSize) / colorPickerConfig.maxWidth + 5);
  }

}
