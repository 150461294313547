import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CreatorType, FlattenedSequence, Telemonitoring, TelemonitoringUser } from '@pixacare/pxc-ts-core';
import { DocumentService } from '@services/document.service';
import { PatientContactHttpService } from '@services/http/patient-contact.http.service';
import { TelemonitoringHttpService } from '@services/http/telemonitoring.http.service';
import { OperationState } from '@shared/models/enums/operation.enum';
import { ActionState } from '@shared/models/helpers/action-state';
import { UserInputSelection } from '@shared/models/helpers/user-input-selection';
import { CreateTelemonitoringRequest } from '@shared/models/telemonitoring/create-telemonitoring-request';
import { telemonitoringsActions } from '@shared/store/telemonitorings/telemonitorings.actions';
import { catchError, iif, map, Observable, of, startWith, switchMap } from 'rxjs';
import { TelemonitoringRequestForm } from './telemonitoring-request-form';

@Injectable()
export class TelemonitoringRequestService {

  private readonly telemonitoringService = inject(TelemonitoringHttpService);
  private readonly documentService = inject(DocumentService);
  private readonly store = inject(Store);
  private readonly patientContactService = inject(PatientContactHttpService);

  downloadReport(telemonitoring: Telemonitoring): void {
    this.documentService.downloadDocument({
      name: 'Tutoriel',
      document$: this.telemonitoringService.getTutorial(+telemonitoring.id),
      notifications: {
        loading: {
          message: 'Le tutoriel sera automatiquement téléchargé.',
          title: 'Téléchargement du tutoriel en cours',
        },
        success: {
          message:'Le tutoriel a bien été téléchargé.',
          title: 'Téléchargement du tutoriel réussi',
        },
        error: {
          message: 'Le tutoriel n\'a pas été téléchargé.',
          title: 'Echec du téléchargement du tutoriel, merci de réessayer plus tard.',
        },
      },
    });

  }

  createMonitoring(form: FormGroup<TelemonitoringRequestForm>): Observable<ActionState<Telemonitoring>> {

    const sequenceIds = this.getSequenceIds(form);

    const clientCode = form.get('clientCode').value;
    const creatorType = form.get('creatorType').value as CreatorType;

    const formPatientContact = form.get('patientContact').value;

    const patientContact$ = iif(() => creatorType === CreatorType.BY_PATIENT && !formPatientContact?.id,
      this.patientContactService.createPatientContact(
        formPatientContact,
        clientCode,
      ).pipe(
        map((contact) => contact.id),
      ),
      of(formPatientContact?.id),
    );

    const collaborators = form.get('collaborators').value as UserInputSelection[];
    const telemonitoringUsers: TelemonitoringUser[] = collaborators.map(({ user, email }) => ({
      id: user?.id,
      mailAddress: email,
      isNotified: false,
    }));

    return patientContact$.pipe(
      switchMap((patientContactId) => {

        const payload: CreateTelemonitoringRequest = {
          patientId: form.get('patientId').value,
          name: form.get('name').value,
          message: form.get('message').value,
          clientCode,
          sequenceIds,
          departmentIds: form.get('departmentIds').value,
          telemonitoringUsers,
          creatorType,
          ...(creatorType === CreatorType.BY_PATIENT && { 
            patientContactId,
            patientFormId: form.get('patientForm').value?.id,
            shouldSendTextMessageToPatient: form.get('shouldSendTextMessageToPatient').value,
            ...(form.get('enableAutomaticReminderForInactivePatient').value && {
              reminderDaysInterval: form.get('reminderDaysInterval').value,
            }),
          }),
        };

        return this.telemonitoringService.createTelemonitoring(payload)
          .pipe(
            map(({ body }) => {

              this.store.dispatch(telemonitoringsActions.addTelemonitoring({
                telemonitoring: body,
                sequenceIds,
              }));

              this.downloadReport(body);

              return {
                status: OperationState.FINISHED,
                data: body,
              };

            }),
          );
      }),
      catchError((error) => {
        console.error('An error occured while creating a telemonitoring', error);
        return [{
          status: OperationState.FAILED,
          message: 'Une erreur est survenue lors de la création du télésuivi.',
        }];
      }),
      startWith({
        status: OperationState.ONGOING,
      }),
    );

  }

  getSequenceIds(form: FormGroup): number[] {
    const sequences = form.get('sequences').value as FlattenedSequence[];
    return sequences?.map((s) => s.sequenceInstance.id) ?? [];
  }

}
