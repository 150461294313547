import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AttachmentUriResponse } from '@pixacare/pxc-ts-core';
import { DialogService } from '@services/dialog.service';
import { AttachmentHttpService } from '@services/http/attachment.http.service';
import { Attachment } from '@shared/models/attachments/attachment';
import { ActionsService } from '@shared/models/helpers/actions-service';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { MenuActionId } from '@shared/models/menu-actions/menu-action-id.enum';
import { attachmentsActions } from '@shared/store/attachments/attachments.actions';
import { downloadFile } from '@shared/utils/utils';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AttachmentActionsService extends ActionsService {
  
  actions: MenuAction<Attachment>[] = [
    {
      id: MenuActionId.ATTACHMENT_DOWNLOAD,
      label: 'Télécharger',
      icon: '@tui.download',
      execute: ({ clientCode, patientId, id: attachmentId, originalFilename }: Attachment): void => {
        this.attachmentService.getAttachmentUri({ clientCode, patientId, attachmentId }).subscribe({ 
          next: ({ uri }: AttachmentUriResponse) => {
            downloadFile(uri, originalFilename);
          }, 
        });
      },
    },
    {
      id: MenuActionId.ATTACHMENT_DELETE,
      label: 'Supprimer',
      icon: '@tui.trash',
      execute: ({ clientCode, patientId, id: attachmentId }: Attachment): void => {
        this.dialogService.openConfirm('Suppression du fichier', {
          content: 'Êtes-vous certain de vouloir supprimer ce fichier ? Cette opération est irréversible.',
          yes: 'Supprimer',
          no: 'Annuler',
        }).pipe(
          filter((response: boolean) => response),
        ).subscribe(() => {
          this.store.dispatch(attachmentsActions.deleteAttachment({ clientCode, patientId, attachmentId }));
        });
      },
    },
  ];
  
  private readonly store = inject(Store);
  private readonly dialogService = inject(DialogService);
  private readonly attachmentService = inject(AttachmentHttpService);

}
