const TUI_FRENCH_LANGUAGE_ADDON_COMMERCE = {
  cardNumber: ['Number', 'Numéro de carte'],
  cardExpiry: ['Expiry', 'Date d"Expiration de la carte']
};
const TUI_FRENCH_LANGUAGE_ADDON_EDITOR = {
  colorSelectorModeNames: ['Couleur unie', 'Dégradé de couleur'],
  toolbarTools: {
    undo: 'Annuler',
    redo: 'Rétablir',
    font: 'Police',
    fontStyle: 'Style de police',
    fontSize: 'Taille de police',
    bold: 'Gras',
    italic: 'Italique',
    underline: 'Souligner',
    strikeThrough: 'Barrer',
    justify: 'Justifier',
    justifyLeft: 'Aligner à gauche',
    justifyCenter: 'Centrer',
    justifyRight: 'Justifier à droite',
    justifyFull: 'Justifier',
    list: 'Liste',
    indent: 'Indenter',
    outdent: 'Réduire le retrait',
    unorderedList: 'Liste à puces',
    orderedList: 'Liste numérotée',
    quote: 'Citation',
    foreColor: 'Couleur de texte',
    backColor: 'Couleur de fond',
    hiliteColor: 'Couleur de mise en évidence',
    clear: 'Effacer',
    link: 'Lien',
    attach: 'Joindre un fichier',
    tex: 'Insérer TeX',
    code: 'Code',
    image: 'Insérer une image',
    insertHorizontalRule: 'Insérer une règle horizontale',
    superscript: 'Exposant',
    subscript: 'Indice',
    insertTable: 'Insérer une table',
    insertGroup: 'Insérer un groupe',
    hiliteGroup: 'Hilite group',
    removeGroup: 'Supprimer le groupe',
    insertAnchor: 'Insérer une ancre',
    mergeCells: 'Fusionner les cellules',
    splitCells: 'Diviser les cellules',
    rowsColumnsManaging: 'Gestion des lignes et des colonnes',
    cellColor: 'Couleur de la cellule',
    setDetails: 'Accordéon',
    removeDetails: "Supprimer l'accordéon"
  },
  editorEditLink: {
    urlExample: 'exemple.com',
    anchorExample: 'ancre'
  },
  editorTableCommands: [['Insérer une colonne avant', 'Insérer une colonne après'], ['Insérer une ligne avant', 'Insérer une ligne après'], ['Supprimer la colonne', 'Supprimer la ligne']],
  editorCodeOptions: ['Code dans le texte', 'Bloc de code'],
  editorFontOptions: {
    small: 'Petite',
    large: 'Grande',
    normal: 'Normale',
    title: 'Titre',
    subtitle: 'Sous-titre'
  }
};
const TUI_FRENCH_LANGUAGE_ADDON_TABLE = {
  showHideText: 'Afficher/Cacher',
  paginationTexts: {
    pages: 'Pages',
    linesPerPage: 'Lignes par page',
    of: 'de'
  }
};
const TUI_FRENCH_LANGUAGE_COUNTRIES = {
  AD: 'Andorre',
  AE: 'Émirats arabes unis',
  AF: 'Afghanistan',
  AG: 'Antigua et Barbuda',
  AI: 'Anguilla',
  AL: 'Albanie',
  AM: 'Arménie',
  AO: 'Angola',
  AR: 'Argentine',
  AT: 'Autriche',
  AU: 'Australie',
  AW: 'Aruba',
  AZ: 'Azerbaïdjan',
  BA: 'Bosnie-Herzégovine',
  BB: 'Barbade',
  BD: 'Bangladesh',
  BE: 'Belgique',
  BF: 'Burkina Faso',
  BG: 'Bulgarie',
  BH: 'Bahrein',
  BI: 'Burundi',
  BJ: 'Bénin',
  BL: 'Saint-Barthélemy',
  BM: 'Bermudes',
  BN: 'Brunéi Darussalam',
  BO: 'Bolivie',
  BQ: 'Bonaire, Saint-Eustache et Saba',
  BR: 'Brésil',
  BS: 'Bahamas',
  BT: 'Bhoutan',
  BW: 'Botswana',
  BY: 'Bélarus',
  BZ: 'Bélize',
  CA: 'Canada',
  CD: 'République Démocratique du Congo (RDC)',
  CF: 'République centrafricaine',
  CG: 'Congo Brazzaville',
  CH: 'Suisse',
  CI: "Côte d'Ivoire",
  CL: 'Chili',
  CM: 'Cameroun',
  CN: 'Chine',
  CO: 'Colombie',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cap-Vert',
  CW: 'Curaçao',
  CY: 'Chypre',
  CZ: 'République Tchèque',
  DE: 'Allemagne',
  DJ: 'Djibouti',
  DK: 'Danemark',
  DM: 'Dominique',
  DO: 'République Dominicaine',
  DZ: 'Algérie',
  EC: 'Équateur',
  EE: 'Estonie',
  EG: 'Égypte',
  ER: 'Érythrée',
  ES: 'Espagne',
  ET: 'Éthiopie',
  FI: 'Finlande',
  FJ: 'Fidji',
  FK: 'Iles Malouines/Falkland',
  FM: 'États fédérés de Micronésie',
  FR: 'France',
  GA: 'Gabon',
  GB: 'Royaume-Uni',
  GD: 'Grenade',
  GE: 'Géorgie',
  GF: 'Guyane',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Groenland',
  GM: 'Gambie',
  GN: 'Guinée',
  GP: 'Guadeloupe',
  GQ: 'Guinée équatoriale',
  GR: 'Grèce',
  GT: 'Guatemala',
  GW: 'Guinée-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HR: 'Croatie',
  HT: 'Haïti',
  HU: 'Hongrie',
  ID: 'Indonesia',
  IE: 'Irlande',
  IL: 'Israël',
  IN: 'Inde',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Islande',
  IT: 'Italie',
  JM: 'Jamaïque',
  JO: 'Jordanie',
  JP: 'Japon',
  KE: 'Kenya',
  KG: 'Kirghizistan',
  KH: 'Cambodge',
  KM: 'Comores',
  KN: 'Saint-Kitts-et-Nevis',
  KP: 'Corée du Nord',
  KR: 'Corée du Sud',
  KW: 'Koweït',
  KY: 'Iles Caïmans',
  KZ: 'Kazakhstan',
  LA: 'Lao',
  LB: 'Liban',
  LC: 'Sainte-Lucie',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Libéria',
  LS: 'Lesotho',
  LT: 'Lituanie',
  LU: 'Luxembourg',
  LV: 'Lettonie',
  LY: 'Libye',
  MA: 'Maroc',
  MC: 'Monaco',
  MD: 'Moldavie',
  ME: 'Monténégro',
  MF: 'Saint-Martin',
  MG: 'Madagascar',
  MK: 'Macédoine',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolie',
  MO: 'Macao',
  MQ: 'Martinique',
  MR: 'Mauritanie',
  MS: 'Montserrat',
  MT: 'Malte',
  MU: 'Maurice',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexique',
  MY: 'Malaisie',
  MZ: 'Mozambique',
  NA: 'Namibie',
  NC: 'Nouvelle-Calédonie',
  NE: 'Niger',
  NG: 'Nigéria',
  NI: 'Nicaragua',
  NL: 'Pays-Bas',
  NO: 'Norvège',
  NP: 'Népal',
  NZ: 'Nouvelle-Zélande',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Pérou',
  PF: 'Polynésie française',
  PG: 'Papouasie-Nouvelle-Guinée',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Pologne',
  PT: 'Portugal',
  PW: 'Palaos',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'La Réunion',
  RO: 'Roumanie',
  RS: 'Serbie',
  RU: 'Russie',
  RW: 'Rwanda',
  SA: 'Arabie Saoudite',
  SB: 'Iles Salomon',
  SC: 'Seychelles',
  SD: 'Soudan',
  SE: 'Suède',
  SG: 'Singapour',
  SH: 'Sainte-Hélène',
  SI: 'Slovénie',
  SK: 'Slovaquie',
  SL: 'Sierra Leone',
  SM: 'Saint-Marin',
  SN: 'Sénégal',
  SO: 'Somalie',
  SR: 'Suriname',
  ST: 'Sao Tomé-et-Principe',
  SV: 'El Salvador',
  SX: 'Saint-Martin',
  SY: 'République arabe syrienne',
  SZ: 'Swaziland',
  TC: 'Iles Turks-et-Caïcos',
  TD: 'Tchad',
  TG: 'Togo',
  TH: 'Thaïlande',
  TJ: 'Tadjikistan',
  TL: 'Timor-Leste',
  TM: 'Turkménistan',
  TN: 'Tunisie',
  TO: 'Tonga',
  TR: 'Turquie',
  TT: 'Trinité-et-Tobago',
  TW: 'Taïwan',
  TZ: 'Tanzanie',
  UA: 'Ukraine',
  UG: 'Ouganda',
  US: "États-Unis d'Amérique",
  UY: 'Uruguay',
  UZ: 'Ouzbékistan',
  VC: 'Saint-Vincent-et-les-Grenadines',
  VE: 'Venezuela',
  VG: 'Iles vierges britanniques',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WS: 'Samoa',
  XK: 'Kosovo',
  YE: 'Yémen',
  YT: 'Mayotte',
  ZA: 'Afrique du Sud',
  ZM: 'Zambie',
  ZW: 'Zimbabwe',
  AC: 'Ascension Island',
  AS: 'American Samoa',
  AX: 'Åland Islands',
  CC: 'Cocos (Keeling) Islands',
  CK: 'Cook Islands',
  CX: 'Christmas Island',
  EH: 'Western Sahara',
  FO: 'Faroe Islands',
  GG: 'Guernsey',
  GU: 'Guam',
  IM: 'Isle of Man',
  JE: 'Jersey',
  IO: 'British Indian Ocean Territory',
  KI: 'Kiribati',
  MH: 'Marshall Islands',
  MP: 'Northern Mariana Islands',
  NF: 'Norfolk Island',
  NR: 'Nauru',
  NU: 'Niue',
  PM: 'Saint Pierre and Miquelon',
  PR: 'Puerto Rico',
  PS: 'Palestine',
  SJ: 'Svalbard and Jan Mayen',
  SS: 'South Sudan',
  TA: 'Tristan da Cunha',
  TK: 'Tokelau',
  TV: 'Tuvalu',
  VA: 'Holy See',
  VI: 'Virgin Islands',
  WF: 'Wallis and Futuna'
};
const TUI_FRENCH_LANGUAGE_CORE = {
  months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  close: 'Fermer',
  clear: 'Effacer le champ',
  nothingFoundMessage: 'Aucun résultat',
  defaultErrorMessage: 'Valeur non valide',
  spinTexts: ['Précédent', 'Suivant'],
  shortWeekDays: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
  countries: TUI_FRENCH_LANGUAGE_COUNTRIES
};
const TUI_FRENCH_LANGUAGE_KIT = {
  cancel: 'Annuler',
  done: 'Terminé',
  more: 'Plus',
  otherDate: 'Autre date...',
  showAll: 'Tout afficher',
  hide: 'Cacher',
  mobileCalendarTexts: ['Choisir un jour', 'Choisir un intervalle', 'Choisissez des jours'],
  range: ['De', 'À'],
  countTexts: ['Plus', 'Moins'],
  time: {
    'HH:MM': 'HH:MM',
    'HH:MM AA': 'HH:MM AA',
    'HH:MM:SS': 'HH:MM:SS',
    'HH:MM:SS AA': 'HH:MM:SS AA',
    'HH:MM:SS.MSS': 'HH:MM:SS.MSS',
    'HH:MM:SS.MSS AA': 'HH:MM:SS.MSS AA'
  },
  dateTexts: {
    DMY: 'jj.mm.aaaa',
    MDY: 'mm.jj.aaaa',
    YMD: 'aaaa.mm.jj'
  },
  digitalInformationUnits: ['B', 'KiB', 'MiB'],
  passwordTexts: ['Afficher le mot de passe', 'Cacher le mot de passe'],
  copyTexts: ['Copier', 'Copié'],
  shortCalendarMonths: ['Janv', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
  pagination: ['Page précédente', 'Page suivante'],
  fileTexts: {
    loadingError: 'Erreur de chargement',
    preview: 'Aperçu',
    remove: 'Supprimer'
  },
  inputFileTexts: {
    defaultLabelSingle: 'ou déposez-le ici',
    defaultLabelMultiple: 'ou déposez-les ici',
    defaultLinkSingle: 'Sélectionnez un fichier',
    defaultLinkMultiple: 'Sélectionnez des fichiers',
    maxSizeRejectionReason: 'Le fichier est trop volumineux',
    formatRejectionReason: 'Format de fichier incorrect',
    drop: 'Déposez le fichier ici',
    dropMultiple: 'Déposez les fichiers ici'
  },
  multiSelectTexts: {
    all: 'Tout sélectionner',
    none: 'Ne rien sélectionner'
  },
  confirm: {
    yes: 'Oui',
    no: 'Non'
  },
  previewTexts: {
    rotate: 'Faire pivoter'
  },
  zoomTexts: {
    zoomOut: 'Zoomer',
    zoomIn: 'Dézoomer',
    reset: 'Réinitialiser'
  },
  phoneSearch: 'Tapez le pays ou le code'
};
const TUI_FRENCH_LANGUAGE_LAYOUT = {
  inputSearch: {
    popular: 'Popular',
    history: 'Recent',
    placeholder: 'Type query',
    hotkey: 'to search',
    all: 'All',
    empty: 'Nothing found'
  }
};
const TUI_FRENCH_LANGUAGE = {
  name: 'french',
  ...TUI_FRENCH_LANGUAGE_CORE,
  ...TUI_FRENCH_LANGUAGE_KIT,
  ...TUI_FRENCH_LANGUAGE_ADDON_TABLE,
  ...TUI_FRENCH_LANGUAGE_ADDON_COMMERCE,
  ...TUI_FRENCH_LANGUAGE_ADDON_EDITOR,
  ...TUI_FRENCH_LANGUAGE_LAYOUT
};

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_FRENCH_LANGUAGE, TUI_FRENCH_LANGUAGE_ADDON_COMMERCE, TUI_FRENCH_LANGUAGE_ADDON_EDITOR, TUI_FRENCH_LANGUAGE_ADDON_TABLE, TUI_FRENCH_LANGUAGE_CORE, TUI_FRENCH_LANGUAGE_COUNTRIES, TUI_FRENCH_LANGUAGE_KIT, TUI_FRENCH_LANGUAGE_LAYOUT };
