<!-- If nothing has loaded -->
@if ((items$ | async) === null) {
  <pxc-loader
    [dark]="true"
    class="loader"
    data-testid="items-loading"
  ></pxc-loader>
}

@if (items$ | async; as items) {
  @let currentItem = items[currentIndex];

  <div class="gallery-slider thumb relative h-screen select-none">
    <div class="flex items-center justify-center overflow-hidden">
      @for (item of items; track trackByGalleryItem(item); let index = $index) {
        @if (item.media && isInLazyLoadRange(index, items.length)) {
          @switch (item.media.type) {
            @case (MediaType.IMAGE) {
              <pxc-zoom-container
                [hidden]="currentIndex !== index || !item.isLoaded"
                [enableOverflow]="true"
              >
                <img
                  class="pointer-events-none mx-auto h-full object-contain"
                  [src]="item.media.uri"
                  [alt]="'image-' + index"
                  (load)="onPictureLoad(item)"
                  (error)="onPictureError(item)"
                  [attr.data-testid]="'image-' + index"
                />
              </pxc-zoom-container>
            }
            @case (MediaType.VIDEO) {
              <pxc-video-player
                [media]="item.media"
                [hidden]="currentIndex !== index"
              ></pxc-video-player>
            }
          }
        }
      }

      @if (currentItem) {
        @if (currentItem.media?.type === MediaType.IMAGE && !currentItem.isLoaded) {
          <pxc-loader
            [dark]="true"
            data-testid="image-loader"
          ></pxc-loader>
        }

        @if (currentItem.innerHtml) {
          <div
            class="w-96"
            [innerHTML]="currentItem.innerHtml"
          ></div>
        }
      }
    </div>

    <!-- Thumbnails display -->
    @if (config.thumbnails) {
      <pxc-gallery-thumbnail-list
        [items]="items"
        [activeIndex]="currentIndex"
        (thumbnailClicked)="navigateToIndex($event)"
        class="my-2"
        data-testid="thumbnails"
      ></pxc-gallery-thumbnail-list>
    }
  </div>

  <!-- Gallery navigation -->
  @if (config.navigation) {
    <button
      tuiIconButton
      class="!absolute left-2 top-[50vh] -translate-y-1/2"
      (click)="navigateToPrevious()"
      data-testid="previous"
      appearance="glass"
      iconStart="@tui.chevron-left"
    ></button>
    <button
      tuiIconButton
      class="!absolute right-2 top-[50vh] -translate-y-1/2"
      (click)="navigateToNext()"
      data-testid="next"
      appearance="glass"
      iconStart="@tui.chevron-right"
    ></button>
  }

  <!-- Action bar -->
  <div class="absolute right-3 top-3 flex flex-col gap-1 sm:flex-row">
    <pxc-icon-action-buttons
      appearance="glass"
      size="m"
      [actions]="actions"
      [entity]="currentItem"
    ></pxc-icon-action-buttons>

    <button
      tuiIconButton
      appearance="glass"
      iconStart="@tui.x"
      (click)="close()"
      data-testid="close-action"
    ></button>
  </div>

  <ng-template #insertionAnchor></ng-template>
}
