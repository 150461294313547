import { Injectable } from '@angular/core';
import { sliderConfig } from '@modules/gallery/gallery.configs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SadmEntity } from '@pixacare/pxc-ts-core';
import { GalleryConfig } from '@shared/models/gallery-config';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { Widget } from '@shared/models/widget';
import { Observable } from 'rxjs';
import { GalleryItem } from 'src/app/shared/models/gallery-item';
import { selectSadmEntity } from 'src/app/shared/store/sadm/sadm.selectors';
import { sequencesActions } from 'src/app/shared/store/sequences/sequences.actions';
import { SliderGalleryService } from '../slider-gallery.service';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class SadmGalleryService extends SliderGalleryService<SadmEntity> {

  protected getEntity(sadmEntityId: number): Observable<SadmEntity> {
    return this.store.select(selectSadmEntity(sadmEntityId));
  }

  protected getItems(entity: SadmEntity): GalleryItem[] {
    return entity.sequences.map(({ medias }): GalleryItem => ({ media: medias[0] }));
  }
  
  protected getConfig(): GalleryConfig {
    return {
      ...sliderConfig,
    };
  }
  
  protected getActions(): MenuAction[] {
    return [];
  }

  protected getWidget(): Widget {
    return undefined;
  }

  protected getImageLoadingErrorHandler() {
    return ({ media }: GalleryItem): void => {
      if (media) {
        this.store.dispatch(sequencesActions.updateSequenceMedia({
          clientCode: media.clientCode,
          sequenceId: media.sequenceId,
          mediaId: media.id,
        }));
      }
    };
  } 

}
