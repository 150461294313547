import { inject, Injectable } from '@angular/core';
import { PatientService } from '@modules/dashboard/patient/patient.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Patient } from '@pixacare/pxc-ts-core';
import { Attachment } from '@shared/models/attachments/attachment';
import { UploadingFile } from '@shared/models/attachments/uploading-file';
import { attachmentsActions } from '@shared/store/attachments/attachments.actions';
import { selectPatientAttachmentExtensions, selectPatientAttachments, selectPatientUploadingFiles } from '@shared/store/attachments/attachments.selectors';
import { AttachmentProps } from '@shared/store/attachments/models/props/attachment.props';
import { UploadAttachmentsProps } from '@shared/store/attachments/models/props/upload-attachments.props';
import { map, Observable, switchMap, tap } from 'rxjs';

@UntilDestroy()
@Injectable()
export class PatientAttachmentsService {

  public attachmentProps$: Observable<AttachmentProps>;
  public uploadingFiles$: Observable<UploadingFile[]>;
  public attachments$: Observable<Attachment[]>;
  public extensions$: Observable<string[]>;

  private readonly store = inject(Store);
  private readonly patientService = inject(PatientService);
    
  constructor() {
    this.attachmentProps$ = this.patientService.getPatient().pipe(
      map(({ clientCode, id }: Patient) => ({ clientCode, patientId: id })),
      untilDestroyed(this),
    );
    this.uploadingFiles$ = this.attachmentProps$.pipe(
      switchMap((props: AttachmentProps) => this.store.select(selectPatientUploadingFiles(props))),
      untilDestroyed(this),
    );
    this.attachments$ = this.attachmentProps$.pipe(
      switchMap((props: AttachmentProps) => 
        this.store.select(selectPatientAttachments(props)).pipe(
          tap((attachments: Attachment[]) => {
            if (!attachments) {
              this.store.dispatch(attachmentsActions.getPatientAttachments(props));
            }
          }),
        ),
      ),
      untilDestroyed(this),
    );
    this.extensions$ = this.attachmentProps$.pipe(
      switchMap((props: AttachmentProps) => 
        this.store.select(selectPatientAttachmentExtensions(props)),
      ),
      untilDestroyed(this),
    );
  }

  public dispatchUploadAttachments(props: UploadAttachmentsProps): void {
    this.store.dispatch(
      attachmentsActions.uploadAttachments(props),
    );
  }

}
