import { Injectable } from '@angular/core';
import { sliderConfig } from '@modules/gallery/gallery.configs';
import { SequencePreviewDrawerComponent } from '@modules/sequence/sequence-preview-drawer/sequence-preview-drawer.component';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';
import { GalleryConfig } from '@shared/models/gallery-config';
import { Widget } from '@shared/models/widget';
import { sequencesActions } from '@shared/store/sequences/sequences.actions';
import { selectSequence } from '@shared/store/sequences/sequences.selectors';
import { first, Observable, of, switchMap } from 'rxjs';
import { GalleryItem } from 'src/app/shared/models/gallery-item';
import { SliderGalleryService } from '../slider-gallery.service';

@Injectable({
  providedIn: 'root',
})
export abstract class SequenceGalleryService extends SliderGalleryService<FlattenedSequence> {

  protected getEntity(sequenceId: number, clientCode: string): Observable<FlattenedSequence> {
    return this.store.select(selectSequence(sequenceId)).pipe(
      switchMap((sequence) => sequence ? of(sequence) : this.fetchSequence(clientCode, sequenceId)),
    );
  }

  protected getItems(entity: FlattenedSequence): GalleryItem[] {
    return entity.medias.map((media): GalleryItem => ({ media }));
  }

  protected getConfig(): GalleryConfig {
    return {
      ...sliderConfig,
    };
  }

  protected getWidget(sequence: FlattenedSequence): Widget {
    return {
      component: SequencePreviewDrawerComponent,
      config: {
        context: {
          patient: sequence.patientInstance,
          date: sequence.sequenceInstance.createdOn,
        },
        dialogClass: 'dialog--gallery-info',
      },
    };
  }

  protected getImageLoadingErrorHandler() {
    return ({ media }: GalleryItem): void => {
      if (media) {
        this.store.dispatch(sequencesActions.updateSequenceMedia({
          clientCode: media.clientCode,
          sequenceId: media.sequenceId,
          mediaId: media.id,
        }));
      }
    };
  }

  private fetchSequence(clientCode: string, sequenceId: number): Observable<FlattenedSequence> {
    this.store.dispatch(sequencesActions.getUserSequence({
      clientCode,
      sequenceId,
    }));
    return this.store.select(selectSequence(sequenceId)).pipe(
      first((sequence) => !!sequence),
    );
  }

}
