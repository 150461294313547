import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { LoaderComponent } from '@modules/shared/loader/loader.component';
import { Store } from '@ngrx/store';
import { LicenseState } from '@pixacare/pxc-ts-core';
import { administrationColumnsWithGroups, administrationColumnsWithoutGroups } from '@shared/models/users-administration/administration-columns-config';
import { selectClientDepartments } from '@shared/store/departments/departments.selectors';
import { selectHasLicenseSupportAccess } from '@shared/store/licenses/licenses.selectors';
import { selectClientCode } from '@shared/store/router/router.selectors';
import { TuiTable } from '@taiga-ui/addon-table';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiHint, TuiScrollbar, TuiSurface } from '@taiga-ui/core';
import { iif, map, Observable, of, switchMap } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MenuActionAdapterService } from 'src/app/services/menu-action-adapter.service';
import { ActionsByUserState } from 'src/app/shared/models/actions-by-user-state';
import { AdministrationUser } from 'src/app/shared/models/administration-user';
import { UserAdminState } from 'src/app/shared/models/enums/user-admin-state.enum';
import { ActionButton } from 'src/app/shared/models/menu-actions/action-buttons';
import { SortPipe } from 'src/app/shared/pipes/sort.pipe';
import { CellOverflowComponent } from '../../shared/cell-overflow/cell-overflow.component';
import { SharedModule } from '../../shared/shared.module';
import { UsersAdminSortService } from './users-admin-sort.service';

@Component({
  selector: 'pxc-users-administration-table',
  standalone: true,
  imports: [
    CommonModule,
    TuiTable,
    SharedModule,
    TuiLet,
    ScrollingModule,
    TuiScrollbar,
    SortPipe,
    TuiSurface,
    TuiHint,
    CellOverflowComponent,
    LoaderComponent,
  ],
  providers: [
    UsersAdminSortService,
    MenuActionAdapterService,
  ],
  templateUrl: './users-administration-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersAdministrationTableComponent implements OnInit  {

  @Input() administrationUsers: AdministrationUser[];
  @Input() actionsByUserState: ActionsByUserState;

  @Output() adminChanged = new EventEmitter<AdministrationUser>();

  actionButtonsByState: Record<UserAdminState, ActionButton[]>;
  hasSupportLicense$: Observable<boolean>;
  columns$: Observable<string[]>;
  userDepartments$: Observable<{ [userId: number]: string }>;

  currentUserId: number;

  TABLE_ITEM_HEIGHT = 61;
  LicenseState = LicenseState;
  UserAdminState = UserAdminState;

  readonly usersAdminSortService = inject(UsersAdminSortService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly menuActionsAdapter = inject(MenuActionAdapterService);

  constructor(private readonly store: Store) {}

  ngOnInit(): void {

    this.hasSupportLicense$ = this.store.select(selectHasLicenseSupportAccess);

    this.actionButtonsByState = Object.entries(this.actionsByUserState).reduce((acc, [state, actions]) => ({
      ...acc,
      [state]: this.menuActionsAdapter.menuActionsToActionsButtons(actions),
    }), {} as Record<UserAdminState, ActionButton[]>);

    this.currentUserId = this.authenticationService.currentUser.id;

    this.columns$ = this.hasSupportLicense$.pipe(
      switchMap((hasSupportLicense) => 
        iif(
          () => hasSupportLicense,
          of(administrationColumnsWithGroups),
          of(administrationColumnsWithoutGroups),
        ),
      ));

    this.userDepartments$ = this.store.select(selectClientCode).pipe(
      switchMap((clientCode) => 
        this.store.select(selectClientDepartments(clientCode)),
      ),
      map((departments) => (
        this.administrationUsers.reduce((usersToDepartments, user) => ({
          ...usersToDepartments,
          [user.id]: departments
            .filter((department) => !!department.memberships[user.id])
            .map((department) => department.name)
            .join(', '),
        }), {} as { [userId: number]: string })
      )));
  }

  adminChange(user: AdministrationUser): void {
    this.adminChanged.emit(user);
  }

  trackByUser(index: number, item: AdministrationUser): string {
    return `${index}-${item.id}-${item.licenseState}-${item.state}-${item.isAdmin}`;
  }

}
