/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AutoCompleteModel } from '@shared/models/helpers/auto-complete-model';
import { makeFilter } from '@shared/utils/filter-utils';
import { TuiButton } from '@taiga-ui/core';
import { combineLatest, first, map, of, switchMap } from 'rxjs';
import { FilterType } from 'src/app/shared/models/filters/filter-type.enum';
import { FilterBarService } from '../filter-bar.service';

@Component({
  selector: 'pxc-filter-buttons',
  templateUrl: './filter-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiButton,
  ],
})
export class FilterButtonsComponent {

  private readonly filterBarService = inject(FilterBarService);
  
  template$ = this.filterBarService.templates$.pipe(
    map((templates) => templates
      .find((template) => template.type === FilterType.BUTTONS),
    ),
  );

  selectedIndex$ = this.template$.pipe(
    switchMap((template) => this.filterBarService.getSelectedIndex$(template)),
  );

  buttons$ = this.template$.pipe(
    switchMap((template) => template ? template.getValue('') : of(null)),
  );

  updateFilters(button: AutoCompleteModel, index: number): void {
    combineLatest([
      this.template$,
      this.selectedIndex$,
    ])
      .pipe(first())
      .subscribe(([template, selectedIndex]) => {

        if (index === selectedIndex) {  
          return;
        }

        this.filterBarService.updateTemplate({
          ...template,
          filters: [
            ...(button.value ? [makeFilter(button, template)] : []),
          ],
        });
      });
  }

}
