<button
  class="group flex border-none bg-transparent p-1 outline-none"
  tuiDropdownOpen
  data-testid="color-picker-button"
  [tuiDropdown]="colorPicker"
  tuiDropdownAlign="right"
  [tuiDropdownSided]="true"
  [tuiDropdownOffset]="12"
>
  <div
    class="relative flex items-center justify-center rounded-full border-2 border-solid border-border-normal"
    [style.width.px]="colorPickerConfig.displayWidth"
    [style.height.px]="colorPickerConfig.displayWidth"
  >
    <div
      class="absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full border border-solid border-neutral-1"
      [style.background-color]="color.code"
      [style.width.px]="width | colorPickerWidth: colorPickerConfig"
      [style.height.px]="width | colorPickerWidth: colorPickerConfig"
    ></div>
  </div>
</button>

<ng-template #colorPicker>
  <pxc-color-picker
    [colorPickerConfig]="colorPickerConfig"
    [color]="color"
    [width]="width"
    (colorChange)="colorChange.emit($event)"
    (widthChange)="widthChange.emit($event)"
  ></pxc-color-picker>
</ng-template>
