@if (labels$ | async; as labels) {
  <div>
    <pxc-label-table
      [labels]="labels"
      [actions]="actions"
    >
      <ng-container slot="header">
        @if (department$ | async; as department) {
          <div class="flex items-center justify-between border-b border-border-hover p-3">
            <div class="flex items-center gap-3">
              <h3 tuiTitle>Mots clés de {{ department.name }}</h3>
              <tui-badge appearance="info">{{ labels.length }} mots clés</tui-badge>
            </div>
            <button
              slot="actions"
              tuiButton
              iconStart="@tui.plus"
              (click)="addLabels()"
            >
              Ajouter
            </button>
          </div>
        }
      </ng-container>
    </pxc-label-table>
  </div>
}
