@if (uploadingFiles?.length) {
  <div class="flex flex-col gap-2">
    @for (uploadingFile of uploadingFiles; track uploadingFile.id) {
      <div class="flex items-center justify-between rounded-lg border-2 border-solid border-border-normal bg-white p-4">
        <div class="flex items-center justify-start">
          <pxc-file-icon
            class="max-h-10 min-h-10 min-w-10 max-w-10"
            [filename]="uploadingFile.file.name"
          ></pxc-file-icon>
          <div class="mx-4 flex flex-col justify-evenly">
            <span class="font-bold">
              {{ uploadingFile.file.name }}
            </span>
            <span>
              {{ uploadingFile.file.size | fileSize }}
            </span>
          </div>
        </div>
        @switch (uploadingFile.uploadState) {
          @case (OperationState.ONGOING) {
            <pxc-spinner></pxc-spinner>
          }
          @case (OperationState.FINISHED) {
            <tui-icon
              class="text-emerald-600"
              icon="@tui.check"
            ></tui-icon>
          }
          @case (OperationState.FAILED) {
            <div class="flex items-center gap-2">
              <div class="flex items-center gap-1">
                <tui-icon
                  class="text-red-600"
                  icon="@tui.x"
                ></tui-icon>
                <span class="text-red-600">Erreur</span>
              </div>
              <button
                tuiButton
                appearance="outline"
                iconStart="@tui.refresh-cw"
                size="s"
                type="button"
                (click)="retry(uploadingFile)"
              >
                Réessayer
              </button>
            </div>
          }
        }
      </div>
    }
  </div>
}
