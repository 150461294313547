import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AvatarStyle, Patient } from '@pixacare/pxc-ts-core';
import { AvatarStyleService } from '@services/avatar-style.service';
import { Department } from '@shared/models/base/department';
import { Size } from '@shared/models/enums/size.enum';
import { selectClientDepartments, selectIsDepartmentStateLoaded } from '@shared/store/departments/departments.selectors';
import { TuiButton, TuiDialogContext, TuiIcon } from '@taiga-ui/core';
import { TuiSkeleton } from '@taiga-ui/kit';
import { TuiBlockStatus } from '@taiga-ui/layout';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { map, Observable } from 'rxjs';
import { PatientModule } from '../../patient/patient.module';
import { SharedModule } from '../../shared/shared.module';

@Component({
  standalone: true,
  templateUrl: './patient-department-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PatientModule,
    CommonModule,
    TuiIcon,
    SharedModule,
    TuiButton,
    TuiBlockStatus,
    TuiSkeleton,
  ],
})
export class PatientDepartmentPickerComponent {

  @Input() departments$: Observable<Department[]>;
  @Input() patient: Patient;
  @Input() clientCode: string;
  
  isDepartmentsStateLoaded$: Observable<boolean>;
  
  Size = Size;

  constructor(
    private readonly avatarStyleService: AvatarStyleService, 
    private readonly store: Store,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<number, {
      patient: Patient;
      clientCode: string;
    }>,
  ) {
    this.patient = this.context.data.patient;
    this.clientCode = this.context.data.clientCode;
    this.isDepartmentsStateLoaded$ = this.store.select(selectIsDepartmentStateLoaded(this.clientCode));
    this.departments$ = this.store.select(selectClientDepartments(this.clientCode)).pipe(
      map((departments: Department[]) => departments.filter(
        (department: Department) => this.patient.departmentIds.includes(department.id)),
      ),
    );

  }

  getDepartmentAvatarStyle(department: Department): AvatarStyle {
    return this.avatarStyleService.getAvatarColors(department.name);
  }

  pickDepartment(departmentId: number): void {
    this.context.completeWith(departmentId);
  }

  cancel(): void {
    this.context.completeWith(null);
  }

}
