import { Attachment } from '@shared/models/attachments/attachment';
import { Table } from '../models/table';

const TABLE_ITEM_HEIGHT = 60;

export const ATTACHMENT_TABLE_CONFIG: Table<Attachment> = {
  virtualScroll: {
    itemSize: TABLE_ITEM_HEIGHT,
    maxBuffer: TABLE_ITEM_HEIGHT * 25,
    minBuffer: TABLE_ITEM_HEIGHT * 15,
  },
  headers: [
    { 
      column: 'name',
      name: 'Nom',
      classes: '!border-t-0',
      sorter: (a: Attachment, b: Attachment): number => a.originalFilename.localeCompare(b.originalFilename),
    },
    { 
      column: 'type',
      name: 'Type',
      classes: '!border-t-0 !min-w-[6rem] !max-w-[6rem] !w-[6rem]',
      sorter: (a: Attachment, b: Attachment): number => a.extension.localeCompare(b.extension),
    },
    {
      column: 'createdBy', 
      name: 'Créé par',
      classes: '!border-t-0 !min-w-[12rem] !max-w-[12rem] !w-[12rem]',
      sorter: (a: Attachment, b: Attachment): number => 
        a.createdBy.firstName.localeCompare(b.createdBy.firstName) || 
        a.createdBy.lastName.localeCompare(b.createdBy.lastName),
    },
    {
      column: 'createdOn',
      name: 'Date',
      classes: '!border-t-0 !min-w-[10rem] !max-w-[10rem] !w-[10rem]',
      sorter: (a: Attachment, b: Attachment): number => a.createdOn.getTime() - b.createdOn.getTime(),
    },
    {
      column: 'actions',
      name: '',
      classes: '!border-t-0 !min-w-[8rem] !max-w-[8rem] !w-[8rem]',
    },
  ],
};
