@if (administrationUsers | sort: usersAdminSortService.sortUserByState) {
  @let hasSupportLicense = hasSupportLicense$ | async;
  @let userDepartments = userDepartments$ | async;
  <div
    tuiSurface="outline"
    class="w-full rounded-xl"
  >
    <ng-content select="[slot=header]"></ng-content>
    @if (administrationUsers.length > 0) {
      <tui-scrollbar>
        <cdk-virtual-scroll-viewport
          #viewport
          tuiScrollable
          [itemSize]="TABLE_ITEM_HEIGHT"
          [maxBufferPx]="TABLE_ITEM_HEIGHT * 25"
          [minBufferPx]="TABLE_ITEM_HEIGHT * 15"
          class="tui-zero-scrollbar h-[58vh]"
        >
          <table
            tuiTable
            class="w-full"
            [columns]="columns$ | async"
          >
            <thead>
              <tr tuiThGroup>
                <th
                  class="!border-r-0"
                  *tuiHead="'name'"
                  tuiTh
                  [sticky]="true"
                  [style.top.px]="-viewport['_renderedContentOffset']"
                  [sorter]="null"
                >
                  Nom et prénom
                </th>
                <th
                  class="!border-r-0"
                  *tuiHead="'state'"
                  tuiTh
                  [sticky]="true"
                  [style.top.px]="-viewport['_renderedContentOffset']"
                  [sorter]="usersAdminSortService.sortUserByState"
                >
                  État
                </th>
                @if (hasSupportLicense) {
                  <th
                    class="!border-r-0"
                    *tuiHead="'groups'"
                    tuiTh
                    [sticky]="true"
                    [style.top.px]="-viewport['_renderedContentOffset']"
                    [sorter]="null"
                  >
                    Groupes
                  </th>
                }
                <th
                  class="!border-r-0"
                  *tuiHead="'admin'"
                  tuiTh
                  [sticky]="true"
                  [style.top.px]="-viewport['_renderedContentOffset']"
                  [sorter]="usersAdminSortService.sortUserByAdmin"
                >
                  Administrateur
                </th>
                <th
                  *tuiHead="'actions'"
                  tuiTh
                  [sticky]="true"
                  [style.top.px]="-viewport['_renderedContentOffset']"
                  [sorter]="null"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody
              tuiTbody
              *tuiLet="administrationUsers | tuiTableSort as sortedUsers"
              [data]="sortedUsers"
            >
              <tr
                tuiTr
                *cdkVirtualFor="let user of sortedUsers; trackBy: trackByUser"
                class="group hover:bg-neutral-1/20"
              >
                <td
                  class="min-w-72 max-w-72 p-3"
                  tuiTd
                  *tuiCell="'name'"
                >
                  <pxc-user-card
                    class="my-2"
                    [user]="user"
                    [mailAddress]="user.mailAddress"
                    size="small"
                  ></pxc-user-card>
                </td>
                <td
                  class="max-w-min-w-48 min-w-48"
                  tuiTd
                  *tuiCell="'state'"
                >
                  <pxc-user-admin-state-badge [state]="user.state"></pxc-user-admin-state-badge>
                </td>
                @if (hasSupportLicense) {
                  <td
                    class="min-w-48 max-w-48"
                    tuiTd
                    *tuiCell="'groups'"
                  >
                    <pxc-cell-overflow [cellContent]="userDepartments[user.id]"></pxc-cell-overflow>
                  </td>
                }
                <td
                  class="max-w-min-w-48 min-w-48"
                  tuiTd
                  *tuiCell="'admin'"
                >
                  @if (user.state === UserAdminState.ENABLED || user.state === UserAdminState.DISABLED) {
                    <pxc-toggle
                      [toggle]="user.isAdmin"
                      (toggleChange)="adminChange(user)"
                    ></pxc-toggle>
                  }
                </td>
                <td
                  class="max-w-min-w-48 min-w-48"
                  tuiTd
                  *tuiCell="'actions'"
                >
                  <pxc-action-buttons
                    class="transition group-hover:opacity-100 md:opacity-0"
                    [actions]="actionsByUserState[user.state]"
                    [actionButtons]="actionButtonsByState[user.state]"
                    [entity]="user"
                  ></pxc-action-buttons>
                </td>
              </tr>
            </tbody>
          </table>
        </cdk-virtual-scroll-viewport>
      </tui-scrollbar>
    } @else {
      <p class="my-16 w-full text-center text-secondary">Aucun utilisateur trouvé.</p>
    }
  </div>
} @else {
  <pxc-loader
    data-testid="loading"
    class="my-5"
  >
    Chargement des utilisateurs ...
  </pxc-loader>
}
