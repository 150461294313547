import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PaginationQuery, Patient } from '@pixacare/pxc-ts-core';
import { TuiAlertService, TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { catchError, map, Observable, of } from 'rxjs';
import { PatientNamePipe } from 'src/app/modules/patient/patient-name.pipe';
import { DialogService } from 'src/app/services/dialog.service';
import { PatientHttpService } from 'src/app/services/http/patient.http.service';
import { QueryReportConfig } from 'src/app/shared/models/report-config';
import { downloadBlob } from 'src/app/shared/utils/utils';
import { PatientEncounterPickerComponent } from '../patient-encounter-picker/patient-encounter-picker.component';

@Component({
  templateUrl: './patient-report-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientReportPreviewComponent implements OnInit {

  patient: Patient = this.context.data.patient;
  clientCode: string = this.context.data.clientCode;
  patientReportConfig: QueryReportConfig = this.context.data.patientReportConfig ?? {
    query: new PaginationQuery({ orderBy: ['created_on|desc'] }),
  };

  patientExport$: Observable<{ content: Blob; preview: SafeResourceUrl }>;
  loading = true;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly patientService: PatientHttpService,
    private readonly dialogService: DialogService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, {
      patient: Patient;
      clientCode: string;
      patientReportConfig: QueryReportConfig;
    }>,
    private readonly patientNamePipe: PatientNamePipe,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
  ) { }

  ngOnInit() {

    this.patientExport$ = this.patientService.report(this.clientCode, this.patient.id, this.patientReportConfig)
      .pipe(
        map((patientExport) => {
          this.loading = false;
          const blob = new Blob([patientExport.body], { type: 'application/pdf' });
          return {
            content: blob,
            preview: this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)),
          };
        }),
      );
  }

  download(content: Blob): void {
    downloadBlob(content, `${this.patientNamePipe.transform(this.patient)} - Rapport de suivi.pdf`);
  }

  export(): void {
    this.close();
    this.dialogService.openComponent<PatientEncounterPickerComponent, string>(PatientEncounterPickerComponent, {
      label: 'Exporter un rapport patient dans le DPI',
      data: {
        clientCode: this.clientCode,
        patientId: this.patient.id,
      },
    }).subscribe((encounterId) => {

      if (!encounterId) {
        return;
      }

      const loadingToastr = this.alertService.open('L\'export sera prêt dans quelques instants', {
        label: 'Export en cours',
        appearance: 'info',
        autoClose: 10000,
        closeable: false,
      }).subscribe();

      this.patientService.export(
        this.clientCode,
        this.patient.id,
        encounterId,
        this.patientReportConfig,
      ).pipe(
        catchError((err) => {
          console.error(err);
          loadingToastr.unsubscribe();
          this.alertService.open('Une erreur est survenue lors de l\'export du rapport dans le DPI', {
            label: 'Impossible d\'exporter le rapport',
            appearance: 'error',
          }).subscribe();
          return of([]);
        }),
      ).subscribe(() => {

        loadingToastr.unsubscribe();
        this.alertService.open('Le rapport du patient a été exporté dans le DPI', {
          label: 'Rapport exporté',
          appearance: 'success',
        }).subscribe();
      });

    });
  }

  close(): void {
    this.context.completeWith();
  }

}
