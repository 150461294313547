@let channels = channels$ | async;
<div
  class="flex h-full w-full flex-col gap-2 border-r border-border-normal"
>
  <div class="flex items-center justify-between gap-2 px-4 pt-3">
    <h5 tuiTitle="m">Messagerie</h5>
    <button
      type="button"
      tuiIconButton
      appearance="secondary"
      iconStart="@tui.plus"
      size="s"
      aria-label="Créer une conversation"
      (click)="openCreateChannelDialog()"
    ></button>
  </div>
  <div class="flex flex-col gap-3 px-4 py-1">
    <pxc-filter-bar
      searchPlaceholder="Rechercher une conversation ..."
      size="m"
    ></pxc-filter-bar>
    <pxc-filter-buttons></pxc-filter-buttons>
  </div>
  @if (channels?.length > 0) {
    <tui-scrollbar class="flex-1">
      <div class="flex flex-col">
        @for (channel of channels; track channel.id + channel.lastMessage) {
          <pxc-channel-preview
            class="cursor-pointer px-4 py-2 transition hover:bg-neutral-1"
            [channel]="channel"
            [routerLink]="['./', channel.id]"
            [routerLinkActive]="['bg-neutral-1']"
            queryParamsHandling="merge"
          ></pxc-channel-preview>
        }
      </div>
    </tui-scrollbar>
  } @else {
    @let search = filteredSearch$ | async;
    <pxc-status
      [state]="Status.BASIC"
      [icon]="!search ? '@tui.message-circle' : '@tui.meh'"
      [showTitle]="false"
      [description]="
          search ? 'Aucune conversation ne correspond à votre recherche.' : 'Vous n\'avez pas encore de conversations !'
        "
    ></pxc-status>
  }
</div>
