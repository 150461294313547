import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FILTER_TEMPLATES, FilterBarService } from '@modules/filters/filter-bar.service';
import { UserSequenceGalleryService } from '@modules/gallery/services/dialogs/sliders/sequences/user-sequence-gallery.service';
import { TelemonitoringGalleryService } from '@modules/gallery/services/dialogs/sliders/telemonitorings/telemonitoring-gallery.service';
import { SequenceActionsService } from '@modules/sequence/services/sequence-actions.service';
import { TelemonitoringActionsService } from '@modules/telemonitoring/telemonitoring-actions.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FlattenedTelemonitoring } from '@pixacare/pxc-ts-core';
import { SequenceContext } from '@shared/models/enums/sequence-context.enum';
import { sequenceFilterTemplates } from '@shared/models/filters/filter-template.config';
import { MediaClickedOutput } from '@shared/models/media-clicked-output';
import { ActionButton } from '@shared/models/menu-actions/action-buttons';
import { MenuActionId } from '@shared/models/menu-actions/menu-action-id.enum';
import { millisecondsToDays, millisecondsToHours, pluralize } from '@shared/utils/utils';
import { filter, first, map, Observable } from 'rxjs';
import { TelemonitoringPageService } from './telemonitoring-page.service';

@UntilDestroy()
@Component({
  templateUrl: './telemonitoring.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TelemonitoringPageService,
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: sequenceFilterTemplates,
    },
    UserSequenceGalleryService,
  ],
})
export class TelemonitoringComponent {

  telemonitoring$ = this.telemonitoringPageService.getTelemonitoring();
  sequences$ =  this.telemonitoringPageService.getSequences(this.filterBarService.filteredSearch$);
  qrCode$ = this.telemonitoringPageService.getQrCode();
  isTelemonitoringStateLoaded$ = this.telemonitoringPageService.getIsTelemonitoringStateLoaded();
  patientContactPhoneNumber$ = this.telemonitoringPageService.getPatientContactPhoneNumber();
  context: SequenceContext = SequenceContext.TELEMONITORING;
  sequenceActions = this.sequenceActionsService.getActions(this.context);
  tmActions = this.tmActionsService.getActions();
  isArchived$ = this.telemonitoring$.pipe(
    untilDestroyed(this),
    map((telemonitoring: FlattenedTelemonitoring) =>
      !telemonitoring.telemonitoring.isActive || (telemonitoring.patient && telemonitoring.patient.isArchived),
    ),
  );

  baseActionButtons: ActionButton[] = [
    {
      appearance: 'secondary',
      title: 'Rapport',
      actionIds: [
        MenuActionId.TELEMONITORING_REPORT,
      ],
    },
    {
      appearance: 'outline',
      title: 'Conversation',
      actionIds: [
        MenuActionId.TELEMONITORING_OPEN_CHAT,
      ],
    },
  ];

  reminderActionButton: ActionButton = {
    appearance: 'outline',
    title: 'Relance',
    actionIds: [MenuActionId.TELEMONITORING_SEND_REMINDER],
  };

  actionButtons$: Observable<ActionButton[]> = this.telemonitoring$.pipe(
    filter(({ telemonitoring }) => !!telemonitoring),
    map(({ telemonitoring }) => [...this.baseActionButtons, {
      ...this.reminderActionButton,
      ...(telemonitoring.lastNotifiedOn 
        && this.computeReminderActionButtonHint(telemonitoring.lastNotifiedOn)),
    }]),
    untilDestroyed(this),
  );

  constructor(
    private readonly sequenceActionsService: SequenceActionsService,
    private readonly tmActionsService: TelemonitoringActionsService,
    private readonly userSequenceGalleryService: UserSequenceGalleryService,
    private readonly telemonitoringGalleryService: TelemonitoringGalleryService,
    private readonly filterBarService: FilterBarService,
    private readonly telemonitoringPageService: TelemonitoringPageService,
  ) {}
  
  openGallery({ clientCode, sequenceId, mediaIdx }: MediaClickedOutput): void {
    this.userSequenceGalleryService.openGallery(clientCode, sequenceId, mediaIdx);
  }

  openQrCode(clientCode: string, telemonitoringId: number): void {
    this.telemonitoringGalleryService.openGallery(clientCode, telemonitoringId);
  }

  addSequence(): void {
    this.telemonitoring$.pipe(first()).subscribe(async (tlm) => {
      await this.sequenceActionsService.addSequence(tlm.telemonitoring.clientCode, {
        telemonitoringId: tlm.telemonitoring.id,
        patient: tlm.patient,
      });
    });
  }

  computeReminderActionButtonHint(lastNotifiedOn: Date): Partial<ActionButton> {
    const timeSinceLastNotification = new Date().getTime() - lastNotifiedOn.getTime();
    const daysSinceLastNotification = Math.floor(millisecondsToDays(timeSinceLastNotification));
    const hoursUntilNextNotification = Math.ceil((24 - millisecondsToHours(timeSinceLastNotification)));

    const hint = `Dernière relance effectuée il y a ${pluralize('jour', daysSinceLastNotification)}`;
    const hintOnDisabled = `Prochaine relance disponible dans ${pluralize('heure', hoursUntilNextNotification)}`;
    const disabled = daysSinceLastNotification < 1;

    return { hint, hintOnDisabled, disabled };
  }

}
