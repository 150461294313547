import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { TuiButton } from '@taiga-ui/core';

@Component({
  selector: 'pxc-icon-action-buttons',
  standalone: true,
  imports: [
    AsyncPipe,
    TuiButton,
  ],
  templateUrl: './icon-action-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconActionButtonsComponent<T> {

  @Input() appearance: string;
  @Input() size: string;
  @Input() actions: MenuAction<T>[];
  @Input() entity: T;

}
